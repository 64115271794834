import React, { useEffect, useState } from "react";
import "./style.scss";
import { Breadcrumb, Footer, Header, Sidebar } from "../../components";
import { Link } from "react-router-dom";
import useDocumentTitle from "../../assets/utils/useDocumentTitle";
import BaseURL from "../../assets/utils/baseUrl";

const Berita = () => {
  useDocumentTitle("Informasi - BAPENDA KOTA BEKASI");

  const [informasi, setInformasi] = useState([]);
  const [informasiPerPage, setInformasiPerPage] = useState([]);
  const [pageNumber, setPageNumber] = useState([]);
  const [pageShow, setPageShow] = useState(0);

  let tempPageLength = 1;
  let tempInformasiPerPage = [];
  let tempArray = [];
  let itemPerPageLength = 10;

  useEffect(() => {
    const getData = async () => {
      await fetch(BaseURL + "api/berita")
        .then((res) => res.json())
        .then((data) => setInformasi(data.data));
    };
    getData();
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
  }, []);

  useEffect(() => {
    tempPageLength = Math.ceil(informasi.length / itemPerPageLength);
    let tempPageNumber = [];
    for (let i = 0; i < tempPageLength; i++) {
      tempArray.push([]);
      tempPageNumber.push(i);
    }
    setPageNumber(tempPageNumber);
    tempInformasiPerPage = tempArray;
    setItemPerPage();
  }, [informasi]);

  useEffect(() => {
    tempPageLength = Math.ceil(informasi.length / itemPerPageLength);
    let tempPageNumber = [];
    for (let i = 0; i < tempPageLength; i++) {
      tempArray.push([]);
      tempPageNumber.push(i);
    }
    setPageNumber(tempPageNumber);
    tempInformasiPerPage = tempArray;
    setInformasiPerPage(tempInformasiPerPage);
    setItemPerPage();
  }, [pageShow]);

  const pageChanger = (item) => {
    setPageShow(item);
  };

  const setItemPerPage = () => {
    let index = 0;
    let itemLength = informasi.length;

    if (tempArray.length > 0) {
      // set item per page
      for (let i = 0; i < tempPageLength; i++) {
        if (itemLength >= itemPerPageLength) {
          for (let j = 0; j < itemPerPageLength; j++) {
            tempArray[i].push({
              id_berita: informasi[index].id_berita,
              judul: informasi[index].judul,
              created_date: informasi[index].created_date,
              konten: informasi[index].konten,
              thumbnail: informasi[index].thumbnail,
            });
            index++;
          }
          itemLength = itemLength - itemPerPageLength;
        } else {
          for (let j = 0; j < itemLength; j++) {
            tempArray[i].push({
              id_berita: informasi[index].id_berita,
              judul: informasi[index].judul,
              created_date: informasi[index].created_date,
              konten: informasi[index].konten,
              thumbnail: informasi[index].thumbnail,
            });
            index++;
          }
        }
      }
      setInformasiPerPage(tempArray[pageShow]);
    }
  };

  return (
    <div className="page">
      <Header active="publikasi" />
      <main className="main-sb">
        <section className="content">
          <Breadcrumb path="Publikasi / Berita" />
          <div className="page-title-wrapper">
            <div className="page-title">Berita Terkini</div>
          </div>
          <div className="informasi-list-wrapper">
            {informasiPerPage.length > 0 &&
              informasiPerPage.map((item) => {
                return (
                  <div className="informasi-list-item" key={item.id_berita}>
                    <div className="informasi-list-thumbnail-wrapper">
                      <img className="informasi-list-thumbnail" src={BaseURL + "assets/images/berita/" + item.thumbnail}></img>
                    </div>
                    <div className="informasi-list-informasi">
                      <div>
                        <p className="informasi-list-title">{item.judul}</p>
                        <p className="informasi-list-date">{item.created_date}</p>
                        <p className="informasi-list-desc">{item.konten.substring(0, 400).replace("(_newline)", " ")} [ ... ]</p>
                      </div>
                      <Link to={"/berita-detail/" + item.id_berita}>
                        <div className="informasi-list-desc link">Selengkapnya</div>
                      </Link>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="pagination-wrapper">
            {pageNumber.length > 0 &&
              pageNumber.map((item, idx) => {
                return (
                  <div
                    className={item == pageShow ? "page-number page-number-active" : "page-number"}
                    onClick={() => {
                      // setPageShow(item);
                      pageChanger(item);
                    }}
                    key={idx}
                  >
                    {item + 1}
                  </div>
                );
              })}
          </div>
        </section>
        <Sidebar />
      </main>
      <Footer />
    </div>
  );
};

export default Berita;
