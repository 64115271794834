import React, { useEffect, useState } from "react";
import { Breadcrumb, Footer, Header, Sidebar } from "../../components";
import useDocumentTitle from "../../assets/utils/useDocumentTitle";
import BaseURL from "../../assets/utils/baseUrl";
import "./style.scss";

const Galeri = () => {
  useDocumentTitle("Galeri - BAPENDA BEKASI");

  const [informasi, setInformasi] = useState([]);
  const [informasiPerPage, setInformasiPerPage] = useState([]);
  const [pageNumber, setPageNumber] = useState([]);
  const [pageShow, setPageShow] = useState(0);
  const [row, setRow] = useState([]);
  const [imageQueue, setImageQueue] = useState([]);
  const [imageShow, setImageShow] = useState({
    imageQueueIndex: "",
    id_galeri: "",
    deskripsi: "",
    thumbnail: "",
  });
  const [imageViewer, setImageViewer] = useState(false);

  let tempPageLength = 1;
  let tempImageQueue = [];
  let tempInformasiPerPage = [];
  let tempArray = [];
  let tempRow = [];
  let itemPerPageLength = 9;

  useEffect(() => {
    const getData = async () => {
      await fetch(BaseURL + "api/galeri")
        .then((res) => res.json())
        .then((data) => {
          setInformasi(data.data);
        });
    };
    getData();
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
  }, []);

  useEffect(() => {
    tempPageLength = Math.ceil(informasi.length / itemPerPageLength);
    let tempPageNumber = [];
    for (let i = 0; i < tempPageLength; i++) {
      tempArray.push([]);
      tempPageNumber.push(i);
    }
    setPageNumber(tempPageNumber);
    tempInformasiPerPage = tempArray;
    setInformasiPerPage(tempInformasiPerPage);
    setItemPerPage();
  }, [informasi]);

  useEffect(() => {
    tempPageLength = Math.ceil(informasi.length / itemPerPageLength);
    let tempPageNumber = [];
    for (let i = 0; i < tempPageLength; i++) {
      tempArray.push([]);
      tempPageNumber.push(i);
    }
    setPageNumber(tempPageNumber);
    tempInformasiPerPage = tempArray;
    setInformasiPerPage(tempInformasiPerPage);
    setItemPerPage();
  }, [pageShow]);

  const pageChanger = (item) => {
    setPageShow(item);
  };

  const setItemPerPage = () => {
    let index = 0;
    let itemLength = informasi.length;

    if (tempArray.length > 0) {
      // set item per page
      for (let i = 0; i < tempPageLength; i++) {
        if (itemLength >= itemPerPageLength) {
          for (let j = 0; j < itemPerPageLength; j++) {
            tempArray[i].push({
              imageQueueIndex: index,
              id_galeri: informasi[index].id_galeri,
              deskripsi: informasi[index].deskripsi,
              thumbnail: informasi[index].thumbnail,
            });
            index++;
          }
          itemLength = itemLength - itemPerPageLength;
        } else {
          for (let j = 0; j < itemLength; j++) {
            tempArray[i].push({
              imageQueueIndex: index,
              id_galeri: informasi[index].id_galeri,
              deskripsi: informasi[index].deskripsi,
              thumbnail: informasi[index].thumbnail,
            });
            index++;
          }
        }
      }
      setRowPerPage();
    }
  };

  const setRowPerPage = () => {
    // set row
    if (tempArray.length > 0) {
      let index = 0;
      tempRow = [];
      let itemLength = tempArray[pageShow].length;

      if (tempArray[pageShow].length > 0) {
        for (let i = 0; i < itemLength; i++) {
          tempImageQueue.push({
            imageQueueIndex: i,
            id_galeri: tempArray[pageShow][i].id_galeri,
            deskripsi: tempArray[pageShow][i].deskripsi,
            thumbnail: tempArray[pageShow][i].thumbnail,
          });
        }
        setImageQueue(tempImageQueue);

        let rowLength = Math.ceil(tempArray[pageShow].length / 3);
        for (let i = 0; i < rowLength; i++) {
          tempRow.push([]);
        }

        for (let i = 0; i < tempRow.length; i++) {
          if (itemLength >= 3) {
            for (let j = 0; j < 3; j++) {
              tempRow[i].push({
                imageQueueIndex: index,
                id_galeri: tempArray[pageShow][index].id_galeri,
                thumbnail: tempArray[pageShow][index].thumbnail,
                deskripsi: tempArray[pageShow][index].deskripsi,
              });
              index++;
            }
            itemLength = itemLength - 3;
          } else {
            for (let j = 0; j < itemLength; j++) {
              tempRow[i].push({
                imageQueueIndex: index,
                id_galeri: tempArray[pageShow][index].id_galeri,
                thumbnail: tempArray[pageShow][index].thumbnail,
                deskripsi: tempArray[pageShow][index].deskripsi,
              });
              index++;
            }
          }
        }
        setRow(tempRow);
      }
    }
  };

  const leftImageChanger = () => {
    if (imageShow.imageQueueIndex != 0) {
      setImageShow({
        imageQueueIndex: imageQueue[imageShow.imageQueueIndex - 1].imageQueueIndex,
        id_galeri: imageQueue[imageShow.imageQueueIndex - 1].id_galeri,
        deskripsi: imageQueue[imageShow.imageQueueIndex - 1].deskripsi,
        thumbnail: imageQueue[imageShow.imageQueueIndex - 1].thumbnail,
      });
    } else {
      setImageShow({
        imageQueueIndex: imageQueue[informasiPerPage[pageShow].length - 1].imageQueueIndex,
        id_galeri: imageQueue[informasiPerPage[pageShow].length - 1].id_galeri,
        deskripsi: imageQueue[informasiPerPage[pageShow].length - 1].deskripsi,
        thumbnail: imageQueue[informasiPerPage[pageShow].length - 1].thumbnail,
      });
    }
  };

  const rightImageChanger = () => {
    if (imageShow.imageQueueIndex != informasiPerPage[pageShow].length - 1) {
      setImageShow({
        imageQueueIndex: imageQueue[imageShow.imageQueueIndex + 1].imageQueueIndex,
        id_galeri: imageQueue[imageShow.imageQueueIndex + 1].id_galeri,
        deskripsi: imageQueue[imageShow.imageQueueIndex + 1].deskripsi,
        thumbnail: imageQueue[imageShow.imageQueueIndex + 1].thumbnail,
      });
    } else {
      setImageShow({
        imageQueueIndex: imageQueue[0].imageQueueIndex,
        id_galeri: imageQueue[0].id_galeri,
        deskripsi: imageQueue[0].deskripsi,
        thumbnail: imageQueue[0].thumbnail,
      });
    }
  };

  return (
    <div className="page">
      <Header active="publikasi" />
      <main className="main-sb">
        <section className="content">
          <Breadcrumb path="Publikasi / Galeri" />
          <div className="page-title-wrapper">
            <div className="page-title">Galeri</div>
          </div>
          <div className="image-list-wrapper">
            {row.length > 0 &&
              row.map((rowValue) => {
                return (
                  <div className="image-list-row" key={Math.random}>
                    {rowValue.map((item) => {
                      return (
                        <div className="image-list-item" key={item.id_galeri}>
                          <img
                            src={BaseURL + "/assets/images/galeri/" + item.thumbnail}
                            className="image-list-thumbnail"
                            onClick={() => {
                              setImageShow({
                                imageQueueIndex: item.imageQueueIndex,
                                id_galeri: item.id_galeri,
                                deskripsi: item.deskripsi,
                                thumbnail: item.thumbnail,
                              });
                              setImageViewer(true);
                            }}
                          ></img>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
          </div>
          <div className="pagination-wrapper">
            {pageNumber.length > 0 &&
              pageNumber.map((item, idx) => {
                return (
                  <div
                    className={item == pageShow ? "page-number page-number-active" : "page-number"}
                    onClick={() => {
                      // setPageShow(item);
                      pageChanger(item);
                    }}
                    key={idx}
                  >
                    {item + 1}
                  </div>
                );
              })}
          </div>
        </section>
        <Sidebar />
      </main>
      <Footer />

      {/* image viewer */}
      <div className={imageViewer ? "darker-bg-admin imgbox" : "darker-bg-admin imgbox hide"}>
        {informasi.length > 0 && (
          <div className="image-viewer-wrapper">
            <img src={BaseURL + "/assets/images/galeri/" + imageShow.thumbnail} className="image-viewer-thumbnail"></img>
            <div className="image-viewer-desc">{imageShow.deskripsi}</div>
          </div>
        )}
        <div className="image-viewer-arrow-wrapper">
          <div className="leftArrow" onClick={() => leftImageChanger()}>
            <i className="fas fa-chevron-left"></i>
          </div>
          <div className="rightArrow" onClick={() => rightImageChanger()}>
            <i className="fas fa-chevron-right"></i>
          </div>
        </div>
        <i className="fas fa-times close-image-viewer" onClick={() => setImageViewer(false)}></i>
      </div>
    </div>
  );
};

export default Galeri;
