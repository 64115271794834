import React, { useEffect, useState } from "react";
import BaseURL from "../../../assets/utils/baseUrl";
import MUIDataTable from "mui-datatables";
import { Button, Footer, HeaderAdmin } from "../../../components";
import { TextField } from "@material-ui/core";
import { Loader } from "../../../assets";

function KelolaRetribusi({ setToken }) {
  const [retribusi, setRetribusi] = useState(null);
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(true);
  const [popUp, setPopUp] = useState(false);
  const [success, setSuccess] = useState(true);
  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [alertTahun, setAlertTahun] = useState(false);
  const [alertTarget, setAlertTarget] = useState(false);
  const [alertRealisasi, setAlertRealisasi] = useState(false);
  const [formData, setFormData] = useState({
    tahun: "",
    target: "",
    realisasi: "",
    created_date: "",
  });
  const [id, setId] = useState("");

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      await fetch(BaseURL + "api/retribusi")
        .then((res) => res.json())
        .then((data) => {
          setRetribusi(data.data);
          setLoading(false);
        });
    };
    getData();
  }, [load]);

  const columns = [
    {
      name: "tahun",
      label: "Tahun",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <div style={{ width: 200 }}>{value}</div>;
        },
      },
    },
    {
      name: "target",
      label: "Target",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "realisasi",
      label: "Realisasi",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "tanggal_post",
      label: "Tanggal Post",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <div style={{ width: 100 }}>{value}</div>;
        },
      },
    },
    {
      name: "opsi",
      label: "Opsi",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="tooltip btn-hapus-row" onClick={() => hapus(value)}>
                <i className="fas fa-trash-alt"></i>
                <span className="tooltiptext">Hapus</span>
              </div>
              <div
                className="tooltip btn-edit-row"
                onClick={() => {
                  setUpdateModal(true);
                  openUpdateModal(value);
                }}
              >
                <i className="fas fa-edit"></i>
                <span className="tooltiptext">Update</span>
              </div>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    sort: true,
    filter: false,
    download: false,
    print: false,
    selectableRows: false,
  };

  let tablesData = [];

  if (retribusi) {
    let date = [];
    for (let i = 0; i < retribusi.length; i++) {
      let tempDate = "";
      tempDate = retribusi[i].created_date;
      date = tempDate.split("-");
      if (date[1] != "10") {
        date[1] = date[1].replace("0", "");
      }
      let m = "";
      switch (date[1]) {
        case "1":
          m = "Januari";
          break;
        case "2":
          m = "Februari";
          break;
        case "3":
          m = "Maret";
          break;
        case "4":
          m = "April";
          break;
        case "5":
          m = "Mei";
          break;
        case "6":
          m = "Juni";
          break;
        case "7":
          m = "Juli";
          break;
        case "8":
          m = "Agustus";
          break;
        case "9":
          m = "September";
          break;
        case "10":
          m = "Oktober";
          break;
        case "11":
          m = "November";
          break;
        case "12":
          m = "Desember";
          break;
        default:
          m = "-";
      }
      date = date[2] + " " + m + " " + date[0];
      tablesData.push({
        tahun: retribusi[i].tahun,
        target: retribusi[i].target,
        realisasi: retribusi[i].realisasi,
        tanggal_post: date,
        opsi: retribusi[i].id_retribusi,
      });
    }
  }

  const tambah = () => {
    if (formData.tahun == "") {
      setAlertTahun(true);
    } else {
      setAlertTahun(false);
    }

    if (formData.target == "") {
      setAlertTarget(true);
    } else {
      setAlertTarget(false);
    }

    if (formData.realisasi == null) {
      setAlertRealisasi(true);
    } else {
      setAlertRealisasi(false);
    }

    if (formData.tahun !== "" && formData.target !== "" && formData.realisasi !== null) {
      const data = new FormData();
      let today = new Date();
      let date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
      data.append("request", "post");
      data.append("tahun", formData.tahun);
      data.append("target", formData.target);
      data.append("realisasi", formData.realisasi);
      data.append("id_user", localStorage.getItem("id"));
      data.append("created_date", date);

      try {
        fetch(BaseURL + "api/retribusi", {
          method: "POST",
          body: data,
        })
          .then((response) => response.json())
          .then((data) => {
            setFormData({
              tahun: "",
              target: "",
              realisasi: "",
              created_date: "",
            });
            setLoad(!load);
            setModal(false);
            setUpdateModal(false);
            if (data.status) {
              setSuccess(true);
            } else {
              setSuccess(false);
            }
            setPopUp(true);
          })
          .catch((error) => console.log("Gagal: ", error));
      } catch (error) {
        alert("Terjadi kesalahan pada sistem");
        console.log(error);
      }
    }
  };

  const hapus = ($id) => {
    try {
      fetch(BaseURL + "api/retribusi/" + $id, {
        method: "DELETE",
      })
        .then((response) => response.json())
        .then((data) => {
          setLoad(!load);
          if (data.status) {
            setSuccess(true);
          } else {
            setSuccess(false);
          }
          setPopUp(true);
        })
        .catch((error) => console.log("Gagal: ", error));
    } catch (error) {
      alert("Terjadi kesalahan pada sistem");
      console.log(error);
    }
  };

  const openUpdateModal = ($id) => {
    const getDataUpdate = async () => {
      await fetch(BaseURL + "api/retribusi?id=" + $id)
        .then((res) => res.json())
        .then((data) => {
          setFormData({
            tahun: data.data[0].tahun,
            target: data.data[0].target,
            realisasi: data.data[0].realisasi,
            created_date: data.data[0].created_date,
          });
          setId($id);
          setModal(true);
        });
    };
    getDataUpdate();
  };

  const update = () => {
    if (formData.tahun == "") {
      setAlertTahun(true);
    } else {
      setAlertTahun(false);
    }

    if (formData.target == "") {
      setAlertTarget(true);
    } else {
      setAlertTarget(false);
    }

    if (formData.realisasi == "") {
      setAlertRealisasi(true);
    } else {
      setAlertRealisasi(false);
    }

    if (formData.tahun !== "" && formData.realisasi !== "" && formData.target !== "") {
      const data = new FormData();
      let today = new Date();
      let date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
      data.append("request", "put");
      data.append("id_retribusi", id);
      data.append("tahun", formData.tahun);
      data.append("target", formData.target);
      data.append("realisasi", formData.realisasi);
      data.append("id_user", localStorage.getItem("id"));
      data.append("created_date", formData.created_date);
      data.append("updated_date", date);

      try {
        fetch(BaseURL + "api/retribusi", {
          method: "POST",
          body: data,
        })
          .then((response) => response.json())
          .then((data) => {
            setFormData({
              tahun: "",
              target: "",
              realisasi: "",
              created_date: "",
            });
            setLoad(!load);
            setModal(false);
            setUpdateModal(false);
            if (data.status) {
              setSuccess(true);
            } else {
              setSuccess(false);
            }
            setPopUp(true);
          })
          .catch((error) => console.log("Gagal: ", error));
      } catch (error) {
        alert("Terjadi kesalahan pada sistem");
        console.log(error);
      }
    }
  };

  return (
    <div className="page">
      <HeaderAdmin title="Kelola Retribusi" setToken={(e) => setToken(e)} />
      <div className="main-admin">
        <div className="container-admin">
          <div style={{ height: 50 }} />
          <Button
            title="Tambah Post"
            type="primary"
            onClick={() => {
              setUpdateModal(false);
              setModal(true);
            }}
          />
          <div style={{ height: 10 }} />
          {loading ? (
            <div className="loader">
              <img src={Loader}></img>
            </div>
          ) : (
            <MUIDataTable title={"Daftar Retribusi"} data={tablesData} columns={columns} options={options} download={false} className="table" />
          )}
        </div>
      </div>
      <Footer />

      {/* modal */}
      <div className={modal ? "darker-bg-admin" : "darker-bg-admin hide"}>
        {/* form */}
        <div className="modal-admin">
          <div className="form-admin">
            <TextField type="number" id="tahun" label="Tahun" variant="outlined" style={{ marginTop: 10, marginBottom: 10 }} onChange={(e) => setFormData({ ...formData, tahun: e.target.value })} value={formData.tahun} />
            {alertTahun && <div className="danger-alert">Tahun tidak boleh kosong</div>}

            <TextField type="number" id="target" label="Target" variant="outlined" style={{ marginTop: 10, marginBottom: 10 }} onChange={(e) => setFormData({ ...formData, target: e.target.value })} value={formData.target} />
            {alertTarget && <div className="danger-alert">Target tidak boleh kosong</div>}

            <TextField type="number" id="realiasi" label="Realisasi" variant="outlined" style={{ marginTop: 10, marginBottom: 10 }} onChange={(e) => setFormData({ ...formData, realisasi: e.target.value })} value={formData.realisasi} />
            {alertRealisasi && <div className="danger-alert">Realisasi tidak boleh kosong</div>}
          </div>

          <div style={{ height: 30 }}></div>

          <div className="form-option">
            <Button
              title={updateModal ? "Update" : "Tambah"}
              type="primary"
              onClick={() => {
                if (updateModal) {
                  update();
                } else {
                  tambah();
                }
              }}
            />
            <div style={{ width: 10 }}></div>
            <Button
              title="Batal"
              type="danger"
              onClick={() => {
                setModal(false);
                setUpdateModal(false);
                setFormData({
                  tahun: "",
                  target: "",
                  realisasi: "",
                  created_date: "",
                });
                setAlertTahun(false);
                setAlertTarget(false);
                setAlertRealisasi(false);
              }}
            />
          </div>
        </div>
      </div>

      {/* message pop up */}
      <div className={popUp ? "darker-bg-admin" : "darker-bg-admin hide"}>
        <div className="pop-up-admin">
          <i className={success ? "fas fa-check-circle pop-up-logo" : "fas fa-times-circle pop-up-logo failed-pop-up"}></i>
          {success ? <p>Berhasil</p> : <p className={success ? "" : "failed-pop-up"}>Gagal</p>}
          <div style={{ height: 20 }}></div>
          <Button
            title="Tutup"
            type="primary"
            onClick={() => {
              setAlertTahun(false);
              setAlertTarget(false);
              setAlertRealisasi(false);
              setPopUp(false);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default KelolaRetribusi;
