import React, { useEffect, useState } from "react";
import "./style.scss";
import BaseURL from "../../../assets/utils/baseUrl";
import { Link } from "react-router-dom";

function Sidebar() {
  const [berita, setBerita] = useState([]);

  useEffect(() => {
    const getData = async () => {
      await fetch(BaseURL + "api/berita/?jumlah_item=5")
        .then((res) => res.json())
        .then((data) => setBerita(data.data));
    };
    getData();
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
  }, []);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://widget.kominfo.go.id/gpr-widget-kominfo.min.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <section className="sidebar">
      <div className="sidebar-panel">
        <div className="sidebar-title">Berita Terkini</div>
        <div className="sidebar-content">
          {berita.map((item) => {
            return (
              <Link
                to={"/berita-detail/" + item.id_berita}
                className="sidebar-berita-wrapper"
                key={item.id_berita}
              >
                <i className="fas fa-angle-right iconPlus"></i>
                <div>{item.judul}</div>
              </Link>
            );
          })}
        </div>
      </div>

      <div style={{ height: 10 }}></div>

      <div className="sidebar-panel">
        <div
          id="gpr-kominfo-widget-container"
          style={{ width: "100%", minHeight: 200, zIndex: 0 }}
        ></div>
      </div>
    </section>
  );
}

export default Sidebar;
