import React, { useState } from "react";
import useDocumentTitle from "../../assets/utils/useDocumentTitle";
import {
  Breadcrumb,
  Footer,
  Header,
  Numbering,
  PanelAccordion,
  Sidebar,
} from "../../components";
import "./style.scss";
import { items } from "./ListContentAccordion";

function JPpbb() {
  useDocumentTitle("Pelayanan PBB - BAPENDA BEKASI");
  const [isActive, setIsActive] = useState(null);

  return (
    <div className="page">
      <Header active="JPpbb" />

      {/* main */}
      <main className="main-sb">
        <section className="content">
          <Breadcrumb path="Jenis Pajak / PBB" />
          <div className="page-title-wrapper">
            <div className="page-title">Pajak PBB</div>
          </div>
          <div className="jenis-pajak-title">
            <strong>Pajak Bumi Dan Bangunan Perdesaan Dan Perkotaan</strong>
          </div>
          <div className="jenis-pajak-content">
            <p className="page-text">
            
Peraturan Daerah Nomor 16 Tahun 2011 Tentang Pajak Bumi dan Bangunan Perdesaan dan Perkotaan

Pajak Bumi dan Bangunan Perdesaan dan Perkotaan dipungut pajak atas kepemilikan, penguasaan, dan/atau pemanfaatan Bumi dan/atau Bangunan.
			</p>
 <div className="jenis-pajak-title">
           <p><b>Jenis Pelayanan & Persyaratan</b></p>
		   </div>
          </div>
          <div className="jenis-pajak-content">
            {items.map((item, index) => {
              return (
                <PanelAccordion
                  key={index}
                  label={item.label}
                  content={item.content}
                  index={index}
                  setIsActive={setIsActive}
                  isActive={isActive}
                />
              );
            })}
          </div>
        </section>
        <Sidebar />
      </main>

      <Footer />
    </div>
  );
}

export default JPpbb;
