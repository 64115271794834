import React from "react";
import "./style.scss";

const Numbering = (props) => (
  <div className="container-numbering">
    <div className="number-numbering">{props.number}.</div>
    <p className="content-numbering">{props.content}</p>
  </div>
);

export default Numbering;
