import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { hero1, hero2, logoBekasi, logoBphtb, logoEbphtb, logoEsptpd, logoSimpad,ipbb,lpse } from "../../assets";
import useDocumentTitle from "../../assets/utils/useDocumentTitle";
import { Footer, Header, SubFooter } from "../../components";
import "./style.scss";
import BaseURL from "../../assets/utils/baseUrl";
import { Bar, Pie } from "react-chartjs-2";
import YouTube from "react-youtube";
import { Timeline } from "react-twitter-widgets";

//import { YoutubeLink } from "../../assets/YoutubeLink";



const Beranda = () => {
  useDocumentTitle("BAPENDA - Kota Bekasi");
  const [index, setIndex] = useState(0);
  const [banner, setBanner] = useState([]);
  const heroImage = [hero1, hero2];
  const [berita, setBerita] = useState([]);
  const [agenda, setAgenda] = useState([]);
  const [beritaVideo, setBeritaVideo] = useState([]);
  
 
  const [pajak, setPajak] = useState({
    all: [],
    label: [],
    target: [],
    realisasi: [],
  });



 
  const [retribusi, setRetribusi] = useState({
    all: [],
    label: [],
    target: [],
    realisasi: [],
  });

  const [bphtb, setBphtb] = useState({
    all: [],
    label: [],
    januari19:['20569724282'],
    januari20: ['10439554853'],
    februari19: ['39815186329'],
    februari20: ['24123572180'],
  });

 
 /* const [apbd, setApbd] = useState({
    tahun: "",
    label: [],
    pad: 0,
    dana_perimbangan: 0,
    lain_lain_pendapatan: 0,
    pembiayaan: 0,
  });*/
  const [pad, setPad] = useState({
    tahun: "",
    label: [],
    pajak_daerah: 0,
    retribusi_daerah: 0,
    kd_yang_dipisahkan: 0,
    lain_lain_pad: 0,
  });
  const [chartActive, setChartActive] = useState("APBD");
  const [infoApbdShow, setInfoApbdShow] = useState("APBD");

  const [chartActiveBphtb, setChartActiveBphtb] = useState("BPHTB");
  const [infoBphtbShow, setInfoBphtbShow] = useState("BPHTB");
  const [youtubePlay, setYoutubePlay] = useState({});

  useEffect(() => {

    const _Hasync= _Hasync|| [];
    _Hasync.push(['Histats.start', '1,4600114,4,28,115,60,00011000']);
    _Hasync.push(['Histats.fasi', '1']);
    _Hasync.push(['Histats.track_hits', '']);
    (function() {
      const hs = document.createElement('script'); hs.type = 'text/javascript'; hs.async = true;
    hs.src = ('//s10.histats.com/js15_as.js');
    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(hs);
    })();

    const getDataBerita = async () => {
      await fetch(BaseURL + "api/berita/?jumlah_item=4")
        .then((res) => res.json())
        .then((data) => setBerita(data.data));
    };
    const getDataAgenda = async () => {
      await fetch(BaseURL + "api/agenda/?jumlah_item=4")
        .then((res) => res.json())
        .then((data) => setAgenda(data.data));
    };
 
    const getDataPajak = async () => {
      await fetch(BaseURL + "api/pajak/?jumlah_item=5")
        .then((res) => res.json())
        .then((data) => setPajakArray(data.data));
    };
    const getDataRetribusi = async () => {
      await fetch(BaseURL + "api/retribusi/?jumlah_item=5")
        .then((res) => res.json())
        .then((data) => setRetribusiArray(data.data));
    };
 /*   const getDataApbd = async () => {
      await fetch(BaseURL + "api/apbd/?jumlah_item=1")
        .then((res) => res.json())
        .then((data) => setApbdArray(data.data));
    };*/
    const getDataPad = async () => {
      await fetch(BaseURL + "api/pad/?jumlah_item=1")
        .then((res) => res.json())
        .then((data) => setPadArray(data.data));
    };

   
    const getDataBanner = async () => {
      await fetch(BaseURL + "api/banner")
        .then((res) => res.json())
        .then((data) => setBanner(data.data));
    };
	  const getBeritaVideo = () => {
      fetch(BaseURL + "api/berita_video?jumlah_item=3")
        .then((res) => res.json())
        .then((data) => {
          if (data.data.length > 0) {
            setBeritaVideo(data.data);
            setYoutubePlay(data.data[0]);
          }
        });
    };


  
    getDataBerita();
    getDataAgenda();

    getDataPajak();
    getDataRetribusi();
 //   getDataApbd();
    getDataPad();
    
    //getDataBphtb();

    getDataBanner();
	 getBeritaVideo();
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
  }, []);

  useEffect(() => {
    if (banner.length > 0) {
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex === banner.length - 1 ? 0 : prevIndex + 1));
      }, 5000);
    }
    // return () => {};
  }, [index || banner]);

  const setPajakArray = (pajak) => {
    if (pajak.length > 0) {
      let tempLabel = [];
      let tempTarget = [];
      let tempRealisasi = [];

      for (let i = 0; i < pajak.length; i++) {
        tempLabel.push(pajak[i].tahun);
        tempTarget.push(pajak[i].target);
        tempRealisasi.push(pajak[i].realisasi);
      }

      setPajak({
        all: pajak,
        label: tempLabel,
        target: tempTarget,
        realisasi: tempRealisasi,
      });
    }
  };

  const setBphtbArray = (bphtb) => {
    if (bphtb.length > 0) {
      let tempLabel = [];
      let tempjanuari19 = [];
      let tempjanuari20 = [];
      let tempfebruari19 = [];
      let tempfebruari20 = [];

      for (let i = 0; i < bphtb.length; i++) {
        tempLabel.push(bphtb[i].tahun);
        tempjanuari19.push(bphtb[i].januari19);
        tempjanuari20.push(bphtb[i].januari20);
        tempfebruari19.push(bphtb[i].februari19);
        tempfebruari20.push(bphtb[i].februari20);
      }

      setBphtb({
        all: bphtb,
        label: tempLabel,
        januari19: tempjanuari19,
        januari20: tempjanuari20,
        februari19: tempfebruari19,
        februari20: tempfebruari20,
      });
    }
  };
  
  const setRetribusiArray = (retribusi) => {
    if (retribusi.length > 0) {
      let tempLabel = [];
      let tempTarget = [];
      let tempRealisasi = [];

      for (let i = 0; i < retribusi.length; i++) {
        tempLabel.push(retribusi[i].tahun);
        tempTarget.push(retribusi[i].target);
        tempRealisasi.push(retribusi[i].realisasi);
      }

      setRetribusi({
        all: retribusi,
        label: tempLabel,
        target: tempTarget,
        realisasi: tempRealisasi,
      });
    }
  };
/*
  const setApbdArray = (apbd) => {
    if (apbd.length > 0) {
      let tempLabel = ["PAD", "Dana Perimbangan", "Pendapatan Lain-lain", "Pembiayaan"];
      let tempPad = apbd[0].pad;
      let tempDanaPerimbangan = apbd[0].dana_perimbangan;
      let tempLainLainPendapatan = apbd[0].lain_lain_pendapatan;
      let tempPembiayaan = apbd[0].pembiayaan;

      setApbd({
        tahun: apbd[0].tahun,
        label: tempLabel,
        pad: tempPad,
        dana_perimbangan: tempDanaPerimbangan,
        lain_lain_pendapatan: tempLainLainPendapatan,
        pembiayaan: tempPembiayaan,
      });
    }
  };
*/
  const setPadArray = (pad) => {
    if (pad.length > 0) {
      let tempLabel = ["Pajak Daerah", "Retribusi Daerah", "Hasil Pengolahan Kekayaan Daerah Yang Dipisahkan", "Lain-lain PAD"];
      let tempPajakDaerah = pad[0].pajak_daerah;
      let tempRetribusiDaerah = pad[0].retribusi_daerah;
      let tempKdYangDipisahkan = pad[0].kd_yang_dipisahkan;
      let tempLainLainPad = pad[0].lain_lain_pad;

      setPad({
        tahun: pad[0].tahun,
        label: tempLabel,
        pajak_daerah: tempPajakDaerah,
        retribusi_daerah: tempRetribusiDaerah,
        kd_yang_dipisahkan: tempKdYangDipisahkan,
        lain_lain_pad: tempLainLainPad,
      });
    }
  };

 

  const infoApbd = (type) => {
    if (type === "Pajak") {
      return (
        <div className="chart-card">
          <div className="chart-title">Realisasi Pajak Daerah Tahun {pajak.label[0]}</div>
          <div className="chart-wrapper">
            <Bar
              height={300}
              width={"100%"}
              data={{
                labels: pajak.label,
                datasets: [
                  {
                    label: "Target",
                    data: pajak.target,
                    backgroundColor: "#FFC300",
                  },
                  {
                    label: "Realisasi",
                    data: pajak.realisasi,
                    backgroundColor: "#4690CC",
                  },
                ],
              }}
              options={{ maintainAspectRatio: false, animations: false }}
            />
          </div>
          <table className="table">
            <tr className="table-head">
              <th className="cell">No</th>
              <th className="cell">Tahun</th>
              <th className="cell">Target</th>
              <th className="cell">Realisasi</th>
              <th className="cell">%</th>
            </tr>
            {pajak.all.length > 0 &&
              pajak.all.map((item, idx) => {
                let persen = 0;
                if (item.target > 0) {
                  persen = (item.realisasi * 100) / item.target;
                } else {
                  persen = 100;
                }
                return (
                  <tr className="table-data" key={idx}>
                    <td className="cell">{idx + 1}</td>
                    <td className="cell">{item.tahun}</td>
                    <td className="cell">{numberWithCommas(item.target)}</td>
                    <td className="cell">{numberWithCommas(item.realisasi)}</td>
                    <td className="cell">{persen.toFixed(2).toString().replace(".", ",")}%</td>
                  </tr>
                );
              })}
          </table>
        </div>
      );
    } else if (type === "Retribusi") {
      return (
        <div className="chart-card">
          <div className="chart-title">Realisasi Retribusi Daerah Tahun {retribusi.label[0]}</div>
          <div className="chart-wrapper">
            <Bar
              height={300}
              width={"100%"}
              data={{
                labels: retribusi.label,
                datasets: [
                  {
                    label: "Target",
                    data: retribusi.target,
                    backgroundColor: "#FFC300",
                  },
                  {
                    label: "Realisasi",
                    data: retribusi.realisasi,
                    backgroundColor: "#24F65C",
                  },
                ],
              }}
              options={{ maintainAspectRatio: false, animations: false }}
            />
          </div>
          <table className="table">
            <tr className="table-head">
              <th className="cell">No</th>
              <th className="cell">Tahun</th>
              <th className="cell">Target</th>
              <th className="cell">Realisasi</th>
              <th className="cell">%</th>
            </tr>
            {retribusi.all.length > 0 &&
              retribusi.all.map((item, idx) => {
                let persen = 0;
                if (item.target > 0) {
                  persen = (item.realisasi * 100) / item.target;
                } else {
                  persen = 100;
                }
                return (
                  <tr className="table-data" key={idx}>
                    <td className="cell">{idx + 1}</td>
                    <td className="cell">{item.tahun}</td>
                    <td className="cell">{numberWithCommas(item.target)}</td>
                    <td className="cell">{numberWithCommas(item.realisasi)}</td>
                    <td className="cell">{persen.toFixed(2).toString().replace(".", ",")}%</td>
                  </tr>
                );
              })}
          </table>
        </div>
      );
    } /*else if (type === "APBD") {
      return (
        <div className="chart-card">
          <div className="chart-title">Komponen APBD Tahun {apbd.tahun}</div>
          <div className="chart-wrapper">
            <Pie
              height={300}
              width={"100%"}
              data={{
                labels: apbd.label,
                datasets: [
                  {
                    label: "Nilai",
                    data: [apbd.pad, apbd.dana_perimbangan, apbd.lain_lain_pendapatan, apbd.pembiayaan],
                    backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)", "rgb(255, 205, 86)", "rgb(153, 255, 135)"],
                    hoverOffset: 4,
                  },
                ],
              }}
              options={{ maintainAspectRatio: false, animations: false }}
            />
          </div>
          <table className="table">
            <tr className="table-head">
              <th className="cell">No</th>
              <th className="cell">APBD</th>
              <th className="cell">Nilai</th>
            </tr>
            {apbd.label != 0 && (
              <>
                <tr className="table-data">
                  <td className="cell">1</td>
                  <td className="cell">Pendapatan Asli Daerah</td>
                  <td className="cell">{numberWithCommas(apbd.pad)}</td>
                </tr>
                <tr className="table-data">
                  <td className="cell">2</td>
                  <td className="cell">Dana Perimbangan</td>
                  <td className="cell">{numberWithCommas(apbd.dana_perimbangan)}</td>
                </tr>
                <tr className="table-data">
                  <td className="cell">3</td>
                  <td className="cell">Lain-lain pendapatan yang sah</td>
                  <td className="cell">{numberWithCommas(apbd.lain_lain_pendapatan)}</td>
                </tr>
                <tr className="table-data">
                  <td className="cell">4</td>
                  <td className="cell">Pembiayaan</td>
                  <td className="cell">{numberWithCommas(apbd.pembiayaan)}</td>
                </tr>
                <tr className="table-head">
                  <th className="cell"></th>
                  <th className="cell">Jumlah APBD Kota Bekasi</th>
                  <th className="cell">{numberWithCommas(parseFloat(apbd.pad) + parseFloat(apbd.dana_perimbangan) + parseFloat(apbd.lain_lain_pendapatan) + parseFloat(apbd.pembiayaan))}</th>
                </tr>
              </>
            )}
          </table>
        </div>
      );
    } */else {
      return (
        <div className="chart-card">
          <div className="chart-title">Target PAD Tahun {pad.tahun}</div>
          <div className="chart-wrapper">
            <Pie
              height={300}
              width={"100%"}
              data={{
                labels: pad.label,
                datasets: [
                  {
                    label: "Nilai",
                    data: [pad.pajak_daerah, pad.retribusi_daerah, pad.kd_yang_dipisahkan, pad.lain_lain_pad],
                    backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)", "rgb(255, 205, 86)", "rgb(153, 255, 135)"],
                    hoverOffset: 4,
                  },
                ],
              }}
              options={{ maintainAspectRatio: false, animations: false }}
            />
          </div>
          <table className="table">
            <tr className="table-head">
              <th className="cell">No</th>
              <th className="cell">Keterangan</th>
              <th className="cell">Nilai</th>
            </tr>
            {pad.label != 0 && (
              <>
                <tr className="table-data">
                  <td className="cell">1</td>
                  <td className="cell">Pajak Daerah</td>
                  <td className="cell">{numberWithCommas(pad.pajak_daerah)}</td>
                </tr>
                <tr className="table-data">
                  <td className="cell">2</td>
                  <td className="cell">Retribusi Daerah</td>
                  <td className="cell">{numberWithCommas(pad.retribusi_daerah)}</td>
                </tr>
                <tr className="table-data">
                  <td className="cell">3</td>
                  <td className="cell">Hasil Pengelolaan Kekayaan Daerah Yang Dipisahkan</td>
                  <td className="cell">{numberWithCommas(pad.kd_yang_dipisahkan)}</td>
                </tr>
                <tr className="table-data">
                  <td className="cell">4</td>
                  <td className="cell">Lain-lain PAD</td>
                  <td className="cell">{numberWithCommas(pad.lain_lain_pad)}</td>
                </tr>
                <tr className="table-head">
                  <th className="cell"></th>
                  <th className="cell">Pendapatan Asli Daerah (PAD)</th>
                  <th className="cell">{numberWithCommas(parseFloat(pad.pajak_daerah) + parseFloat(pad.retribusi_daerah) + parseFloat(pad.kd_yang_dipisahkan) + parseFloat(pad.lain_lain_pad))}</th>
                </tr>
              </>
            )}
          </table>
        </div>
      );
    }
  };

/*
  const infoBphtb = (type) => {
    if (type === "Bphtb") {
      return (
        <div className="chart-card">
          <div className="chart-title">Realisasi BPHTB 2019 s/d 2020 </div>
          <div className="chart-wrapper">
            <Bar
              height={300}
              width={"100%"}
              data={{
                labels: bphtb.label,
                datasets: [
                  {
                    label: "Januari",
                    data: bphtb.januari19,
                    backgroundColor: "#FFC300",
                  },
                  {
                    label: "Januari",
                    data: bphtb.januari20,
                    backgroundColor: "#4690CC",
                  },
                  {
                    label: "Februari",
                    data: bphtb.februari19,
                    backgroundColor: "#FFC300",
                  },
                  {
                    label: "Februari",
                    data: bphtb.februari20,
                    backgroundColor: "#4690CC",
                  },
                ],
              }}
              options={{ maintainAspectRatio: false, animations: false }}
            />
          </div>
          <table className="table">
            <tr className="table-head">
              <th className="cell">No</th>
              <th className="cell">Tahun</th>
              <th className="cell">Target</th>
              <th className="cell">Realisasi</th>
              <th className="cell">%</th>
            </tr>
                
            <tr className="table-data" >
                  <td className="cell">1</td>
                  <td className="cell">2020</td>
                    <td className="cell">392.976.712.306</td>
                    <td className="cell">397.287.952.936</td>
                    <td className="cell">101,10</td>
                    
                  </tr>
                  <tr className="table-data" >
                  <td className="cell">2</td>
                  <td className="cell">2019</td>
                    <td className="cell">440.310.041.800</td>
                    <td className="cell">467.575.231.133</td>
                    <td className="cell">106,19</td>
                  </tr>  
             
          </table>
        </div>
      );
    } 
    else {
      return (
        <div className="chart-card">
          <div className="chart-title">Realisasi BPHTB 2019 s/d 2020</div>
          <div className="chart-wrapper">
            <Bar
              height={300}
              width={"100%"}
              data={{
                labels: bphtb.label,
                datasets: [
                  {
                    label: "Januari",
                    data: bphtb.januari19,
                    backgroundColor: "#FFC300",
                  },
                  {
                    label: "Januari",
                    data: bphtb.januari20,
                    backgroundColor: "#4690CC",
                  },
                  {
                    label: "Februari",
                    data: bphtb.februari19,
                    backgroundColor: "#FFC300",
                  },
                  {
                    label: "Februari",
                    data: bphtb.februari20,
                    backgroundColor: "#4690CC",
                  },
                ],
              }}
              options={{ maintainAspectRatio: false, animations: false }}
            />
          </div>
          <table className="table">
            <tr className="table-head">
              <th className="cell">No</th>
              <th className="cell">Tahun</th>
              <th className="cell">Target</th>
              <th className="cell">Realisasi</th>
              <th className="cell">%</th>
            </tr>
            <tr className="table-data" >
                  <td className="cell">1</td>
                  <td className="cell">2020</td>
                    <td className="cell">392.976.712.306</td>
                    <td className="cell">397.287.952.936</td>
                    <td className="cell">101,10</td>
                    
                  </tr>
                  <tr className="table-data" >
                  <td className="cell">2</td>
                  <td className="cell">2019</td>
                    <td className="cell">440.310.041.800</td>
                    <td className="cell">467.575.231.133</td>
                    <td className="cell">106,19</td>
                  </tr> 
                
          </table>
        </div>
      );
    }
  };*/

  function numberWithCommas(x) {
    return new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(x);
  }

  return (
    <div className="page">
      <Header active="beranda" />

      {/* main */}
      <main className="main">
        <section className="hero">
          <div className="slideshow-wrapper" style={{ transform: `translate(${-index * 100}%, 0)` }}>
            {banner.length > 0 &&
              banner.map((item, idx) => {
                return <img src={BaseURL + "assets/images/banner/" + item.thumbnail} className="hero-image" key={idx} />;
              })}
          </div>

          {/* slide arrow */}
          <div className="slidearrow-wrapper">
            <div
              className="leftArrow"
              onClick={() => {
                setIndex(index === 0 ? 0 : index - 1);
              }}
            >
              <i className="fas fa-chevron-left"></i>
            </div>
            <div
              className="rightArrow"
              onClick={() => {
                setIndex(index === banner.length - 1 ? 0 : index + 1);
              }}
            >
              <i className="fas fa-chevron-right"></i>
            </div>
          </div>

          <a href={banner.length > 0 ? banner[index].link : ""} target="blank" className="slide-title-wrapper">
            <p>{banner.length > 0 && banner[index].judul}</p>
          </a>
        </section>

        <section className="section bg-dark">
          <div className="moving-text-wrapper">
            <marquee width="100%" direction="left" height="100%">
              Selamat datang di website Badan Pendapatan Daerah Kota Bekasi...
            </marquee>
          </div>
        </section>

        <section className="section bg-light">
          <div className="sub-section">
            <div className="section-title-wrapper">
              <div className="section-title">Kunjungi Aplikasi Kami</div>
            </div>
            <div className="informasi-panel">
              <a href="https://ebphtb.bekasikota.go.id/" target="blank" className="informasi-column bg-blue">
                <img className="service-image" src={logoEbphtb} />
              </a>
              <a href="http://sipdah.bekasikota.go.id/" target="blank" className="informasi-column bg-green">
                <img className="service-image" src={logoEsptpd} />
              </a>
			   <a href="https://play.google.com/store/apps/details?id=com.depi_pramadani.pajak_Pbb&hl=in&gl=US" target="blank" className="informasi-column">
                <img className="service-image" src={ipbb} />
              </a>
            </div>
          </div>
        </section>

        <section className="section bg-dark">
          <div className="sub-section">
            <div className="section-title-wrapper">
              <div className="section-title section-title-light">Info Pendapatan Daerah</div>
            </div>
            <div className="chart-menu-wrapper">
         
			 
              <div
                className={chartActive === "PAD" ? "chart-menu chart-menu-active" : "chart-menu"}
                onClick={() => {
                  setInfoApbdShow("PAD");
                  setChartActive("PAD");
                }}
              >
                PAD
              </div>
              <div
                className={chartActive === "Pajak" ? "chart-menu chart-menu-active" : "chart-menu"}
                onClick={() => {
                  setInfoApbdShow("Pajak");
                  setChartActive("Pajak");
                }}
              >
                Pajak
              </div>
              <div
                className={chartActive === "Retribusi" ? "chart-menu chart-menu-active" : "chart-menu"}
                onClick={() => {
                  setInfoApbdShow("Retribusi");
                  setChartActive("Retribusi");
                }}
              >
                Retribusi
              </div>
            </div>
            {infoApbd(infoApbdShow)}
          </div>

         
        </section>

        <section className="section bg-light">
          <div className="sub-section">
            <div className="section-title-wrapper">
              <div className="section-title">Berita Terkini</div>
            </div>
            <div className="informasi-panel">
              {berita.map((item) => {
                return (
                  <div className="informasi-column" key={item.id_berita}>
                    <img className="informasi-headline-thumbnail" src={BaseURL + "assets/images/berita/" + item.thumbnail} />
                    <p className="informasi-headline-date">{item.created_date}</p>
                    <Link to={"/berita-detail/" + item.id_berita}>
                      <p className="informasi-headline-title">{item.judul}</p>
                    </Link>
                  </div>
                );
              })}
            </div>
            <Link to="/berita" className="informasi-button">
              Berita lainnya
            </Link>
          </div>
        </section>

        <section className="section bg-dark">
          <div className="sub-section">
            <div className="section-title-wrapper">
              <div className="section-title section-title-light">Penanggulangan Bencana</div>
            </div>
            <div className="informasi-panel">
              {agenda.map((item) => {
                return (
                  <div className="informasi-column" key={item.id_agenda}>
                    <img className="informasi-headline-thumbnail" src={BaseURL + "assets/images/agenda/" + item.thumbnail} />
                    <p className="informasi-headline-date">{item.created_date}</p>
                    <Link to={"/agenda-detail/" + item.id_agenda}>
                      <p className="informasi-headline-title">{item.judul}</p>
                    </Link>
                  </div>
                );
              })}
            </div>
            <Link to="/agenda" className="informasi-button">
              Agenda lainnya
            </Link>
          </div>

        </section>

    

  

       <section className="section">
          <div className="section-social-media">
            <div className="section-youtube">
              <Link to="berita-video" className="section-youtube-title">
                Berita Video{" "}
                <span>
                  <i class="fas fa-angle-double-right"></i>
                </span>
              </Link>
              {beritaVideo.length > 0 ? (
                <div className="section-youtube-play">
                  <YouTube
                    videoId={youtubePlay.id_video}
                    className="youtube-iframe"
                  />
                </div>
              ) : (
                <div>Belum ada berita Video</div>
              )}
              <div className="section-youtube-grid">
                {beritaVideo.map((item, index) => (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      className="youtube-item"
                      onClick={() => setYoutubePlay(item)}
                    >
                      <img
                        src={item.thumbnail}
                        style={{ width: "100%", objectFit: "cover" }}
                      ></img>
                      <div className="youtube-icon-wrapper">
                        <i class="fab fa-youtube youtube-icon"></i>
                      </div>
                    </div>
                    <div className="youtube-title">{item.judul}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="section-twitter">
              <div className="twitter-iframe-wrapper">
                <Timeline
                  dataSource={{
                    sourceType: "profile",
                    screenName: "bapenda_bekasi",
                  }}
                  options={{ width: "100%", height: "450" }}
                />
              </div>
            </div>
          </div>
        </section>
		
		
	      <section className="section">
          <div className="sub-section">
            <div className="section-title-wrapper">
              <div className="section-title">Link / Tautan</div>
            </div>
            <div className="tautan-panel">
              <div className="tautan-item">
                <img src={logoBekasi} className="logo-tautan"></img>
                <a href="https://www.bekasikota.go.id/" className="tautan-text">
                  Pemerintah Kota Bekasi
                </a>
              </div>
			   <div className="tautan-item">
                <img src={lpse} className="logo-tautan"></img>
                <a href="http://lpse.bekasikota.go.id/eproc4" className="tautan-text">
                  LPSE KOTA BEKASI
                </a>
              </div>
            </div>
          </div>
		  
		

          <div className="sub-section">
            <div className="section-title-wrapper">
              <div className="section-title">Total pengunjung</div>
              
            </div>
            <div className="tautan-panel">
              <div className="tautan-item">
              <div id="histats_counter"></div>
              </div>
            </div>
          </div>
        </section>	
       
        

  
      </main>


      <SubFooter />

      <Footer />
    </div>
  );
};
/*
   <div
                className={chartActive === "APBD" ? "chart-menu chart-menu-active" : "chart-menu"}
               onClick={() => {
                  setInfoApbdShow("APBD");
                  setChartActive("APBD");
               }}
              >
                APBD
             </div>

 <div className="sub-section">
            <div className="section-title-wrapper">
              <div className="section-title section-title-light">Info Pajak BPHTB</div>
            </div>
            <div className="chart-menu-wrapper">
         
			 
              <div
                className={chartActiveBphtb === "Bphtb" ? "chart-menu chart-menu-active" : "chart-menu"}
                onClick={() => {
                  setInfoBphtbShow("Bphtb");
                  setChartActiveBphtb("Bphtb");
                }}
              >
                Tahun 2019 s/d 2020 BPHTB
              </div>
           
            </div>
            {infoBphtb(infoBphtbShow)}
          </div>

*/
export default Beranda;
