import React from "react";
import { Numbering } from "../..";
import "./style.scss";

const PanelAccordion = (props) => {
  let content = props.content.split("(_breakline)");
  let numberingContent = [];
  for (let i = 0; i < content.length; i++) {
    if (content[i].includes("(_numbering_number)")) {
      // content[i] = content[i].replace("(_numbering_number)", "");
      numberingContent = content[i].split("(_break_numbering)");
      content[i] = numberingContent;
    }

    if (content[i].includes("(_numbering_alphabet)")) {
      // content[i] = content[i].replace("(_numbering_alphabet)", "");
      numberingContent = content[i].split("(_break_numbering)");
      content[i] = numberingContent;
    }
  }

  return (
    <div className="item-accordion">
      <div
        className="label-accordion-container"
        onClick={() =>
          props.setIsActive(() => {
            if (props.isActive == props.index) {
              return null;
            } else {
              return props.index;
            }
          })
        }
      >
        <div type="button" className="label-accordion">
          {props.label}
        </div>
        <span className="cross-wrapper">
          <span
            className={
              props.isActive == props.index ? "cross-icon" : "plus-icon"
            }
          ></span>
        </span>
      </div>
      <div
        className={
          props.isActive == props.index
            ? "content-accordion"
            : "content-hide-accordion"
        }
      >
        {content.map((item, index) => {
          if (Array.isArray(item)) {
            if (item[0].includes("(_numbering_number)")) {
              item[0] = item[0].replace("(_numbering_number)", "");
              return (
                <>
                  {item.map((item, index) => (
                    <Numbering number={index + 1} content={item} key={index} />
                  ))}
                </>
              );
            } else {
              item[0] = item[0].replace("(_numbering_alphabet)", "");
              return (
                <>
                  {item.map((item, index) => (
                    <Numbering
                      number={(index + 10).toString(36)}
                      content={item}
                      key={index}
                    />
                  ))}
                </>
              );
            }
          } else {
            return (
              <p
                style={
                  item.includes("(_indent)")
                    ? { marginLeft: 17 }
                    : item.includes("(_indent_2)")
                    ? { marginLeft: 34 }
                    : item.includes("(_indent_3)")
                    ? { marginLeft: 51 }
                    : item.includes("(_indent_4)")
                    ? { marginLeft: 68 }
                    : item.includes("(_bold)")
                    ? { fontWeight: "bold", marginBottom: 10, marginTop: 10 }
                    : { marginLeft: 0 }
                }
                className="paragraf-accordion"
              >
                {item
                  .replace("(_indent)", "")
                  .replace("(_indent_2)", "")
                  .replace("(_indent_3)", "")
                  .replace("(_indent_4)", "")
                  .replace("(_bold)", "")}
              </p>
            );
          }
        })}
      </div>
    </div>
  );
};

export default PanelAccordion;
