import React from "react";
import "./style.scss";

function Button({ title, type, onClick }) {
  if (type !== "submit") {
    return (
      <div className={type == "primary" ? "btn-admin-primary" : type == "secondary" ? "btn-admin-secondary" : "btn-admin-danger"} onClick={onClick}>
        {title}
      </div>
    );
  } else {
    return (
      <button type="submit" className="btn-admin-secondary">
        {title}
      </button>
    );
  }
}

export default Button;
