import React from "react";
import useDocumentTitle from "../../assets/utils/useDocumentTitle";
import { Breadcrumb, Footer, Header, Sidebar } from "../../components";
import "./style.scss";

function JPrestoran() {
  useDocumentTitle("Pajak Restora- BAPENDA BEKASI");
  return (
    <div className="page">
      <Header active="JPrestoran" />

      {/* main */}
      <main className="main-sb">
        <section className="content">
          <Breadcrumb path="Jenis Pelayanan / Pajak Restoran" />
          <div className="page-title-wrapper">
            <div className="page-title">Pajak restoran</div>
          </div>

          {/* <p className="page-text">
          </p> */}

          <p className="subpage-title"><strong>Pajak Restoran</strong></p>
          <p className="page-text">Restoran adalah fasilitas penyedia makanan dan/atau minuman dengan
dipungut bayaran, yang mencakup juga rumah makan, kafetaria, kantin,
warung, bar, dan sejenisnya termasuk jasa boga/katering.
</p>
<p>
Pajak Restoran
Paragraf 1
Nama, Objek, Subjek dan Wajib Pajak
Pasal 11
(1) Dengan nama Pajak Restoran dipungut pajak atas pelayanan yang
disediakan oleh Restoran.
(2) Objek Pajak Restoran adalah pelayanan yang disediakan oleh Restoran.
(3) Pelayanan yang disediakan restoran sebagaimana dimaksud pada ayat (1)
meliputi pelayanan penjualan makanan dan/atau minuman yang oleh
pembeli, baik dikonsumsi di tempat pelayanan maupun dikonsumsi di
tempat lain.
14
(4) Tidak termasuk obyek pajak Restoran sebagaimana dimaksud ayat (2)
adalah pelayanan yang disediakan oleh Restoran yang nilai penjualannya
tidak melebihi Rp. 10.000.000,00 (sepuluh juta rupiah).
Pasal 12
(1) Subjek Pajak Restoran adalah orang pribadi atau Badan yang membeli
makanan dan/atau minuman dari Restoran.
(2) Wajib Pajak Restoran adalah orang pribadi atau Badan yang
mengusahakan Restoran.
Paragraf 2
Dasar Pengenaan dan Tarif Pajak
Pasal 13
Dasar pengenaan Pajak Restoran adalah jumlah pembayaran yang diterima
atau yang seharusnya diterima Restoran.
Pasal 14
Tarif Pajak Restoran ditetapkan sebesar 10% (sepuluh persen).
</p>
         
        
         
         
          
        </section>
        <Sidebar />
      </main>

      <Footer />
    </div>
  );
}

export default JPrestoran;
