import React, { useEffect, useState } from "react";
import BaseURL from "../../assets/utils/baseUrl";
import useDocumentTitle from "../../assets/utils/useDocumentTitle";
import { Breadcrumb, Footer, Header, Sidebar } from "../../components";
import "./style.scss";
import { useLocation } from "react-router-dom";

function BeritaDetail({ match }) {
  useDocumentTitle("Informasi - BAPENDA BEKASI");
  let id = match.params.id;
  const [informasi, setInformasi] = useState("");
  let konten = [];

  const location = useLocation();

  useEffect(() => {
    const getData = async () => {
      await fetch(BaseURL + "api/berita/?id=" + id)
        .then((res) => res.json())
        .then((data) => {
          setInformasi(data.data[0]);
        });
    };
    getData();
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
  }, [location]);

  if (informasi !== "") {
    let str = informasi.konten;
    let kontenArr = str.split("(_newline)");
    for (let i = 0; i < kontenArr.length; i++) {
      konten.push(<p className="informasi-detail-desc">{kontenArr[i]}</p>);
    }
  }

  return (
    <div className="page">
      <Header active="publikasi" />
      <div className="main-sb">
        <div className="content">
          <Breadcrumb path={informasi.judul} parent="berita" />
          <div className="informasi-detail-wrapper">
            <p className="informasi-detail-title">{informasi.judul}</p>
            <p className="informasi-detail-date">
              {informasi.created_date} | {informasi.user}
            </p>
            <div className="informasi-detail-image-wrapper">
              <img src={BaseURL + "assets/images/berita/" + informasi.thumbnail} className="informasi-detail-image"></img>
            </div>
            <div className="informasi-detail-desc-wrapper">{konten}</div>
          </div>
        </div>
        <Sidebar />
      </div>
      <Footer />
    </div>
  );
}

export default BeritaDetail;
