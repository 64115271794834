export const UnitPelayanan = [
  {
    unit: "Bapenda Kota Bekasi",
    alamat:
      "Jl. Ir. H. Juanda No.100, Rt.001/Rw.005, Margahayu, Kec. Bekasi Timur Kota Bekasi, Jawa Barat 17113", 
    telp: "(021) 88397963, email:uptbtimur@yahoo.com",
    koordinat: {
      lat: -6.244178048073945,
      lng: 107.00631259611676,
    },
  },
  {
    unit: "UPTD Kecamatan Bekasi Timur",
    alamat:"Jl.Ki Mangun Sarkoro City Club Taman Kota Bekasi RT 004/02 Bekasi Jaya, Bekasi Timur Kode Pos: 171112",
    telp: "021-85433900",
    koordinat: {
      lat: -6.2389603,
      lng: 107.0086799,
    },
  },
  {
    unit: "UPTD Kecamatan Bekasi Utara",
    alamat:"Jl.KH.Muchtar Tabrani Perum. Kav. Tegal Perintis Blok A No.2 Rt 006 Rw 002",
    telp: " email:uptdutara@gmail.com",
    koordinat: {
      lat: -6.215118797273666,
      lng: 107.0013572916146,
    },
  },
  {
    unit: "UPTD Kecamatan Bekasi Barat",
    alamat:"Jl.Jenderal Sudirman Komplek Pertokoan Kranji Ruko No.5, Kelurahan Kranji, Kec.bekasi Barat Kode Pos : 17135",
    telp: "Uptdbarat1@gmail.com",
    koordinat: {
      lat: -6.2296403,
      lng: 106.9850699,
    },
  },
   {
    unit: "UPTD Kecamatan Bekasi Selatan",
    alamat:"Jl.Pulo Sirih Barat Jaya Blok FE No.307 Kelurahan Jaka Satia , Kecamatan Bekasi selatan Kode Pos: 17147 ",
    telp: "021-82735563, email:uptd.selatan@yahoo.com",
    koordinat: {
      lat: -6.2661917,
      lng: 106.9534301,
    },
  },
  {
    unit: "UPTD Kecamatan Bantar Gebang",
    alamat:"Jl.Mustika Sari No.57 Rt 001/004 Kelurahan Bantar Gebang Kecamatan Bantar Gebang Kode Pos :17151",
    telp: "email: uptdbtg@gmail.com",
    koordinat: {
      lat: -6.30625379856369,
      lng: 106.98781703543696,
    },
  },
  {
    unit: "UPTD Kecamatan Mustika Jaya",
    alamat:"Jl.Raya Mustika Jaya -Legenda Kelurahan Mustika Jaya Kec.Mustika Jaya, Kode Pos : 17158 ",
    telp: "021-82621944",
    koordinat: {
      lat: -6.2661917,
      lng: 106.9359207,
    },
  },
  {
    unit: "UPTD Kecamatan Rawa Lumbu",
    alamat:"Jl.Raya Siliwangi Km.5 Ruko Pasar Segar RA.06 Kel.Bojong Rawalumbu Kec.Rawa Lumbu Kode Pos: 17116",
    telp: "021-82736277 email: uptbrwlumbu@gmail.com",
    koordinat: {
      lat: -6.2661917,
      lng: 106.9359207,
    },
  },
  {
    unit: "UPTD Kecamatan Jati Asih",
    alamat:" Jl.Bima II no.23  Rt 004/001 Kel.Jati asih Kec.Jati Asih",
    telp: "email: ptb.pendapatan.jatiasih@gmail.com",
    koordinat: {
      lat: -6.2661917,
      lng: 106.9359207,
    },
  },
  {
    unit: "UPTD Kecamatan Jati Sampurta",
    alamat:"Jl.Raya Kranggan No.1 Kode Pos: 17433",
    telp: "email: Uptdjatisampurna100@gmail.com",
    koordinat: {
      lat: -6.2661917,
      lng: 106.9359207,
    },
  },
  {
    unit: "UPTD Kecamatan Pondok Melati",
    alamat:"Jl.Raya Pasar Kecapi Rt 009 Rw 16 No.26 Kel.Jati Rahayu Kec. Pondok Melati Kode Pos: 17414",
    telp: "email: uptdpondokmelati@gmail.com",
    koordinat: {
      lat: -6.297096755343718,
      lng: 106.93141038390186,
    },
  },
  {
    unit: "UPTD Kecamatan Pondok Gede",
    alamat:" Jl.Raya Jatiwaringin No.41 Kel.Pondok Gede Kec.Pondok Gede Kode Pos: 17411",
    telp: "email:uptd.pdgede@gmail.com",
    koordinat: {
      lat: -6.27361533248806,
      lng: 106.91428062236194,
    },
  },
  {
    unit: "UPTD Kecamatan Medan Satria",
    alamat:"Jl.Harapan Indah No.9 Kelurahan Medan Satria Kecamatan Medan Satria Kode Pos : 17135",
    telp: "email:Uptdmedansatria@gmail.com",
    koordinat: {
      lat:-6.1801599420714695,
      lng:106.9740103576409,
    },
  },
  {
    unit: "MPP Pelayanan Public Mall Bekasi Trade Center Bekasi Timur",
    alamat:"",
    telp: "",
    koordinat: {
      lat: -6.2591875,
      lng: 107.0185661,
    },
  },
  {
    unit: "MPP Pelayanan Public Cibubur Mall Jati Sampurna",
    alamat:"",
    telp: "",
    koordinat: {
      lat:-6.3762355,
      lng:106.91331,
    },
  },
  {
    unit: "MPP Pelayanan Public Mall Atrium Pondok Gede",
    alamat:"",
    telp: "",
    koordinat: {
      lat: -6.283862425912767,
      lng: 106.91352160283319,
    },
  },
];
