import React from "react";
import useDocumentTitle from "../../assets/utils/useDocumentTitle";
import { Breadcrumb, Footer, Header, Sidebar } from "../../components";
import "./style.scss";

function VisiMisi() {
  useDocumentTitle("Visi dan Misi - BAPENDA BEKASI");
  return (
    <div className="page">
      <Header active="profil" />

      {/* main */}
      <main className="main-sb">
        <section className="content">
          <Breadcrumb path="Profil / Visi dan Misi" />
          <div className="page-title-wrapper">
            <div className="page-title">Visi & Misi</div>
          </div>

          {/* <p className="page-text">
            Dalam rangka pelaksanaan Peraturan Pemerintah Nomor 18 Tahun 2016 tentang Perangkat Daerah dan Peraturan Menteri Dalam Negeri Nomor 97 Tahun 2016 tentang Perangkat Daerah Provinsi DKI Jakarta, maka berdasarkan Peraturan Gubernur
            Nomor 154 Tahun 2020 tentang Organisasi dan Tata Kerja Perangkat Daerah dibentuklah Badan Pendapatan Daerah (Bapenda) Provinsi DKI Jakarta.
          </p> */}

          <p className="subpage-title">Visi</p>
          <p className="page-text">Pengelola Pendapatan Daerah yang Profesional dan Amanah</p>

          <p className="subpage-title">Misi</p>
          <p className="page-text">
1. Meningkatkan Tata Kelola Pelayanan Pajak Daerah<br></br>

2. Memantapkan Kinerja Kompetensi Aparatur dan Organisasi<br></br>

3. Mengoptimalkan Penerimaan Pendapatan Daerah <br></br>

          </p>

        
         
          <p className="subpage-title">Sejarah Bapenda Kota Bekasi </p>
          <p className="page-text"><br></br>
Pada masa kepemimpinan Kepala Daerah dengan sebutan pertama kali Walikota Bekasi dimulai tahun 1998 s/d sekarang, saat itu Walikota Bekasi Periode 1998—2003 dipimpin oleh H. N. SONTANIE. Pengelolaan Pendapatan Daerah pada masa kepemimpinan H. N. SONTANIE mengalami 2 kali perubahan yakni :<br></br>
<br></br>

Periode Pertama tahun 1998—2000 pengelolaan pendapatan dibawah naungan lembaga berbentuk dinas yang bernama Dinas Pendapatan Daerah (Dipenda) Kota Bekasi.<br></br>
Periode Kedua tahun 2001—2004 pengelolaan pendapatan dibawah naungan lembaga berbentuk Badan yang bernama Badan Pengelolaan Keuangan, Kekayaan dan Aset Daerah (Bakukeda) Kota Bekasi.<br></br>
Periode selanjutnya yakni tahun 2003—2008 Walikota Bekasi dipimpin oleh AKHMAD ZURFAIH, masa kepemimpinan beliau pengelolaan Pendapatan dimasa awal kepemimpinannya masih bernama Bakukeda Kota Bekasi. Memasuki tahun kedua kepemimpinan beliau yakni di Bulan Juni 2004 pengelolaan pendapatan daerah masih dibawa naungan lembaga berbentuk Badan yakni bernama Badan Pendapatan Daerah Kota Bekasi (Bapenda) Kota Bekasi periode tahun 2004—2008.<br></br>
<br></br>

Walikota Bekasi selanjutnya periode 2008—2013 kepemimpinan Pemerintah Kota Bekasi dipegang oleh H. MOCHTAR MOHAMAD, pada masa beliau ditahun pertama pengelolaan pendapatan daerah masih bernama Bapenda Kota Bekasi. memasuki awal tahun kedua kepemimpinan beliau pengelolaan pendapatan daerah menyesuikan dengan Peraturan Pemerintah Nomor 41 Tahun 2007 tentang Organisasi Perangkat Daerah, bahwa berdasarkan Peraturan Pemerintah tersebut pengelolaan pendapatan daerah dibawah Satuan Kerja Perangkat Daerah (SKPD) berbentuk Dinas yakni yang bernama Dinas Pengelolaan Pendapatan, Keuangan dan Aset Daerah (DPPKAD) periode tahun 2009—2011. Dikarenakan terlalu besarnya beban lingkup dari DPPKAD Kota Bekasi sehingga kurangnya fokus dalam urusan pengelolaan pendapatan maka berdasarkan Peraturan Daerah Kota Bekasi Nomor 06 Tahun 2010 tentang Perubahan Peraturan Daerah Kota Bekasi Nomor 06 Tahun 2008 tentang Dinas Daerah, maka pengelolaan pendapatan daerah berubah menjadi Dinas Pendapatan Daerah (Dispenda) Kota Bekasi, walaAupun Perda tentang Dispenda telah disahkan pada tahun 2010 namun berlaku efektifnya Dispenda Kota Bekasi yakni pada Bulan Juni Tahun 2011 s/d sekarang.<br></br>
          </p>
          
        </section>
        <Sidebar />
      </main>

      <Footer />
    </div>
  );
}

export default VisiMisi;
