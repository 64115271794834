import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";

import useDocumentTitle from "../../assets/utils/useDocumentTitle";
import { Breadcrumb, Footer, Header, Sidebar } from "../../components";
import BaseURL from "../../assets/utils/baseUrl";

function Laporanppid() {
  useDocumentTitle("Laporan PPID - BAPENDA BEKASI");

  const [laporan, setLaporan] = useState([]);

  useEffect(() => {
    fetch(BaseURL + "api/laporan_Ppid")
      .then((res) => res.json())
      .then((data) => {
        if (data.data) {
          let tableData = [];
          for (let i = 0; i < data.data.length; i++) {
            tableData.push({
              judul: data.data[i].judul,
              deskripsi: data.data[i].deskripsi,
              link: data.data[i].link,
            });
          }
          setLaporan(tableData);
        }
      })
      .catch((error) =>
        console.log("sorry, there is a mistake while loading data")
      );
  }, []);

  const columns = [
    {
      name: "judul",
      label: "Judul",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <div style={{ flex: 1 }}>{value}</div>;
        },
      },
    },
    {
      name: "deskripsi",
      label: "Deskripsi",
      options: {
        filter: true,
        sort: true,
      },
      customBodyRender: (value) => {
        return <div style={{ flex: 1 }}>{value}</div>;
      },
    },
    {
      name: "link",
      label: "Link",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <div style={{ flex: 1 }}>
              <a href={value} target="_blank" rel="noopener noreferrer">
                {value}
              </a>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    sort: true,
    filter: false,
    download: false,
    print: false,
    selectableRows: false,
  };

  return (
    <div className="page">
      <Header active="Laporanppid" />

      {/* main */}
      <main className="main-sb">
        <section className="content">
          <Breadcrumb path="Laporanppid" />
          <div className="page-title-wrapper">
            <div className="page-title">Laporan PPID</div>
          </div>

          <MUIDataTable
            title={""}
            data={laporan}
            columns={columns}
            options={options}
            download={false}
            className="table"
          />
        </section>
        <Sidebar />
      </main>

      <Footer />
    </div>
  );
}

export default Laporanppid;
