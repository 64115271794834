import React, { useEffect, useState } from "react";
import BaseURL from "../../assets/utils/baseUrl";
import useDocumentTitle from "../../assets/utils/useDocumentTitle";
import { Breadcrumb, Footer, Header, Sidebar } from "../../components";
import "./style.scss";

function Pejabat() {
  useDocumentTitle("Pejabat - BAPENDA BEKASI");
  const [pejabat, setPejabat] = useState([]);

  useEffect(() => {
    const getData = async () => {
      await fetch(BaseURL + "api/pejabat")
        .then((res) => res.json())
        .then((data) => setPejabat(data.data));
    };
    getData();
  }, []);

  return (
    <div className="page">
      <Header active="profil" />

      {/* main */}
      <main className="main-sb">
        <section className="content">
          <Breadcrumb path="Profil / Pejabat" />
          <div className="page-title-wrapper">
            <div className="page-title">Pejabat</div>
          </div>

          <div className="pejabat-list-wrapper">
            {pejabat.length > 0 &&
              pejabat.map((item, idx) => {
                return (
                  <div className="pejabat-list-item" key={idx}>
                    <img className="pejabat-image" src={BaseURL + "/assets/images/pejabat/" + item.foto}></img>
                    <div className="pejabat-desc">
                      <div className="pejabat-desc-item">
                        <p className="pejabat-desc-title">Nama</p>
                        <div className="pejabat-desc-value">
                          <p>:</p>
                          <p>{item.nama_pejabat}</p>
                        </div>
                      </div>
                      <div className="pejabat-desc-item">
                        <p className="pejabat-desc-title">NIP</p>
                        <div className="pejabat-desc-value">
                          <p>:</p>
                          <p>{item.nip}</p>
                        </div>
                      </div>
                      <div className="pejabat-desc-item">
                        <p className="pejabat-desc-title">Jabatan</p>
                        <div className="pejabat-desc-value">
                          <p>:</p>
                          <p>{item.nama_jabatan}</p>
                        </div>
                      </div>
                      {item.nama_jabatan.toLowerCase().includes("kepala bapenda") || item.nama_jabatan.toLowerCase().includes("sekretaris bapenda") ? (
                        <div />
                      ) : (
                        <div className="pejabat-desc-item">
                          <p className="pejabat-desc-title">Bidang</p>
                          <div className="pejabat-desc-value">
                            <p>:</p>
                            <p>{item.nama_bidang}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </section>
        <Sidebar />
      </main>

      <Footer />
    </div>
  );
}

export default Pejabat;
