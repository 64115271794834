import React from "react";
import useDocumentTitle from "../../assets/utils/useDocumentTitle";
import { Breadcrumb, Footer, Header, Sidebar } from "../../components";
import "./style.scss";

function Pppj() {
  useDocumentTitle("Pajak PPJ- BAPENDA BEKASI");
  return (
    <div className="page">
      <Header active="Pppj" />

      {/* main */}
      <main className="main-sb">
        <section className="content">
          <Breadcrumb path="Jenis Pelayanan / Pajak PPJ" />
          <div className="page-title-wrapper">
            <div className="page-title">Pajak PPJ</div>
          </div>

          {/* <p className="page-text">
          </p> */}

          <p className="subpage-title"><strong>Pajak PPJ</strong></p>
          <p className="page-text">Pajak Penerangan Jalan adalah pajak atas penggunaan tenaga listrik, baik
yang dihasilkan sendiri maupun diperoleh dari sumber lain.
</p>
<p className="page-text">
(1) Tarif Pajak Penerangan Jalan ditetapkan sebagai berikut :
a. Tarif Rumah Tangga :
1. Pengguna 450 watt = 3 % (tiga persen);
2. Pengguna 900 watt = 5 % (lima persen);
3. Pengguna 1.300 watt = 7 % (tujuh persen);
23
4. Pengguna 2.200 watt = 8 % (delapan persen);
5. Pengguna 3.500 watt = 8,5 % (delapan koma lima persen);
6. Pengguna 4.400 watt ke atas = 9 % (sembilan persen).
b. Fasilitas Sosial dan umum :
1. Sosial murni 0 % (nol persen);
2. Sosial komersil 3 % (tiga persen).
c. Tarif untuk bisnis :
1. Pengguna 450 watt = 4 % (empat persen);
2. Pengguna 900 watt = 6 % (enam persen);
3. Pengguna 1.300 watt = 7,5 % (tujuh koma lima persen);
4. Pengguna 2200 watt = 8,5 % (delapan koma lima persen);
5. Pengguna 3.500 watt = 9 % (sembilan persen);
6. Pengguna 4.400 watt ke atas = 9,5 % (sembilan koma lima persen).
d. penggunaan tenaga listrik dari sumber lain oleh industri,
pertambangan minyak bumi dan gas alam = 3 % (tiga persen);
e. penggunaan tenaga listrik yang dihasilkan sendiri untuk industri dan
bukan industri = 1,5% (satu koma lima persen).
(2) Penggunaan tenaga listrik dari sumber lain oleh industri, pertambangan
minyak bumi dan gas alam, tarif pajak penerangan jalan ditetapkan
sebesar 3% (tiga persen).
(3) Penggunaan tenaga listrik yang dihasilkan sendiri, tarif pajak penerangan
jalan ditetapkan sebesar 1,5% (satu koma lima persen).
</p>
         
        
         
         
          
        </section>
        <Sidebar />
      </main>

      <Footer />
    </div>
  );
}

export default Pppj;
