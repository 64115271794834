import React, { useEffect, useState } from "react";
import "./style.scss";
import { TextField } from "@material-ui/core";
import { Button } from "../../../components";
import { LoaderBlue, logoBekasi } from "../../../assets";
import PropTypes from "prop-types";
import BaseURL from "../../../assets/utils/baseUrl";

function Login({ setToken }) {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [modal, setModal] = useState(false);

  const handleSubmit = async (e) => {
    setModal(true);
    if (username != "" && password != "") {
      const data = new FormData();
      data.append("username", username);
      data.append("password", password);
      try {
        fetch(BaseURL + "api/login", {
          method: "POST",
          body: data,
        })
          .then((response) => response.json())
          .then((data) => {
            setUserName("");
            setPassword("");
            setModal(false);
            if (data.status) {
              setToken(data.data[0]);
            } else {
              alert("Login gagal");
            }
          })
          .catch((error) => console.log("Gagal: ", error));
      } catch (error) {
        setModal(false);
        alert("Terjadi kesalahan pada sistem");
        console.log(error);
      }
    } else {
      setModal(false);
      setUserName("");
      setPassword("");
      alert("Login gagal");
    }
  };

  return (
    <div className="auth-page">
      <img src={logoBekasi} style={{ width: 200, objectFit: "contain" }}></img>
      <div style={{ width: 20 }}></div>
      <div className="auth-card">
        <p className="form-label">Login disini</p>
        <form className="form-admin">
          <TextField id="username" label="Username" variant="outlined" onChange={(e) => setUserName(e.target.value)} style={{ marginTop: 10, marginBottom: 10 }} value={username} />
          <TextField type="password" id="password" label="Password" variant="outlined" onChange={(e) => setPassword(e.target.value)} style={{ marginTop: 10, marginBottom: 10 }} value={password} />

          <div className="form-option">
            <Button title="Login" type="primary" onClick={() => handleSubmit()} />
          </div>
        </form>
      </div>

      {/* modal */}
      <div className={modal ? "darker-bg-admin" : "darker-bg-admin hide"}>
        {/* form */}
        {/* <div className="modal-admin"></div> */}
        <img src={LoaderBlue} style={{ margin: 20, width: 50, position: "absolute" }}></img>
      </div>
    </div>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export default Login;
