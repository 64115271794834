// (_bold) => tulisan tebal
// (_indent) => indentasi paragram 17px
// (_indent_2) => indentasi paragram 34px
// (_indent_3) => indentasi paragram 52px
// (_indent_4) => indentasi paragram 68px
// (_numbering_number) => penanda numbering/penomoran dengan angka. cukup sekali diawal bagian penemoran.
// (_numbering_alphabet) => penanda numbering/penomoran dengan huruf. cukup sekali diawal bagian penemoran.
// (_breakline) => baris baru
// (_break_numbering) => baris baru untuk numbering/penomoran

// note: untuk numbering hanya bisa diterapkan pada penomoran tidak bertingkat (1 level). contoh => Referensi dan Dasar Pengenaan

export const items = [
  {
    label: "PENDAFTARAN BARU PBB (PAJAK BUMI DAN BANGUNAN)",
    content: `
	1.	Mengisi formulir Pendaftaran Data Baru (_breakline)
2.	Mengisi Surat Pemberitahuan Obyek Pajak (SPOP) denganjelas, benardanlengkap (_breakline)
3.	Mengisi Lampiran Surat Pemberitahuan Obyek Pajak (LSPOP) untuk bangunan (_breakline)
4.	Surat Keterangan dari Kelurahan yang ditandatangani olehLurah (_breakline)
5.	Surat kuasa dan dalam hal SPOP permohonan di isi dan ditandatangani oleh Kuasa Wajib Pajak dan Bermaterai 6000 (_breakline)

	Permohonan dilampirkan :(_breakline)
a.	Fotocopy Identitas WajibPajak/Pemohon (KTP)(_breakline)
b.	Fotocopy Identitas yang diberikan kuasa (KTP) apabila dikuasakan(_breakline)
c.	Fotocopy salah satu bukti surat tanah :(_breakline)
-	Untuk Sertifikat, FC Sertifikat sesuai dengan Warna Asli(_breakline)
-	Untuk AJB, FC AJB sesuai dengan Warna Asli dan atau Dilegalisir PPAT/PPATS yang Berwenang(_breakline)
d.	Untuk Girik / ipeda / spop / letter C / surat kavling: Dilegalisir oleh Lurah setempat, dan disertakan Surat Keterangan Tidak Sengkata(_breakline)
e.	Fotocopy  IMB, apabila tidak ada IMB dapat menggunakan Surat Keterangan dari Lurah dilengkapi dengan foto bangunan(_breakline)
f.	Fotocopy SPPT tetangga yang bersebelahan dengan obyekpajak yang didaftarkan(_breakline)
Catatan :(_breakline)
1.	Apabila berkas yang dilampirkan kurangmeyakinkan dapat dilengkapi dengan surat keterangan tidak sengketa dan surat penguasaan sporadic dari kelurahan(_breakline)
2.	Apabila letak obyek pajak tidak ketahui dalam peta SIG dapat dilakukan verifikasi lapangan untuk mengetahui letak relative obyek pajak tersebut dalam peta SIG untu kGirik / ipeda / spop / letter C / suratkavling: Dilegalisir oleh Lurah setempat, dan disertakan(_breakline)
3.	Surat Keterangan Tidak Sengkata(_breakline)
4.	Fotocopy  IMB, apabila tidak ada IMB dapat menggunakan Surat Keterangan dari Lurah dilengkapi dengan fotobangunan.(_breakline)
    
5.	Fotocopy SPPT tetangga yang bersebelahan dengan obyek pajak yang didaftarkan (_breakline)
Catatan : (_breakline)
1.	Apabila berkas yang dilampirkan kurang meyakinkan dapat dilengkapi dengan surat keterangan tidak sengketa dan surat penguasaan sporadic dari kelurahan (_breakline)
2.	Apabila letak obyek pajak tidak ketahui dalam peta SIG dapat dilakukan verifikasi lapangan untuk mengetahuiletak relative obyek pajak tersebut dalam peta SIG. (_breakline)

3.	Apabilasertifikat / surattanahmasihatasnama orang tua yang sudahmeninggalharusmelampirkansuratketeranganwaris. (_breakline)
4.	Sertifikat Program Pendaftaran Tanah Sistematis Lengkap (PTSL) yang belum melakukan validasi BPHTB diberikan kebijakan pelayanan pendaftaran data baru PBB dengan ketentuan nama wajib pajak di SPPT PBB di berikan kode “PTSL”  (_breakline)

    `,
  },
  {
    label: "MUTASI HABIS PBB YANG TIDAK PERLU PENETAPAN SPPT PBB",
    content: `
  1.	Mengisi formulir 
  pengajuan MutasiObyek/Subyek PBB (_breakline)
2.	Mengisi Surat Pemberitahuan Obyek Pajak (SPOP) denganjelas, benar dan lengkap (_breakline)
3.	Mengisi Lampiran Surat Pemberitahuan Obyek Pajak (LSPOP) apabila ada bangunan (_breakline)
4.	Surat kuasa dan dalam hal SPOP diisi dan ditandatangani oleh Kuasa Wajib Pajak dan Bermaterai (_breakline)
Permohonan dilampirkan : (_breakline)
a.	Fotocopy Identitas Wajib Pajak (_breakline)
b.	Fotocopy Identitas yang diberikan kuasa (KTP) apabila dikuasakan(_breakline)
c.	Fotocopy salah satu bukti kepemilikan tanah (Sertifikat, AJB)(_breakline)
d.	Fotocopy SPPT PBB (_breakline)
e.	Fotocopy SSPD BPHTB sejak 2011 yang sudah di Validasi oleh Bapenda Kota Bekasi apabila terjadi perubahan nama subjek pajak
(_breakline)
    `,
  },
  {
    label: "MUTASI HABIS YANG MERUBAH/MEMERLUKAN PENETAPAN & MUTASI SEBAGIAN SPPT PBB",
    content: `
    1.	Mengisi formulir pengajuan Mutasi Obyek/Subyek PBB (_breakline)
2.	Mengisi Surat Pemberitahuan Obyek Pajak (SPOP) dengan jelas, benar dan lengkap (_breakline)
3.	Mengisi Lampiran Surat Pemberitahuan Obyek Pajak (LSPOP) apabila ada bangunan (_breakline)
4.	Surat kuasa dan dalam hal SPOP diisi dan ditandatangani oleh Kuasa Wajib Pajak dan Bermaterai (_breakline)
Permohonan dilampirkan : (_breakline)
a.	Fotocopy Identitas Wajib Pajak (_breakline)
b.	Fotocopy Identitas yang diberikan kuasa (KTP) apabila dikuasakan (_breakline)
c.	Fotocopy bukti kepemilikan tanah (Sertifikat, AJB) (_breakline)
d.	Fotocopy salah satu bukti bangunan (IMB), atau Surat Keterangan Lurah mengenai bangunan apabila IMB tidakada. (_breakline)
e.	Fotocopy SPPT PBB Tahun Berjalan (_breakline)
f.	Fotocopy SPPT PBB Induk atau SPPT PBB asal Tahun Berjalan ( Mutasi Sebagian) (_breakline)
g.	Fotocopy SSPD BPHTB sejak 2011 yangsudah di ValidasiolehBapenda Kota Bekasi apabila terjadi perubahan nama subjek pajak (_breakline)
Persyaratan tambahan: (_breakline)
•	Mengisi Surat Pemberitahua nObyek Pajak (SPOP) Induk untuk mutasi sebagian apabila masih ada sisa tanah dalam SPPT Induk (Mutasi Sebagian) (_breakline)
•	Melampirkan fotocopy site plan untuk mutasi yang dilakukan oleh developer atau pengembang perumahan (tidak ada perubahan nama subjek pajak) (_breakline)
•	Lunas tagihan PBB (_breakline)

    `,
  },
  {
    label: "PEMBETULAN  SPPT PBB YANG MERUBAH KETETAPAN DAN PEMBETULAN SPPT PBB YANG TIDAK MERUBAH KETETAPAN",
    content: `
    1.	Mengisi permohonan pembetulan SPPT PBB (_breakline)
2.	Mengisi Surat Pemberitahuan ObyekPajak (SPOP) denganjelas, benardanlengkap. (_breakline)
3.	Mengisi Lampiran Surat Pemberitahuan ObyekPajak (LSPOP) untuk pembetulan bangunan. (_breakline)
4.	Surat kuasa dan dalam hal SPOP diisi dan ditandatangani oleh Kuasa Wajib Pajak dan Bermaterai (_breakline)
Permohonan dilampirkan : (_breakline)
a.	Fotocopy Identitas WajibPajak/Pemohon (KTP) (_breakline)
b.	Fotocopy Identitas yang diberikan kuasa (KTP) apabila dikuasakan (_breakline)
c.	Asli SPPT PBB Tahun Berjalan (_breakline)
Persyaratan tambahan : (_breakline)
	Untuk Pembetulan NJOP Bumi (_breakline)
-Lampirkan fotocopy SPPT tetangga tahun berjalan Untuk Pembetulan Luas Tanah  (_breakline)
-Lampirkan fotocopy AJB atau Sertifikat (_breakline)
	Untuk Pembetulan Luas Bangunan (Penambahan Bangunan) (_breakline)
-	Lampirkan fotocopy IMB dan foto bangunan terbaru, atau Surat Keterangan Lurah mengenai bangunan apabila tidak ada IMB. (_breakline)
	Untuk Pembetulan Nama Subyek Pajak (_breakline)
-	Lampirkan fotocopy sertifikat/AJB dan atau KTP (_breakline)
	Untuk Pembetulan Alamat Subjek/ObyekPajak (_breakline)
-	Surat Keterangan dari Kelurahan untuk Pembetulan Alamat Objek  yang pindah kelurahan dan fotocopy SPPT PBB tetangga. (_breakline)
-	KTP apabila Subjek dan Obyek PBB dalam lokasi yang sama (_breakline)

    `,
  },
  {
    label: "PENGURANGAN DAN PENGHAPUSAN DENDA ADMINISTRASI PBB (PAJAK BUMI DAN BANGUNAN)",
    content: `
    1.	Fotocopy KTP Wajib Pajak dan KK (_breakline)
2.	Fotocopy SPPT PBB yang dimintakan Pengurangan/Penghapusan denda administrasi (_breakline)
3.	Tidak Memiliki Tunggakan PBB (_breakline)
4.	Melunasi Pokok Pajak yang dimintakan Pengurangan/Penghapusan denda administrasinya (_breakline)
5.	Surat keterangan tidak mampu dari kelurahan (bagi wajib Pajak bukan Pensiunan) (_breakline)
6.	Fotocopy Bukti Kepemilikan tanah (_breakline)
7.	Surat kuasa jik adi kuasakan (_breakline)
8.	Fotocopy SK Pensiun/Veteran (_breakline)
9.	Dokumen yang menunjukkan bahwa Wajib Pajak tidak mampu/kesulitan likuiditas (Neraca R/L) (untuk Wajib Pajak Badan/Perusahaan/Yayasan) (_breakline)
10.	AD/ART dan SOTK bagi yang berbadan hukum (untuk Wajib Pajak Badan/Perusahaan/Yayasan) (_breakline)

    `,
  },
  {
    label: "SALINAN SPPT PBB (PAJAK BUMI DAN BANGUNAN)",
    content: `
	1.	Mengisi formulir salinan SPPT PBB (_breakline)
2.	Surat kuasa dan dalam hal permohonan diisi dan ditandatangani oleh Kuasa Wajib Pajak dan Bermaterai (_breakline)
Permohonan di lampirkan : (_breakline)
a.	Fotocopy Identitas Wajib Pajak/Pemohon (KTP) (_breakline)
b.	Fotocopy Identitas yang diberikan kuasa (KTP) apabiladikuasakan (_breakline)
c.	Fotocopy SPPT tahun sebelumnya (_breakline)
d.	Print Out Lunas PBB (_breakline)
Catatan : (_breakline)
Apabila nama permohonan tidak sesuai dengan nama yang tertera di dalam SPPT, maka harus dilampirkan buktikepemilikan tanah (AJB, Sertifikat) untuk mengetahui riwayat kepemilikan SPPT sebelumnya
(_breakline)
	
    `,
  },
  {
    label: "PENERBITAN SURAT KETERANGAN NJOP PBB (PAJAK BUMI DAN BANGUNAN)",
    content: `
    1.	Mengisi permohonan Surat Keterangan NJOP  PBB (Form tersedia) (_breakline)
2.	Surat kuasa (apabila dikuasakan) (_breakline)
3.	Lunas PBB (_breakline)
Permohonan dilampirkan : (_breakline)
a.	Fotocopy Identitas Wajib Pajak/Pemohon (KTP) (_breakline)
b.	Fotocopy Identitas yang diberikan kuasa (KTP) apabila dikuasakan (_breakline)
c.	Fotocopy SPPT tahun sebelumnya (_breakline)
Catatan : (_breakline)
Apabila nama permohonan tidak sesuai dengan nama yang tertera di dalam SPPT, maka harus dilampirkan bukti kepemilikan tanah (AJB, Sertifikat) untuk mengetahui riwayat kepemilikan SPPT sebelumnya
(_breakline)
    `,
  },
  {
    label: "BUKA BLOKIR SPPT PBB",
    content: `
	Jika Permohonan hanya untuk pembukaan blokir tanpa perlu Cetakan SPPT PBB, Pemohon cukup melampirkan : (_breakline)
-	Print Out Lunas PBB (_breakline)
-	Bukti Bayar PBB yang Terhutang (_breakline)
-	Foto copy Surat Tanah (_breakline)
-	Foto copy KTP (_breakline)
-	Lunas PBB (_breakline)
   `,
  },
  {
    label: "PENGURANGAN POKOK PBB (PAJAK BUMI DAN BANGUNAN)",
    content: `
    1.	Mengisi formulir Pengurangan Pokok PBB (_breakline)
2.	Fotocopy KTP Wajib Pajak dan KK (_breakline)
3.	Fotocopy SPPT PBB yang dimintakan pengurangan (_breakline)
4.	Fotocopy bukti lunas PBB tahun-tahun sebelumnya (_breakline)
5.	Fotocopy slip gaji / penghasilan perbulan (_breakline)
6.	Surat keterangan tidak mampu dari kelurahan yang ditandatangani Lurah (bagi Wajib Pajak bukan Pensiunan) (_breakline)
7.	Fotocopy pembayaran rekening listrik, PAM, telepon (_breakline)
8.	Fotocopy Bukti Kepemilikan tanah (Kecuali Veteran) (_breakline)
9.	Surat Kuasa jika dikuasakan (_breakline)
10.	Fotocopy SK Pengurangan tahun sebelumnya jika ada (_breakline)
11.	Fotocopy SK Pensiun (bagi yang Pensiunan) (_breakline)
12.	Fotocopy SK Veteran (bagi yang Veteran) (_breakline)
13.	Surat pernyataan yang menyatakan 25% (duapuluh lima persen) dari jumlah tempat tidur digunakan untuk pasien tidak mampu (untuk Wajib Pajak Rumah Sakit) (_breakline)
14.	Dokumen yang menunjukan bahwa wajib pajak tidak mampu/kesulitan likuiditas (Neraca R/L) (untuk Wajib PajakBadan/Perusahaan/Yayasan) (_breakline)
15.	AD/ART dan SOTK bagi yang berbadan hukum (untuk Wajib Pajak Badan/Perusahaan/Yayasan (_breakline)

    `,
  },
  {
    label: "PEMBATALAN SPPT PBB",
    content: `
    
   1.	Mengisi formulir Pembatalan SPPT PBB(_breakline)
   
2.	Asli SPPT dan/atau SKPD PBB P2 yang diajukan pembatalan dan fotokopi SPPT PBB yang benar(_breakline)
3.	Fotocopy KTP dan Kartu Keluarga Wajib Pajak / Kitas Lainnya(_breakline)
4.	Surat Kuasa dengan Materai Rp.6000 (Jika dikuasakan)(_breakline)
5.	Fotocopy Bukti Kepemilikan Tanah (Sertifikat/AJB)(_breakline)
6.	Surat Keterangan dari Kelurahan yang menunjukkan obyek pajak SPPT PBB / SKPD PBB P2 termasuk obyek pajak yang dapat dibatalkan(_breakline)
7.	Lunas tagihan SPPT PBB yang diakui, untuk pengajuan pembatalan SPPT double anslag.(_breakline)

8.	SPPT asli yang akan di hapus (double unslaagh).(_breakline)

   
   
    `,
  },
    {
    label: "PENUNDAAN JATUH TEMPO SPPT PBB",
    content: `
    1.	Mengisi Formulir Penundaan Jatuh Tempo SPPT PBB (_breakline)
2.	Surat kuasa (jika di kuasakan) (_breakline)
3.	Fotocopy KTP Wajib Pajak dan KK (_breakline)
4.	Asli SPPT PBB tahun yang bersangkutan (_breakline)
5.	Buktilunas PBB tahun-tahun sebelumnya (_breakline)
6.	Dokumen yang menunjukkan bahwa karena sebab tertentu SPPT (Tidak/Belum/Terlambat disampaikan kepada Wajib Pajak)dibuktikan dengan surat keterangan dari kelurahan yang ditandatangani oleh lurah
(_breakline)

   
   
    `,
  },
    {
    label: "KEBERATAN SPPT PBB",
    content: `
  1.	Untuk 1 SPPT PBB   
  (_breakline)
2.	Secara tertulis dalam Bahasa Indonesia  
  (_breakline)
3.	Diajukan ke Badan Pendapatan Daerah Kota Bekasi  
  (_breakline)
4.	Asli SPPT PBB  
  (_breakline)
5.	Dikemukakan jumlah PBB terutang menurut Wajib Pajak  
  (_breakline)
6.	Maksimal 3 Bulan sejak diterima SPPT/SKPD PBB  
  (_breakline)
7.	Ditandatangani Wajib Pajak atau dengan surat kuasanya  
  (_breakline)
8.	Fotocopy Identitas Wajib Pajak KTP  
  (_breakline)
9.	Fotocopy Bukti Surat Tanah   
  (_breakline)
10.	Fotocopy Bukti Surat Bangunan/IMB dll  
  (_breakline)
11.	Lunas semua tunggakan PBB

  
  (_breakline)

   
   
    `,
  },
  {
    label: "RESTITUSI PBB",
    content: `
   1.	Mengisi Formulir Restitusi SPPT PBB  (_breakline)
2.	Surat kuasa (jika dikuasakan)  (_breakline)
3.	Fotocopy KTP Wajib Pajak dan KK  (_breakline)
4.	Untuk pengembalian kelebihan pembayaran atas permasalahan double unslaagh melampirkan SPPT PBB asli yang dinyatakan tidak benar dan fotocopy SPPT PBB yang dinyatakan benar.  (_breakline)
5.	Fotocopy buku rekening sesuai dengan KTP  (_breakline)
6.	Blanko surat keterangan transfer bermaterai 6000   (_breakline)
7.	Asli bukti bayar SPPT PBB yang dinyatakan tidak benar dan Fotocopy bukti bayar SPPT PBB yang dinyatakan benar.  (_breakline)
8.	Fotocopy NPWP wajibpajak.  (_breakline)
9.	Fotocopy Bukti Kepemilikan tanah (Kecuali Veteran)  (_breakline)


   
   
    `,
  },
];
//(_numbering_number) (_break_numbering)