import React, { useEffect, useState } from "react";
import YouTube from "react-youtube";
import BaseURL from "../../assets/utils/baseUrl";
import useDocumentTitle from "../../assets/utils/useDocumentTitle";
import { Breadcrumb, Footer, Header, Sidebar } from "../../components";
import "./style.scss";

function BeritaVideo() {
  useDocumentTitle("Berita Video - BAPENDA BEKASI");

  const [video, setVideo] = useState([]);
  const [videoPlay, setVideoPlay] = useState(null);
  const [videoPerPage, setVideoPerPage] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetch(BaseURL + "api/berita_video")
      .then((res) => res.json())
      .then((data) => {
        if (data.data.length > 0) {
          let page = [];
          let numberOfAllVideo = data.data.length;
          for (let i = 0; i < Math.ceil(data.data.length / 3); i++) {
            page.push([]);
          }
          let iterationIndex = 0;
          if (numberOfAllVideo >= 3) {
            for (let i = 0; i < page.length; i++) {
              if (numberOfAllVideo >= 3) {
                for (let j = 0; j < 3; j++) {
                  page[i].push(data.data[iterationIndex]);
                  iterationIndex += 1;
                }
                numberOfAllVideo -= 3;
              } else {
                for (let j = 0; j < numberOfAllVideo; j++) {
                  page[i].push(data.data[iterationIndex]);
                  iterationIndex += 1;
                }
              }
            }
          } else {
            for (let i = 0; i < numberOfAllVideo; i++) {
              page[0].push(data.data[i]);
              iterationIndex += 1;
            }
          }
          console.log(page);
          setVideo(page);
          setVideoPerPage(page[0]);
          setVideoPlay(page[0][0]);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  const nextPage = () => {
    setCurrentPage((prevState) => {
      setVideoPerPage(video[prevState - 1 + 1]);
      setVideoPlay(video[prevState - 1 + 1][0]);
      return prevState + 1;
    });
  };

  const prevPage = () => {
    setCurrentPage((prevState) => {
      setVideoPerPage(video[prevState - 1 - 1]);
      setVideoPlay(video[prevState - 1 - 1][0]);
      return prevState - 1;
    });
  };

  return (
    <div className="page">
      <Header active="publikasi" />

      {/* main */}
      <main className="main-sb">
        <section className="content">
          <Breadcrumb path="Publikasi / Berita Video" />
          <div className="page-title-wrapper">
            <div className="page-title">Berita Video</div>
          </div>

          {videoPlay ? (
            <div className="section-youtube-play">
              <YouTube
                videoId={videoPlay.id_video}
                className="youtube-iframe-bv"
              />
            </div>
          ) : (
            <div>Belum ada berita video</div>
          )}
          <div className="section-youtube-grid">
            {videoPerPage.map((item, index) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  className="youtube-item"
                  onClick={() => setVideoPlay(item)}
                >
                  <img
                    src={item.thumbnail}
                    style={{ width: "100%", objectFit: "cover" }}
                  ></img>
                  <div className="youtube-icon-wrapper">
                    <i class="fab fa-youtube youtube-icon"></i>
                  </div>
                </div>
                <div className="youtube-title">{item.judul}</div>
              </div>
            ))}
          </div>
          {video.length > 1 && (
            <div className="berita-video-pagination-wrapper">
              {currentPage > 1 && (
                <>
                  <div className="next-page-button" onClick={prevPage}>
                    Sebelumnya...
                  </div>
                </>
              )}
              <div style={{ width: 10 }} />
              {currentPage < video.length && (
                <div className="next-page-button" onClick={nextPage}>
                  Selanjutnya...
                </div>
              )}
            </div>
          )}
        </section>
        <Sidebar />
      </main>

      <Footer />
    </div>
  );
}

export default BeritaVideo;
