// (_bold) => tulisan tebal
// (_indent) => indentasi paragram 17px
// (_indent_2) => indentasi paragram 34px
// (_indent_3) => indentasi paragram 52px
// (_indent_4) => indentasi paragram 68px
// (_numbering_number) => penanda numbering/penomoran dengan angka. cukup sekali diawal bagian penemoran.
// (_numbering_alphabet) => penanda numbering/penomoran dengan huruf. cukup sekali diawal bagian penemoran.
// (_breakline) => baris baru
// (_break_numbering) => baris baru untuk numbering/penomoran

// note: untuk numbering hanya bisa diterapkan pada penomoran tidak bertingkat (1 level). contoh => Referensi dan Dasar Pengenaan

export const items = [
  
  {
    label: "A. Persyaratan Prinsip",
    content: `
	 
    
Persyaratan yang diperlukan oleh Wajib Pajak untuk mengajukan permohonanValidasiSSPD BPHTBOnline adalah sebagai berikut: (_breakline)
 
a)	Surat Kuasa (_breakline)
b)	Persetujuan surat permohonan Validasi SSPD BPHTB; (_breakline)
c)	Input formulir SSPD BPHTB; (_breakline)
d)	Upload persyaratan Validasi SSPD BPHTB sesuai dengan jenis transaksi; (_breakline)
e)	Menyetujui pernyataan wajib pajak terhadap kebenaran data upload. (_breakline)
(_breakline)



    `,
  },
    {
    label: "B. Persyaratan Teknis",
    content: `
	 

1)	Jenis Peralihan Jual Beli  (_breakline)
a)	Foto copy KTP Pembeli dan Penjual; (_breakline)
b)	Foto copy Bukti Kepemilikan Tanah; (_breakline)
c)	Bukti Harga Transaksi. (_breakline)

2)	Jenis Peralihan Waris  (_breakline)
a)	Foto copy Pewaris dan Ahli Waris; (_breakline)
b)	Surat Keterangan Waris; (_breakline)
c)	Foto copy Bukti Kepemilikan Tanah. (_breakline)

3)	Jenis Peralihan Hibah Wasiat  (_breakline)
a)	Foto copy KTP Penerima dan Pemberi Hibah; (_breakline)
b)	SK Kemenkumhan Pendaftaran Hibah Wasiat; (_breakline)
c)	Foto copy Bukti Kepemilikan Tanah; (_breakline)
d)	Surat Wasiat. (_breakline)

4)	Jenis Peralihan Hibah  (_breakline)
a)	Foto copy KTP Penerima dan Pemberi Hibah; (_breakline)
b)	Surat Pernyataan Persetujuan Pemberi Hibah; (_breakline)
c)	Foto copy Bukti Kepemilikan Tanah. (_breakline)

5)	Jenis Peralihan Tukar Menukar  (_breakline)
a)	Foto copy KTP Penerima dan Pemberi Hibah; (_breakline)
b)	Surat Pernyataan/Akta Tukar Menukar; (_breakline)
c)	Foto copy Bukti Kepemilikan Tanah; (_breakline)
d)	Bukti Nilai Pasar. (_breakline)

6)	Jenis Peralihan Pemasukan Dalam Perseroan atau Badan Hukum Lain  (_breakline)
a)	Foto copy KTP Para Pihak; (_breakline)
b)	SK Kemenkumhan Pengesahan Badan Hukum; (_breakline)
c)	Foto copy Bukti Kepemilikan Tanah; (_breakline)
d)	Dokumen Nilai Apraisal Tanah dan Bangunan. (_breakline)

7)	Jenis Peralihan Pemisahan Hak Yang Mengakibatkan Peralihan  (_breakline)
a)	Foto copy KTP Para Pihak; (_breakline)
b)	Foto copy Bukti Kepemilikan Tanah; (_breakline)
c)	Surat Pernyataan/Akta Pemisahan Hak. (_breakline)

8)	Jenis Peralihan Penunjukan Pembeli dalam lelang  (_breakline)
a)	Foto copy KTP Pemenang Lelang; (_breakline)
b)	Foto copy Bukti Kepemilikan Tanah; (_breakline)
c)	Kwitansi Pelunasan Harga Lelang; (_breakline)
d)	SK Penunjukan Pemenang Lelang. (_breakline)


9)	Jenis Peralihan Pelaksanaan Putusan Hakim yang mempunyai kekuatan hokum tetap (_breakline)
a)	Foto copy KTP Para Pihak; (_breakline)
b)	Foto copy Putusan Pengadilan Inkracht;(_breakline)
c)	Foto Copy Bukti Kepemilikan Tanah.(_breakline)

10)	Jenis Peralihan Penggabungan Usaha (_breakline)
a)	Foto copy KTP Para Pihak;(_breakline)
b)	SK Kemenkumhan Pengesahan Badan Hukum;(_breakline)
c)	Surat Pernyataan/Akta Penggabungan Usaha;(_breakline)
d)	Dokumen Nilai Apraisal Tanah dan Bangunan;(_breakline)
e)	Foto Copy Bukti Kepemilikan Tanah.(_breakline)

11)	Jenis Peralihan Peleburan Usaha (_breakline)
a)	Foto copy KTP Para Pihak;(_breakline)
b)	SK Kemenkumhan Pengesahan Badan Hukum;(_breakline)
c)	Surat Pernyataan/Akta Peleburan Usaha;(_breakline)
d)	Dokumen Nilai Apraisal Tanah dan Bangunan;(_breakline)
e)	Foto Copy Bukti Kepemilikan Tanah.(_breakline)

12)	Jenis Peralihan Pemekaran Usaha (_breakline)
a)	Foto copy KTP Para Pihak;(_breakline)
b)	SK Kemenkumham Pengesahan Badan Hukum;(_breakline)
c)	Surat Pernyataan/Akta Peleburan Usaha;(_breakline)
d)	Dokumen Nilai Apraisal Tanah dan Bangunan;(_breakline)
e)	Foto Copy Bukti Kepemilikan Tanah.(_breakline)

13)	Jenis Peralihan Hadiah (_breakline)
a)	Foto copy KTP Para Pihak;(_breakline)
b)	Foto Copy Bukti Kepemilikan Tanah;(_breakline)
c)	Surat Penetapan Pemberian Hadiah;(_breakline)
d)	Dokumen Nilai Apraisal Tanah dan Bangunan.(_breakline)

14)	Jenis Peralihan Pemberian Hak Baru kaena Kelanjutan Pelepasan Hak (_breakline)
a)	Foto copy KTP Para Pihak;(_breakline)
b)	SK Pemberian Hak dari Kantor Pertanahan;(_breakline)
c)	Foto Copy Bukti Kepemilikan Tanah.(_breakline)

15)	Jenis Peralihan Pemberian Hak Baru karena Diluar Pelepasan Hak(_breakline)
a)	Foto copy KTP Para Pihak; (_breakline)
b)	SK Pemberian Hak dari Kantor Pertanahan;(_breakline)
c)	Foto Copy Bukti Kepemilikan Tanah.(_breakline)
(_breakline)

    `,
  },
  /*
   {
    label: "Objek Pajak",
    content: `
    Dalam Pasal 2 UU BPHTB, yang menjadi objek BPHTB adalah perolehan hak atas tanah dan atau bangunan. Perolehan hak atas tanah dan atau bangunan tersebut meliputi :(_breakline)
    
    1. Pemindahan Hak, karena:(_breakline)
    (_indent)a. Jual Beli;(_breakline)
    (_indent)b. Tukar Menukar;(_breakline)
    (_indent)c. Hibah;(_breakline)
    (_indent)d. Hibah Wasiat;(_breakline)
    (_indent)e. Waris;(_breakline)
    (_indent)f. Pemasukan dalam Perseroan/Badan Hukum lainnya;(_breakline)
    (_indent)g. Pemisahan Hak yang mengakibatkan peralihan;(_breakline)
    (_indent)h. Penunjukan pembeli dalam Lelang;(_breakline)
    (_indent)i. Pelaksanaan putusan Hakim yang mempunyai kekuatan Hukum Tetap;(_breakline)
    (_indent)j. Penggabungan Usaha;(_breakline)
    (_indent)k. Peleburan Usaha;(_breakline)
    (_indent)l. Pemekaran Usaha; dan(_breakline)
    (_indent)m. Hadiah.(_breakline)
    
    2. Pemberian Hak Baru karena :(_breakline)
    (_indent)a. Kelanjutan Pelepasan Hak; dan(_breakline)
    (_indent)b. Diluar Pelepasan Hak.(_breakline)
    Sedangkan jenis-jenis hak atas tanah yang perolehan haknya dikenakan BPHTB
    sebagaimana diatur dalam Pasal 2 ayat (3) UU BPHTB meliputi :(_breakline)
    (_indent)a. Hak Milik;(_breakline)
    (_indent)b. Hak Guna Usaha;(_breakline)
    (_indent)c. Hak Guna Bangunan;(_breakline)
    (_indent)d. Hak Pakai;(_breakline)
    (_indent)e. Hak Milik atas satuan Rumah Susun; dan(_breakline)
    (_indent)f. Hak Pengelolaan.(_breakline)
    Berdasarkan ketentuan Pasal 3 ayat (1) terdapat beberapa objek pajak yang tidak dikenakan BPHTB yaitu :(_breakline)
    (_indent)a. Objek yang diperoleh perwakilan diplomatik, konsulat berdasar azas perlakuan timbal balik;(_breakline)
    (_indent)b. Objek yang diperoleh negara untuk penyelenggaraan pemerintahan dan atau untuk pelaksanaan pembangunan guna kepentingan umum;(_breakline)
    (_indent)c. Objek yang diperoleh Badan/Perwakilan organisasi internasional yang ditetapkan dengan Keputusan Menteri Keuangan dengan syarat tidak menjalankan usaha/kegiatan lain diluar fungsi dan tugasnya;(_breakline)
    (_indent)d. Objek yang diperoleh orang pribadi/Badan karena KONVERSI HAK atau karena perbuatan Hukum lain dengan tidak adanya perubahan nama;(_breakline)
    (_indent)e. Objek yang diperoleh orang pribadi/Badan karena WAKAF; dan(_breakline)
    (_indent)f. Objek yang diperoleh orang pribadi/Badan karena kepentingan IBADAH.(_breakline)
    `,
  },
  {
    label: "Subjek Pajak",
    content: `
    Yang menjadi subjek BPHTB adalah orang pribadi atau badan yang memperoleh hak atas Tanah dan atau Bangunan.(_breakline)
    `,
  },
  {
    label: "Wajib Pajak",
    content: `
    Subjek pajak BPHTB sesuai dengan ketentuan tersebut diatas menjadi wajib pajak BPHTB apabila dikenakan kewajiban membayar pajak.
    `,
  },
  {
    label: "Tarif Pajak",
    content: `
    Pasal 5 UU BPHTB menyatakan bahwa tarif BPHTB merupakan tarif tunggal sebesar 5 %. Penentuan tarif tunggal ini dimaksudkan untuk kesederhanaan dan kemudahan perhitungan.
    `,
  },
  {
    label: "Dasar Pengenaan",
    content: `
    Yang menjadi dasar pengenaan BPHTB adalah Nilai Perolehan Objek Pajak atau
    disingkat NPOP sesuai ketentuan Pasal 6 UU BPHTB.
    Berdasarkan jenis perolehan haknya, NPOP tersebut adalah sebagai berikut :(_breakline)
    
    (_numbering_number)
    Jual Beli = Harga Transaksi(_break_numbering)
    Tukar Menukar = Nilai Pasar(_break_numbering)
    Hibah = Nilai Pasar(_break_numbering)
    Hibah Wasiat = Nilai Pasar(_break_numbering)
    Waris = Nilai Pasar(_break_numbering)
    Pemasukan dalam Perseroan / Badan Hukum lainnya = Nilai Pasar(_break_numbering)
    Pemisahan Hak = Nilai Pasar(_break_numbering)
    Peralihan Hak karena Putusan Hakim = Nilai Pasar(_break_numbering)
    Pemberian Hak Baru = Nilai Pasar(_break_numbering)
    Penggabungan Usaha = Nilai Pasar(_break_numbering)
    Peleburan Usaha = Nilai Pasar(_break_numbering)
    Pemekaran Usaha = Nilai Pasar(_break_numbering)
    Hadiah = Nilai Pasar(_break_numbering)
    Lelang = yang tercantum dalam Risalah Lelang
    (_breakline)

    Menurut ketentuan Pasal 6 ayat (3), bila NPOP tidak diketahui atau NPOP lebih
    rendah dari NJOP PBB maka yang menjadi dasar pengenaan adalah NJOP PBB dan
    apabila NJOP PBB belum ditetapkan maka sesuai dengan ketentuan Pasal 6 ayat (4)
    besarnya NJOP PBB ditetapkan oleh Menteri Keuangan.(_breakline)
    Selanjutnya di dalam Pasal 7, pemerintah menentukan suatu batas nilai perolehan
    tidak kena pajak yang disebut Nilai Perolehan Objek Pajak Tidak Kena Pajak
    (NPOPTKP). Ketentuan Pasal 7 ini dijabarkan lebih lanjut dengan Peraturan
    Pemerintah dan yang terakhir adalah Peraturan Pemerintah (PP) No. 113 Tahun 2000
    tentang Penentuan Besarnya NPOPTKP BPHTB.(_breakline)

    PP No. 113 Tahun 2000 ditindaklanjuti dengan Keputusan Menteri Keuangan (KMK)
    No. 516/KMK.04/2000 tanggal 14 Desember 2000 tentang Tata Cara Penentuan
    Besarnya NPOPTKP BPHTB, yang telah diubah beberapa kali dengan :(_breakline)

    (_numbering_number)
    Peraturan Menteri Keuangan (PMK) No. 86/PMK.03/2006 tentang Perubahan
    Atas Keputusan Menteri Keuangan Nomor 516/KMK.04/2000 Tentang Tata Cara
    Penentuan Besarnya NPOPTKP BPHTB;(_break_numbering)
    PMK No. 33/PMK.03/2008 Perubahan Kedua Atas Keputusan Menteri Keuangan
    Nomor 516/KMK.04/2000 Tentang Tata Cara Penentuan Besarnya NPOPTKP
    BPHTB;(_break_numbering)
    PMK No. 14/PMK.03/2009 Perubahan Ketiga Atas Keputusan Menteri Keuangan
    Nomor 516/KMK.04/2000 Tentang Tata Cara Penentuan Besarnya NPOPTKP
    BPHTB.
    (_breakline)

    Peraturan ini berisikan ketentuan sebagai berikut:(_breakline)

    (_numbering_alphabet)
    untuk perolehan hak karena waris, atau hibah wasiat yang diterima orang
    pribadi yang masih dalam hubungan keluarga sedarah dalam garis
    keturunan lurus satu derajat ke atas atau satu derajat ke bawah dengan
    pemberi hibah wasiat, termasuk suami/istri, ditetapkan paling banyak
    Rp300.000.000,00 (tiga ratus juta rupiah);(_break_numbering)
    untuk perolehan hak Rumah Sederhana Sehat (RSH) sebagaimana diatur
    dalam Peraturan Menteri Negara Perumahan Rakyat Nomor
    03/PERMEN/M/2007 tentang Pengadaan Perumahan dan Permukiman
    Dengan Dukungan Fasilitas Subsidi Perumahan Melalui KPR Bersubsidi
    sebagaimana telah diubah dengan Peraturan Menteri Negara Perumahan
    Rakyat Nomor 07/PERMEN/M/2008, dan Rumah Susun Sederhana
    sebagaimana diatur dalam Peraturan Menteri Negara Perumahan Rakyat
    Nomor 7/PERMEN/M/2007 tentang Pengadaan Perumahan dan Permukiman
    Dengan Dukungan Fasilitas Subsidi Perumahan Melalui KPR Sarusun
    Bersubsidi, ditetapkan sebesar Rp55.000.000,00 (lima puluh lima juta
    rupiah);(_break_numbering)
    untuk perolehan hak baru melalui program pemerintah yang diterima pelaku
    usaha kecil atau mikro dalam rangka Program Peningkatan Sertifikasi Tanah
    untuk Memperkuat Penjaminan Kredit bagi Usaha Mikro dan Kecil,
    ditetapkan sebesar Rp10.000.000,00 (sepuluh juta rupiah);(_break_numbering)
    untuk perolehan hak selain perolehan hak sebagaimana dimaksud pada
    huruf a, huruf b, dan huruf c, ditetapkan paling banyak Rp60.000.000,00
    (enam puluh juta rupiah);(_break_numbering)
    dalam hal Nilai Perolehan Objek Pajak Tidak Kena Pajak yang ditetapkan
    sebagaimana dimaksud pada huruf d lebih besar daripada Nilai Perolehan
    Objek Pajak Tidak Kena Pajak yang ditetapkan sebagaimana dimaksud pada
    huruf b, maka Nilai Perolehan Objek Pajak Tidak Kena Pajak untuk perolehan
    hak sebagaimana dimaksud pada huruf b ditetapkan sama dengan Nilai
    Perolehan Objek Pajak Tidak Kena Pajak sebagaimana ditetapkan pada huruf
    d;(_break_numbering)
    dalam hal Nilai Perolehan Objek Pajak Tidak Kena Pajak yang ditetapkan
    sebagaimana dimaksud pada huruf d lebih besar daripada Nilai Perolehan
    Objek Pajak Tidak Kena Pajak yang ditetapkan sebagaimana dimaksud pada
    huruf c, maka Nilai Perolehan Objek Pajak Tidak Kena Pajak untuk perolehan
    hak sebagaimana dimaksud pada huruf c ditetapkan sama dengan Nilai
    Perolehan Objek Pajak Tidak Kena Pajak sebagaimana ditetapkan pada huruf
    d.
    (_breakline)

    Besarnya NPOPTKP ditetapkan secara regional, maksudnya adalah NPOPTKP
    tersebut ditetapkan per daerah tingkat II (Kabupaten/Kota) dengan
    mempertimbangkan usulan dari Kepala Daerah yang bersangkutan.(_breakline)
    
    (_bold)Cara Menghitung BPHTB(_breakline)
    Untuk menghitung besarnya Nilai Perolehan Objek Pajak Kena Pajak (NPOPKP)
    adalah dengan cara mengurangkan NPOP dengan NPOPTKP. Dengan demikian untuk
    menghitung besarnya BPHTB terutang adalah :(_breakline)
    BPHTB terutang = Tarif x NPOPKP atau 5% x (NPOP – NPOPTKP)(_breakline)
    Contoh :(_breakline)

    (_numbering_number)
    Pada tanggal 1 Pebruari 2003, Bapak Sudirjo membeli sebidang tanah yang
    terletak di Kabupaten Tangerang dengan Nilai Perolehan Objek Pajak (NPOP)
    sebesar Rp50.000.000,- Apabila NPOPTKP ditetapkan untuk Kabupaten Tangerang
    sebesar Rp60.000.000,- maka BPHTB yang menjadi kewajiban Bapak Sumarno
    tsb adalah :
    5% x (50.000.000 - 60.000.000) = Nihil
    atau dengan kata lain Bapak Sudirjo tidak terutang BPHTB.(_break_numbering)
    Pada tanggal 1 Maret 2003 , Bapak Rahmat membeli sebuah rumah seluas 200
    M2 yang berada diatas sebidang tanah hak milik seluas 500 M2 di Kota Bogor
    dengan harga perolehan sebesar Rp500.000.000,- Berdasarkan data SPPT PBB
    atas objek tersebut ternyata NJOPnya sebesar Rp.600.000.000,- (tanah dan
    bangunan). Bila NPOPTKP ditentukan sebesar Rp50.000.000,- maka kewajiban
    BPHTB yang harus dipenuhi oleh Bapak Rahmat tersebut adalah :
    5% x (600.000.000 - 50.000.000) = Rp27.500.000,-
    (_breakline)
    `,
  },
  {
    label: "Pengenaan BPHTB Karena Waris, Hibah Wasiat dan Pemberian Hak",
    content: `
    1. Pengenaan BPHTB Karena Waris dan Hibah Wasiat(_breakline)
    Sesuai dengan bunyi Pasal 3 ayat (2) UU BPHTB, pengenaan BPHTB karena waris
    dan hibah wasiat diatur dengan peraturan pemerintah, yaitu PP No. 111 Tahun
    2000 tentang Pengenaan Bea Perolehan Hak Atas Tanah dan Bangunan Karena
    Waris dan Hibah Wasiat, yang mengatur hal-hal sebagai berikut:(_breakline)
    (_indent)a. BPHTB terutang karena waris dan hibah wasiat sebesar : 50 % dari yang
    seharusnya terutang;(_breakline)
    (_indent)b. Saat terutang pajak adalah sejak tanggal yang bersangkutan mendaftarkan
    peralihan haknya ke Kantor Pertanahan;(_breakline)
    (_indent)c. Dasar pengenaan (NPOP) adalah nilai pasar pada saat pendaftaran hak;(_breakline)

    (_indent)d. Apabila NPOP lebih kecil dari NJOP PBB maka yang menjadi dasar pengenaan
    adalah NJOP PBB;(_breakline)
    (_indent)e. Nilai Perolehan Objek Pajak Tidak Kena Pajak (NPOTKP) terdiri dari 2 jenis :(_breakline)
    (_indent_2)1) Maksimum Rp300 juta terhadap waris dan juga terhadap hibah wasiat
    yang diterima oleh orang pribadi yang masih dalam hubungan keluarga
    sedarah dalam garis keturunan lurus satu derajat ke atas dan satu derajat
    ke bawah dengan pemberi hibah wasiat termasuk suami/istri; dan(_breakline)
    (_indent_2)2) Maksimum Rp60 juta terhadap penerima hibah wasiat selain dari yang
    diatas. Contoh :(_breakline)
    (_indent_3)1. Seorang anak menerima warisan dari orang tuanya sebidang tanah dan
    bangunan dengan nilai pasar pada waktu pendaftaran hak sebesar Rp250
    juta. Terhadap tanah dan bangunan tersebut telah dikenakan PBB dengan
    NJOP sebesar Rp425 juta. Apabila NPOPTKP karena waris untuk daerah
    tersebut ditentukan sebesar Rp250 juta maka BPHTB yang terutang adalah
    sebesar :(_breakline)
    (_indent_3)50% x 5% x (Rp425 juta – Rp250 juta) = Rp4.375.000,-(_breakline)
    (_indent_3)2. Seorang cucu menerima hibah wasiat dari kakeknya sebidang tanah seluas
    300 M2 dengan nilai pasar pada waktu pendaftaran hak sebesar Rp325 juta.
    Terhadap tanah tersebut telah diterbitkan SPPT PBB pada tahun pendaftaran
    hak dengan NJOP sebesar Rp250 juta. Apabila NPOPTKP pada daerah tersebut
    ditentukan sebesar Rp50 juta maka BPHTB yang terutang adalah sebesar :(_breakline)
    (_indent_3)50% x 5% x (Rp325 juta – Rp50 juta ) = Rp6.875.000,-(_breakline)
    (_indent_3)3. Sebuah Yayasan Yatim Piatu “ Al-Attin” menerima hibah wasiat dari seorang
    dermawan sebidang tanah seluas 1.000 M2 dengan nilai pasar pada waktu
    pendaftaran hak sebesar Rp700 juta. Apabila NPOPTKP pada daerah tersebut
    ditentukan sebesar Rp60 juta maka BPHTB terutang yang harus dibayar oleh
    Yayasan tersebut adalah sebesar :(_breakline)
    (_indent_3)50% x 5% x ( Rp700 juta – Rp60 juta) = Rp16.000.000,-(_breakline)
    2. Pengenaan BPHTB Karena Pemberian Hak Pengelolaan
    Sesuai dengan Pasal 3 ayat (2) UU BPHTB, pengenaan BPHTB karena pemberian
    hak pengelolaan diatur dengan peraturan pemerintah, yaitu PP No. 112 Tahun
    2000 tentang Pengenaan Bea Perolehan Hak Atas Tanah dan Bangunan karena
    Pemberian Hak Pengelolaan, yang mengatur hal-hal sebagai berikut :(_breakline)
    (_indent_2)a. Yang dimaksud dengan Hak Pengelolaan adalah hak menguasai dari Negara
    atas tanah yang kewenangan pelaksanaannya sebagian dilimpahkan kepada
    pemegang haknya untuk merencanakan peruntukan dan penggunaan tanah,
    menggunakan tanah untuk keperluan tugasnya, menyerahkan bagian-bagian
    tanah tersebut kepada pihak ketiga dan atau bekerjasama dengan pihak
    ketiga.(_breakline)
    (_indent_2)b. Besarnya BPHTB karena Hak Pengelolaan adalah :(_breakline)
    (_indent_3)1. 0% dari BPHTB yang seharusnya terutang bila penerima Hak Pengelolaan
    adalah Departemen, Lembaga Pemerintah Non Departemen, Pemerintah
    Propinsi/Kabupaten/Kota, Lembaga Pemerintah Lain dan Perum Perumnas;(_breakline)

    (_indent_3)2. 50% dari BPHTB yang seharusnya terutang untuk selain yang diatas;(_breakline)
    (_indent_3)3. Saat terutang Pajak yaitu sejak tanggal ditandatangani dan diterbitkannya
    keputusan pemberian Hak Pengelolaan;(_breakline)
    (_indent_3)4. Dasar pengenaan ( NPOP) adalah Nilai Pasar;(_breakline)
    (_indent_3)5. Apabila Nilai Pasar lebih kecil dari NJOP PBB maka yang dipakai adalah
    NJOPPBB. Contoh :(_breakline)
    (_indent_4)1. Perum Perumnas menerima Hak Pengelolaan dari Pemerintah sebidang tanah
    seluas seluas 5 Ha dengan nilai pasar pada waktu penerbitan hak sebesar Rp3
    milyar. Apabila NPOPTKP pada daerah tersebut ditetapkan sebesar Rp60 juta
    maka besarnya BPHTB yang harus diabayar oleh Perum Perumnas tersebut
    adalah :(_breakline)
    (_indent_4)0% x 5% x (Rp3 milyar – Rp60 juta) = 0 ( nihil ).(_breakline)
    (_indent_4)2. Sebuah perusahaan negara milik daerah ( BUMD Perpakiran ) menerima hak
    pengelolaan dari pemerintah sebidang tanah dan sebuah gedung untuk parkir
    dengan nilai pasar pada waktu penerbitan hak sebesar Rp1 milyar. Terhadap
    tanah dan bangunan tersebut telah diterbitkan SPPT PBB dengan NJOP
    sebesar Rp1,25 milyar. Apabila NPOPTKP atas daerah tersebut ditetapkan
    sebesar Rp50 juta maka besarnya BPHTB yang harus dibayar oleh BUMD
    Perpakiran tersebut adalah sebesar :(_breakline)
    (_indent_4)50% x 5% x (Rp1,25 milyar – Rp50 juta) = Rp30 juta(_breakline)
    `,
  },
  {
    label: "Saat Terutang Pajak",
    content: `
    Ketentuan Pasal 9 ayat (1) UU BPHTB memuat tentang saat terutang pajak atas
    perolehan hak atas tanah dan atau bangunan sebagai berikut :(_breakline)
    1. Jual Beli : Sejak tanggal dibuat & ditandatanganinya Akta;(_breakline)
    2. Tukar Menukar : Sejak tanggal dibuat & ditandatanganinya Akta;(_breakline)
    3. Hibah : Sejak tanggal dibuat & ditandatanganinya Akta;(_breakline)
    4. Waris : Sejak tanggal yang bersangkutan mendaftarkan peralihan haknya ke
    Kantor Pertanahan;(_breakline)
    5. Pemasukan dalam Perseroan : Sejak tanggal dibuat & ditandatanganinya Akta;(_breakline)
    6. Pemisahan Hak : Sejak tanggal dibuat & ditandatanganinya Akta;(_breakline)
    7. Lelang : Sejak tanggal penunjukan pemenang Lelang;(_breakline)
    8. Putusan Hakim : Sejak tanggal putusan Pengadilan yang mempunyai kekuatan
    hukum tetap;(_breakline)
    9. Hibah Wasiat : Sejak tanggal yang bersangkutan mendaftarkan peralihan
    Haknya ke Kantor Pertanahan;(_breakline)
    10. Pemberian Hak Baru : Sejak tanggal ditandatangani dan diterbitkannya Surat
    Keputusan Pemberian Hak;(_breakline)
    11. Penggabungan Usaha : Sejak tanggal dibuat & ditandatanganinya Akta;(_breakline)
    12. Peleburan Usaha : Sejak tanggal dibuat & ditandatanganinya Akta;(_breakline)
    13. Pemekaran Usaha : Sejak tanggal dibuat & ditandatanganinya Akta;(_breakline)
    14. Hadiah : Sejak tanggal dibuat & ditandatanganinya Akta.(_breakline)
    Pajak terutang harus dilunasi pada saat terjadinya perolehan hak, dengan kata lain
    saat terutang pajak BPHTB adalah merupakan saat untuk wajib membayar pajak.(_breakline)

    Tempat pajak terutang adalah di wilayah Kabupaten, Kota, atau Propinsi yang
    meliputi letak tanah dan atau bangunan.(_breakline)
    Ketentuan tata cara pembayaran BPHTB tercantum dalam Pasal 10 UU BPHTB yang
    dijabarkan lebih lanjut dengan Keputusan Menteri Keuangan Nomor
    517/KMK.04/2000 sebagaimana telah diubah dengan Peraturan Menteri Keuangan
    Nomor 168/PMK.03/2007 tentang Perubahan Atas Keputusan Menteri Keuangan
    Nomor 517/KMK.04/2000 tentang Penunjukan Tempat dan Tata Cara Pembayaran
    Bea Perolehan Hak Atas Tanah dan Bangunan, yang kemudian ditindak lanjuti
    dengan Keputusan Dirjen Pajak Nomor 269/PJ/2001 tentang Petunjuk Pelaksanaan
    Pembayaran Bea Perolehan Hak Atas Tanah Dan Bangunan Dan Bentuk Serta Fungsi
    Surat Setoran Bea Perolehan Hak Atas Tanah Dan Bangunan (SSB) dan Surat Edaran
    Dirjen Pajak Nomor 09/PJ.6/2001 yang intinya adalah sebagai berikut :(_breakline)
    1. Pembayaran tidak mendasarkan kepada adanya Surat Ketetapan Pajak;(_breakline)
    2. Dibayar dengan menggunakan Surat Setoran Bea ( SSB ) ke Kas Negara melalui
    Bank/Kantor Pos atau Tempat Pembayaran lain yg ditunjuk;(_breakline)
    3. SSB juga berfungsi sebagai SPOP dan sekaligus digunakan untuk melaporkan
    data perolehan hak atas tanah dan atau bangunan.(_breakline)
    Kewajiban Bayar adalah pada saat :(_breakline)
    1. Dibuat & ditandatanganinya Akta;(_breakline)
    2. Pendaftaran Hak untuk Waris & Hibah Wasiat;(_breakline)
    3. Ditunjuknya pemenang Lelang;(_breakline)
    4. Ditandatanganinya SK Pemberian Hak dalam hal pemberian Hak Baru;(_breakline)
    5. Putusan Pengadilan yang mempunyai kekuatan hukum tetap.(_breakline)
    `,
  },
  {
    label: "Tata Cara Penetapan Dan Penagihan",
    content: `
    Tata cara penetapan BPHTB diatur didalam Pasal 11 dan Pasal 12 sebagai berikut :(_breakline)
    (_indent)1. Dalam jangka waktu 5 tahun sejak pajak terutang, berdasarkan hasil
    pemeriksaan terdapat kurang bayar, Direktorat Jenderal Pajak, dalam hal ini
    Kepala Kantor Pelayanan PBB/KPP Pratama menerbitkan Surat Ketetapan BPHTB
    Kurang Bayar (SKBKB) ditambah denda 2% per bulan maksimum untuk jangka
    waktu 24 bulan ( 48% ).(_breakline)
    (_indent)2. Setelah terbit SKBKB, terdapat data baru lagi sehingga Pajak terutang
    bertambah, maka Kepala Kantor Pelayanan PBB/KPP Pratama menerbitkan Surat
    Ketetapan BPHTB Kurang Bayar Tambahan (SKBKBT) ditambah sanksi
    administrasi sebesar 100% dari jumlah kenaikan, kecuali wajib pajak melapor
    sebelum ada pemeriksaan.(_breakline)
    Tata cara penagihan BPHTB diatur dalam Pasal 13, Pasal 14 dan Pasal 15 UU BPHTB
    maka apabila :(_breakline)
    (_indent)1. Pajak terutang tidak/kurang bayar;(_breakline)
    (_indent)2. Dari pemeriksaan, SSB kurang bayar; dan(_breakline)
    (_indent)3. WP kena sanksi administrasi berupa denda/bunga,
    maka Direktorat Jenderal Pajak menerbitkan Surat Tagihan BPHTB (STB) ditambah
    sanksi bunga 2% per bulan maksimum 24 bulan.(_breakline)
    Surat Tagihan BPHTB setara dengan Surat Ketetapan Pajak (SKP) SKBKB, SKBKBT, STB, SK Pembetulan / SK Pengurangan / SK Keberatan / SK Banding merupakan Dasar Penagihan Pajak. Pajak terutang berdasar SURAT-SURAT tersebut diatas harus dilunasi paling lambat 1(satu) bulan sejak diterima oleh wajib pajak, lewat batas waktu dapat ditagih dengan SURAT PAKSA.(_breakline)
    `,
  },
  {
    label: "Pemberian Fasilitas BPHTB melalui pengurangan BPHTB",
    content: `
    Dalam APBN Tahun 2009 diatur dalam Pasal 3 ayat (2) huruf d UU No. 41 Tahun 2008 tentang APBN Tahun 2009, sebagaimana telah diubah dengan UU No. 26 Tahun 2009 tentang Perubahan Atas APBN Tahun 2009, pemerintah memberikan subsidi terhadap BPHTB dalam bentuk pajak yang ditanggung pemerintah (DTP) sebesar 500 miliar rupiah. Pelaksanaan dari DTP BPHTB tersebut dituangkan dalam Peraturan Menteri Keuangan.
    (_breakline)
    Pemberian subsidi dimaksud diberikan dalam bentuk pemberian fasilitas BPHTB bagi pembeli Rumah Sederhana Sehat (RSH) dan Rumah Susun Sederhana (RSS) yang selama ini telah dilakukan oleh pemerintah.
    (_breakline)
    Para pengembang atau pengusaha realestat yang melakukan penjualan tanah dan/atau bangunan dengan sistem bersih (netto) atau harga jual sudah termasuk pajak-pajak antara lain BPHTB, maka besarnya BPHTB terutang yang dibebankan kepada pembeli adalah sebesar 5% x (NPOP – NPOPTKP). Sebagaimana telah dijelaskan sebelumnya, NPOP dalam hal jual beli adalah harga transaksi dan apabila tidak diketahui atau lebih rendah daripada NJOP yang digunakan dalam pengenaan PBB pada tahun terjadinya perolehan, dasar pengenaan pajak yang dipakai adalah NJOP PBB. Adapun besarnya NPOPTKP secara regional untuk perolehan hak secara umum ditetapkan paling banyak Rp60.000.000,00.
    (_breakline)
    Namun demikian untuk perolehan hak yang memenuhi kriteria Rumah Sederhana Sehat (RSH) dan Rumah Susun Sederhana yang perolehannya dibiayai melalui KPR bersubsidi mendapat fasilitas BPHTB berupa NPOPTKP sebesar Rp 55.000.000,-. Dalam hal NPOPTKP yang ditetapkan secara umum lebih besar daripada Rp 55.000.000,- maka NPOPTKP untuk perolehan hak Rumah Sederhana Sehat (RSH) dan Rumah Susun Sederhana yang perolehannya dibiayai melalui KPR bersubsidi ditetapkan sama dengan NPOPTKP secara umum.
    (_breakline)
    Disamping itu atas permohonan Wajib Pajak, dalam hal Wajib Pajak orang pribadi yang memperoleh hak atas tanah dan atau bangunan yang memenuhi kriteria Rumah Sederhana (RS) dan Rumah Susun Sederhana serta Rumah Sangat Sederhana (RSS) yang diperoleh langsung dari pengembang dan dibiayai melalui KPR tidak bersubsidi dapat diberikan fasilitas BPHTB berupa pengurangan BPHTB sebesar 25% dari pajak yang terutang.
    (_breakline)
    Adapun dasar pengurangan BPHTB diatur dalam Pasal 20 UU BPHTB yang diatur lebih lanjut dalam KMK No. 561/KMK.03/2004 tanggal 25 Nopember 2004 tentang Pemberian Pengurangan BPHTB. Keputusan Menteri Keuangan ini kemudian diubah dan terakhir dengan Peraturan Menteri Keuangan Nomor 91/PMK.03/2006 tanggal 13 Oktober 2006 tentang Perubahan Kedua atas KMK No. 561/KMK.04/2004 tentang Pemberian Pengurangan BPHTB, yang dapat dirinci sebagai berikut :
    (_breakline)
    1. Dalam hal kondisi tertentu Wajib Pajak (WP) yang ada hubungannya dengan
    Objek Pajak :(_breakline)
    (_indent)a. WP pribadi memperoleh hak baru melalui program Pemerintah di bidang
    Pertanahan dan tidak mempunyai kemampuan ekonomis mendapat
    pengurangan sebesar 75%;(_breakline)
    (_indent)b. WP Badan memperoleh hak baru selain Hak Pengelolaan dan telah
    menguasai tanah dan atau bangunan secara fisik lebih dari 20 tahun
    mendapat pengurangan sebesar 50%;(_breakline)
    (_indent)c. WP pribadi yang memperoleh hak atas tanah dan atau bangunan RS dan
    RSS langsung dari pengembang dan membayar secara angsuran mendapat
    pengurangan sebesar 25%;(_breakline)
    (_indent)d. WP pribadi menerima hibah dari keluarga sedarah satu derajad keatas dan
    kebawah mendapat pengurangan sebesar 50%.(_breakline)
    2. Kondisi Wajib Pajak yang ada hubungannya dengan sebab-sebab tertentu :(_breakline)
    (_indent)a. WP memperoleh hak dari hasil pembelian uang ganti rugi pemerintah yang
    nilai ganti ruginya dibawah NJOP mendapat pengurangan sebesar 50%;(_breakline)
    (_indent)b. WP memperoleh hak sebagai penggantian dari tanah yang dibebaskan
    pemerintah untuk kepentingan umum yang memerlukan persyaratan
    khusus, mendapat pengurangan sebesar 50%;(_breakline)
    (_indent)c. WP Badan terkena dampak krisis ekonomi dan moneter yang berdampak
    luas pada kehidupan perekonomian nasional sehingga WP harus melakukan
    restrukturisasi usaha dan atau utang usaha sesuai kebijaksanaan
    pemerintah, mendapat pengurangan sebesar 75%;(_breakline)
    (_indent)d. WP Bank Mandiri yang memperoleh hak atas tanah yang berasal dari BBD,
    BDN, Bapindo dan Bank Exim dalam rangka merger, mendapat pengurangan
    sebesar 100%;(_breakline)
    (_indent)e. WP Badan melakukan Merger atau Konsolidasi dengan atau tanpa terlebih
    dahulu mengadakan likuidasi dan telah memperoleh keputusan persetujuan
    pengunaan Nilai Buku dlm rangka penggabungan atau peleburan usaha
    tersebut dari Dirjen Pajak, mendapat pengurangan sebesar 50%;(_breakline)
    (_indent)f. WP memperoleh hak atas tanah dan atau bangunan yang tidak berfungsi
    lagi karena bencana alam dlsb yang terjadi dalam waktu 3 bulan setelah
    penandatanganan Akta, mendapat pengurangan sebesar 50%;(_breakline)
    (_indent)g. WP pribadi (Veteran, PNS, TNI, Polri, pensiunan, purnawirawan,
    janda/dudanya) yang memproleh hak atas tanah dan atau bangunan rumah
    dinas pemerintah, mendapat pengurangan 75%;(_breakline)
    (_indent)h. WP Badan Korpri yang memperoleh hak atas tanah dan atau bangunan
    dalam rangka pengadaaan perumahan bagi anggota Korpri/PNS, mendapat
    pengurangan sebesar 100%;(_breakline)
    (_indent)i. WP Badan anak perusahaan dari perusahaan asuransi dan reasuransi yang
    memperoleh hak atas tanah dan atau bangunan yang berasal dari
    peusahaan induknya selaku pemegang saham tunggal sebagai kelanjutan
    dari pelaksanaan KepMenKeu tentang Kesehatan Keuangan Perusahaan
    Asuransi dan Perusahaan Reasuransi, mendapat pengurangan sebesar 50%;(_breakline)
    (_indent)j. WP yang domisilinya termasuk dalam wilayah program rehabilitasi dan
    rekonstruksi yang memperoleh hak atas tanah dan atau bangunan melalui
    program pemerintah di bidang pertanahan atau WP yang objek pajaknya
    terkena bencana lam gempa bumi dan gelombang tsunami di Propinsi NAD
    dan Kepulauan Nias, Sumatera Utara, mendapat pengurangan sebesar
    100%;(_breakline)
    (_indent)k. WP yang objek pajaknya terkena bencana alam gempa bumi di Propinsi DIY
    dan sebagian Propinsi Jawa Tengah yang perolehan haknya atau saat
    terhutangnya terjadi 3(tiga) bulan sebelum terjadinya bencana, diberi
    pengurangan sebesar 100%;(_breakline)
    (_indent)l. WP yang objek pajaknya terkena bencana alam gempa bumi dan tsunami di
    pesisir Pantai Selatan Pulau Jawa yang perolehan haknya atau saat
    terhutangnya terjadi 3 (tiga) bulan sebelum terjadinya bencana, diberi
    pengurangan sebesar 100%.(_breakline)
    3. Tanah dan bangunan untuk kepentingan sosial/pendidikan yang semata-mata
    tidak mencari keuntungan mendapat pengurangan sebesar 50%.(_breakline)
    4. Tanah dan atau bangunan di Propinsi NAD yang selama masa rehabilitasi
    berlangsung digunakan untuk kepentingan sosial/pendidikan yang semata-mata
    tidak untuk mencari keuntungan mendapat pengurangan sebesar 100%.(_breakline)
    Tata Cara Permohonan Pengurangan(_breakline)
    1. Permohonan diajukan oleh WP kepada Kepala KPPBB/KPP Pratama / Kakanwil
    DJP / Dir.Jen.Pajak dalam bahasa Indonesia dengan lampiran :(_breakline)
    (_indent)a. Fotokopi Surat Setoran Bea (SSB);(_breakline)
    (_indent)b. Fotokopi Akta/Risalah Lelang/Kep.Pemberian Hak Baru/Putusan Hakim;
    (_indent)c. Fotokopi identitas;(_breakline)
    (_indent)d. Surat Keterangan Lurah/Kepala Desa;(_breakline)
    (_indent)e. Fotokopi persetujuan Merger dari Dirjen Pajak.(_breakline)
    2. Permohonan dalam waktu 3 (tiga) bulan sejak tanggal pembayaran;(_breakline)
    3. Khusus untuk MERGER, permohonan diajukan sebelum Akta ditandatangani oleh
    Notaris/PPAT;(_breakline)
    4. Atas permohonan kemudian dilakukan Pemeriksaan Sederhana dan dituangkan
    dalam Berita Acara; dan(_breakline)
    5. Permohonan yang tidak memenuhi persyaratan tidak dianggap sebagai surat
    permohonan dan tidak dipertimbangkan.(_breakline)

    (_bold)Keputusan Pengurangan(_breakline)
    1. Keputusan oleh Kepala KPPBB/KPP Pratama dalam waktu 3 (tiga) bulan sejak
    terima permohonan dari Wajib Pajak, lebih dari 3 (tiga) bulan dianggap diterima.
    Keputusan oleh Kakanwil DJP dalam waktu 4 (empat) bulan sejak diterima
    pemohonan dari WP, lebih dari 4 bulan dianggap diterima, dan keputusan oleh
    Direktur Jenderal Pajak dalam waktu 6 (enam) bulan, lebih dari 6 bulan
    dianggap dikabulkan;(_breakline)
    2. Bentuk Keputusan : mengabulkan seluruhnya/sebagian atau menolak;(_breakline)
    3. Wewenang Keputusan :(_breakline)
    (_indent)a. Ketetapan sampai dengan 2,5 M oleh Kepala Kantor PBB/ KPP Pratama;(_breakline)
    (_indent)b. Ketetapan diatas 2,5 M sampai dengan 5 M oleh KAKANWIL DJP;(_breakline)
    (_indent)c. Lebih dari 5 M, dampak krisis, merger dan Bank Mandiri oleh Direktur
    Jenderal Pajak.(_breakline)

    (_bold)Pengurangan Yang Dihitung Sendiri Oleh WP(_breakline)
    Terhadap WP yang memenuhi syarat dapat menghitung sendiri besar pengurangan
    sebelum pembayaran BPHTB. Dalam Surat Setoran Bea diberi tanda “pengurangan
    dihitung sendiri” dan jumlah setoran setelah pengurangan. Dalam hal ini WP tetap
    mengajukan permohonan pengurangan sesuai dengan syarat-syarat yang telah
    ditentukan. Bila permohonannya ditolak/dikabulkan namun BPHTB masih kurang
    bayar maka terhadap WP tersebut dikenakan sanksi bunga 2% per bulan dari
    kekurangan bayar tersebut, maksimum 24 bulan. Terhadap BPHTB kurang bayar
    (SKBKB) tidak dapat diajukan pengurangan kembali.(_breakline)
    `,
  },
  {
    label: "Restitusi Dan Imbalan Bunga Serta Pembagian Hasil Penerimaan BPHTB",
    content: `
    (_bold)Restitusi dan Imbalan Bunga(_breakline)
    Restitusi atau pengembalian kelebihan pembayaran BPHTB diatur dalam Pasal 21
    dan Pasal 22 yang dapat dirinci sebagai berikut :(_breakline)

    1. Sebab-sebab Restitusi :(_breakline)
    (_indent)a. Pajak dibayar > pajak terutang yang disebabkan oleh :(_breakline)
    (_indent_2)- Permohonan pengurangan dikabulkan;(_breakline)
    (_indent_2)- Permohonan keberatan dikabulkan;(_breakline)
    (_indent_2)- Permohonan banding dikabulkan;(_breakline)
    (_indent_2)- Perobahan peraturan.(_breakline)
    (_indent)b. Pajak dibayar tidak seharusnya terutang.(_breakline)

    2. Tata Cara Pengajuan Restitusi dan Imbalan Bunga(_breakline)
    (_indent)a. Permohonan restitusi diajukan oleh WP dalam bahasa Indonesia dengan
    alasan dan dilampiri :(_breakline)
    (_indent_2)1) Asli Surat Setoran Bea ( SSB );(_breakline)
    (_indent_2)2) Fotokopi SK Keberatan / Banding / Pengurangan;(_breakline)
    (_indent_2)3) Fotokopi Akta / Risalah Lelang / Keputusan Hak Baru / Putusan Hakim;(_breakline)
    (_indent_2)4) Fotokopi identitas Wajib Pajak.(_breakline)
    (_indent)b. Yang tidak memenuhi persyaratan tidak dianggap sebagai surat permohonan
    dan tidak dipertimbangkan;(_breakline)
    (_indent)c. Berdasarkan pemeriksaan atas permohonan, KPPBB/KPP Pratama
    menerbitkan :(_breakline)
    (_indent_2)1) SKBLB apabila jumlah pajak yang telah dibayar oleh WP ternyata lebih
    besar dari jumlah pajak yang terutang;(_breakline)
    (_indent_2)2) SKBN apabila jumlah pajak yang dibayar oleh WP sama besarnya
    dengan jumlah pajak yang terutang;(_breakline)
    (_indent_2)3) SKBKB apabila jumlah pajak yang telah dibayar oleh WP lebih kecil dari
    jumlah pajak terutang.(_breakline)(_breakline)
    (_indent)d. Keputusan dalam waktu 12 bulan sejak terima permohonan apabila waktu
    12 bulan tersebut terlampaui, maka permohonan tersebut dianggap diterima
    dan paling lambat 1 bulan setelah 12 bulan harus terbit SKBLB dan apabila
    penerbitan SKBLB lewat waktu maka WP mendapat bunga 2% per bulan
    dihitung sejak lewat waktu sampai dengan terbit SKBLB;(_breakline)
    (_indent)e. Berdasarkan SKBLB harus diterbitkan Surat Keputusan Pengembalian
    Kelebihan Pembayaran BPHTB (SKPKPB) yang dikirim ke : WP, BO, KPKN
    dan Kanwil DJP;(_breakline)
    (_indent)f. Dalam waktu 2 bulan setelah SKBLB harus diterbitkan Surat Perintah
    Membayar Kelebihan Pembayaran BPHTB ( SPMKPB ), lewat dari waktu yang
    ditentukan tersebut WP dapat bunga 2% per bulan; dan(_breakline)
    (_indent)g. Atas imbalan bunga diterbitkan Surat Ketetapan Imbalan Bunga ( SKIB )
    dan Surat Perintah Membayar Imbalan Bunga ( SPMIB ).(_breakline)
    `,
  },
  {
    label: "Pembagian Hasil Penerimaan BPHTB",
    content: `
    Pembagian hasil penerimaan BPHTB diatur dalam Pasal 23 UU BPHTB dan
    pelaksanaannya diatur lebih lanjut dengan Keputusan Menteri Keuangan No.
    519/KMK.04/2000 tanggal 14 Desember 2000 sebagai berikut :(_breakline)
    1. Pemerintah Pusat mendapat bagian sebesar 20% dari seluruh penerimaan
    BPHTB yang kemudian bagian Pemerintah Pusat ini dibagikan secara merata
    keseluruh daerah Kabupaten/Kota dan dilaksanakan dalam tiga tahap yaitu
    bulan April, bulan Agustus, dan bulan Nopember tahun anggaran berjalan;(_breakline)
    2. Pemerintah Daerah mendapat bagian sebesar 80% yang dibagi sebagai berikut :(_breakline)
    (_indent)a. 16% untuk Daerah Propinsi; dan(_breakline)
    (_indent)b. 64% untuk Daerah Kabupaten/Kota.(_breakline)
    Sesuai dengan Peraturan Menteri Keuangan No. 04/PMK.07/2008 tanggal 28 Januari
    2008 tentang Pelaksanaan dan Pertanggungjawaban Anggaran Transfer ke Daerah,
    atas transfer Dana Bagi Hasil BPHTB untuk daerah Direktur Jenderal Perimbangan
    Keuangan selaku Kuasa Pengguna Anggaran melimpahkan sebagian kewenangan
    perintah pemindahbukuan dari Rekening Kas Umum Negara ke Rekening Kas Umum
    Daerah kepada Kuasa Bendahara Umum Negara. Pelimpahan kewenangan ini
    dilakukan dengan menerbitkan Surat Perintah Menerbitkan Surat Kuasa Umum
    (SPMSKU). Berdasarkan SPMSKU ini maka Kuasa Bendahara Umum Negara
    menerbitkan Surat Kuasa Umum (SKU) kepada Bank Operasional III untuk
    melakukan pemindahbukuan Dana Bagi Hasil BPHTB dari Rekening Kas Umum
    Negara ke Rekening Kas Umum Daerah. Penyaluran Dana Bagi Hasil BPHTB ini
    berdasarkan realisasi penerimaan BPHTB tahun anggaran berjalan dan dilaksanakan
    secara mingguan.(_breakline)
    Dalam rangka penyaluran transfer ke daerah, setiap tahun anggaran selambatlambatnya pada minggu pertama bulan Desember sebelum tahun anggaran dimulai,
    pemerintah daerah wajib menyampaikan nomor rekening, nama rekening dan nama
    bank kepada Direktur Jenderal Perimbangan Keuangan yang dilampiri dengan: 1) asli
    rekening koran dari Rekening Kas Umum Daerah; dan 2) fotokopi keputusan kepala
    daerah mengenai penunjukan/penetapan pejabat Bendahara Umum Daerah/Kuasa
    Bendahara Umum Daerah yang disahkan oleh kepala daerah.(_breakline)
    `,
  },
  {
    label: "Referensi",
    content: `
    (_numbering_number)
    UU No. 5 Tahun 1960 tentang Peraturan Dasar Pokok-Pokok Agraria;(_break_numbering)
    UU No. 21 Tahun 1997 tentang Bea Perolehan Hak Atas Tanah dan Bangunan, sebagaimana telah diubah dengan UU No. 20 Tahun 2000 tentang Perubahan Atas Undang-Undang Nomor 21 Tahun 1997 tentang Bea Perolehan Hak Atas Tanah dan Bangunan;(_break_numbering)
    UU No. 41 Tahun 2008 tentang APBN Tahun 2009, sebagaimana telah diubah dengan UU No.
    26 Tahun 2009 tentang Perubahan Atas APBN Tahun 2009;(_break_numbering)
    PP No. 34 Tahun 1997 tentang Pelaporan atau Pemberitahuan Perolehan Hak Atas Tanah dan
    atau Bangunan;(_break_numbering)
    PP No. 35 Tahun 1997 tentang Pengenaan Bea Perolehan Hak Atas Tanah dan Bangunan
    Karena Hibah Wasiat;(_break_numbering)
    PP No. 36 Tahun 1997 tentang Pengenaan Bea Perolehan Hak Atas Tanah dan Bangunan
    Karena Pemberian Hak Pengelolaan;(_break_numbering)
    PP No. 111 Tahun 2000 tentang Pengenaan Bea Perolehan Hak Atas Tanah dan Bangunan
    Karena Waris dan Hibah Wasiat;(_break_numbering)
    PP No. 112 Tahun 2000 tentang Pengenaan Bea Perolehan Hak Atas Tanah dan Bangunan
    karena Pemberian Hak Pengelolaan;(_break_numbering)
    PP No. 113 Tahun 2000 tentang Penentuan Besarnya NPOPTKP BPHTB;(_break_numbering)
    Keputusan Menteri Keuangan No. 516/KMK.04/2000 tentang Tata Cara Penentuan Besarnya
    NPOPTKP BPHTB, sebagaimana telah beberapa kali diubah terakhir dengan Peraturan Menteri
    Keuangan No. 14/PMK.03/2009 Perubahan Ketiga Atas Keputusan Menteri Keuangan Nomor
    516/KMK.04/2000 Tentang Tata Cara Penentuan Besarnya NPOPTKP BPHTB;(_break_numbering)
    Keputusan Menteri Keuangan No. 517/KMK.04/2000 tentang Penunjukan Tempat dan Tata
    Cara Pembayaran Bea Perolehan Hak Atas Tanah dan Bangunan, sebagaimana telah diubah
    dengan Peraturan Menteri Keuangan No. 168/PMK.03/2007 tentang Perubahan Atas
    Keputusan Menteri Keuangan No. 517/KMK.04/2000 tentang Penunjukan Tempat dan Tata
    Cara Pembayaran Bea Perolehan Hak Atas Tanah dan Bangunan;(_break_numbering)
    Keputusan Menteri Keuangan No. 561/KMK.03/2004 tentang Pemberian Pengurangan Bea
    Perolehan Hak Atas Tanah dan Bangunan, sebagaimana telah diubah terakhir dengan
    Peraturan Menteri Keuangan No. 91/PMK.03/2006 tentang Perubahan Kedua atas KMK No.
    561/KMK.04/2004 Tentang Pemberian Pengurangan BPHTB;(_break_numbering)
    Peraturan Menteri Keuangan No. 04/PMK.07/2008 tentang Pelaksanaan dan
    Pertanggungjawaban Anggaran Transfer ke Daerah;(_break_numbering)
    Keputusan Menteri Keuangan No. 519/KMK.04/2000 tentang Pembagian Hasil Penerimaan
    BPHTB;(_break_numbering)
    http://www.pajak.go.id;(_break_numbering)
    http://organisasi.org (komunitas dan perpustakaan online);(_break_numbering)
    http://pelayanan-pajak.blogspot.com;(_break_numbering)
    “Pembebasan Pajak RSh Perlu Disesuaikan”; Bisnis Indonesia , 24 Maret 2010.
    `,
  },*/
];
