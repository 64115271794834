import React, { useEffect, useState } from "react";
import BaseURL from "../../../assets/utils/baseUrl";
import MUIDataTable from "mui-datatables";
import "./style.scss";
import { Button, Footer, HeaderAdmin } from "../../../components";
import { TextField } from "@material-ui/core";
import { Loader } from "../../../assets";

function KelolaBerita({ setToken }) {
  const [berita, setBerita] = useState(null);
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(true);
  const [popUp, setPopUp] = useState(false);
  const [success, setSuccess] = useState(true);
  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [alertJudul, setAlertJudul] = useState(false);
  const [alertKonten, setAlertKonten] = useState(false);
  const [alertThumbnail, setAlertThumbnail] = useState(false);
  const [formData, setFormData] = useState({
    judul: "",
    konten: "",
    thumbnail: null,
  });
  const [id, setId] = useState("");

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      await fetch(BaseURL + "api/berita")
        .then((res) => res.json())
        .then((data) => {
          setBerita(data.data);
          setLoading(false);
        });
    };
    getData();
  }, [load]);

  const columns = [
    {
      name: "judul",
      label: "Judul",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <div style={{ width: 200 }}>{value}</div>;
        },
      },
    },
    {
      name: "konten",
      label: "Artikel",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "tanggal_post",
      label: "Tanggal Post",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <div style={{ width: 100 }}>{value}</div>;
        },
      },
    },
    {
      name: "thumbnail",
      label: "Thumbnail",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <img src={BaseURL + "assets/images/berita/" + value} style={{ width: 150, objectFit: "contain" }}></img>;
        },
      },
    },
    {
      name: "opsi",
      label: "Opsi",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="tooltip btn-hapus-row" onClick={() => hapus(value)}>
                <i className="fas fa-trash-alt"></i>
                <span className="tooltiptext">Hapus</span>
              </div>
              <div
                className="tooltip btn-edit-row"
                onClick={() => {
                  setUpdateModal(true);
                  openUpdateModal(value);
                }}
              >
                <i className="fas fa-edit"></i>
                <span className="tooltiptext">Update</span>
              </div>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    sort: true,
    filter: false,
    download: false,
    print: false,
    selectableRows: false,
  };

  let tablesData = [];

  if (berita) {
    for (let i = 0; i < berita.length; i++) {
      tablesData.push({
        judul: berita[i].judul,
        konten: berita[i].konten,
        tanggal_post: berita[i].created_date,
        thumbnail: berita[i].thumbnail,
        opsi: berita[i].id_berita,
      });
    }
  }

  const paragrafBaru = () => {
    setFormData({
      ...formData,
      konten: formData.konten + "(_newline)",
    });
  };

  const tambah = () => {
    if (formData.judul == "") {
      setAlertJudul(true);
    } else {
      setAlertJudul(false);
    }

    if (formData.konten == "") {
      setAlertKonten(true);
    } else {
      setAlertKonten(false);
    }

    if (formData.thumbnail == null) {
      setAlertThumbnail(true);
    } else {
      setAlertThumbnail(false);
    }

    if (formData.judul !== "" && formData.konten !== "" && formData.thumbnail !== null) {
      const data = new FormData();
      let today = new Date();
      let date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
      data.append("request", "post");
      data.append("judul", formData.judul);
      data.append("konten", formData.konten);
      data.append("id_user", localStorage.getItem("id"));
      data.append("created_date", date);
      data.append("image", formData.thumbnail);

      try {
        fetch(BaseURL + "api/berita", {
          method: "POST",
          body: data,
        })
          .then((response) => response.json())
          .then((data) => {
            setFormData({
              judul: "",
              konten: "",
              thumbnail: null,
            });
            setLoad(!load);
            setModal(false);
            setUpdateModal(false);
            if (data.status) {
              setSuccess(true);
            } else {
              setSuccess(false);
            }
            setPopUp(true);
          })
          .catch((error) => console.log("Gagal: ", error));
      } catch (error) {
        alert("Terjadi kesalahan pada sistem");
        console.log(error);
      }
    }
  };

  const hapus = ($id) => {
    try {
      fetch(BaseURL + "api/berita/" + $id, {
        method: "DELETE",
      })
        .then((response) => response.json())
        .then((data) => {
          setLoad(!load);
          if (data.status) {
            setSuccess(true);
          } else {
            setSuccess(false);
          }
          setPopUp(true);
        })
        .catch((error) => console.log("Gagal: ", error));
    } catch (error) {
      alert("Terjadi kesalahan pada sistem");
      console.log(error);
    }
  };

  const openUpdateModal = ($id) => {
    const getDataUpdate = async () => {
      await fetch(BaseURL + "api/berita?id=" + $id)
        .then((res) => res.json())
        .then((data) => {
          setFormData({
            ...formData,
            judul: data.data[0].judul,
            konten: data.data[0].konten,
          });
          setId($id);
          setModal(true);
        });
    };
    getDataUpdate();
  };

  const update = () => {
    if (formData.judul == "") {
      setAlertJudul(true);
    } else {
      setAlertJudul(false);
    }

    if (formData.konten == "") {
      setAlertKonten(true);
    } else {
      setAlertKonten(false);
    }

    if (formData.judul !== "" && formData.konten !== "") {
      const data = new FormData();
      let today = new Date();
      let date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
      data.append("request", "put");
      data.append("id_berita", id);
      data.append("judul", formData.judul);
      data.append("konten", formData.konten);
      data.append("id_user", localStorage.getItem("id"));
      data.append("created_date", date);
      data.append("updated_date", date);
      data.append("image", formData.thumbnail);

      try {
        fetch(BaseURL + "api/berita", {
          method: "POST",
          body: data,
        })
          .then((response) => response.json())
          .then((data) => {
            setFormData({
              judul: "",
              konten: "",
              thumbnail: null,
            });
            setLoad(!load);
            setModal(false);
            setUpdateModal(false);
            if (data.status) {
              setSuccess(true);
            } else {
              setSuccess(false);
            }
            setPopUp(true);
          })
          .catch((error) => console.log("Gagal: ", error));
      } catch (error) {
        alert("Terjadi kesalahan pada sistem");
        console.log(error);
      }
    }
  };

  return (
    <div className="page">
      <HeaderAdmin title="Kelola Berita" setToken={(e) => setToken(e)} />
      <div className="main-admin">
        <div className="container-admin">
          <div style={{ height: 50 }} />
          <Button
            title="Tambah Post"
            type="primary"
            onClick={() => {
              setUpdateModal(false);
              setModal(true);
            }}
          />
          <div style={{ height: 10 }} />
          {loading ? (
            <div className="loader">
              <img src={Loader}></img>
            </div>
          ) : (
            <MUIDataTable title={"Daftar Berita"} data={tablesData} columns={columns} options={options} download={false} className="table" />
          )}
        </div>
      </div>
      <Footer />

      {/* modal */}
      <div className={modal ? "darker-bg-admin" : "darker-bg-admin hide"}>
        {/* form */}
        <div className="modal-admin">
          <div className="form-admin">
            <TextField id="judul" label="Judul" variant="outlined" style={{ marginTop: 10, marginBottom: 10 }} onChange={(e) => setFormData({ ...formData, judul: e.target.value })} value={formData.judul} />
            {alertJudul && <div className="danger-alert">Judul tidak boleh kosong</div>}

            <TextField id="konten" label="Artikel" variant="outlined" style={{ marginTop: 10, marginBottom: 10 }} multiline rows={10} onChange={(e) => setFormData({ ...formData, konten: e.target.value })} value={formData.konten} />
            {alertKonten && <div className="danger-alert">Artikel tidak boleh kosong</div>}

            <div className="paragraf-baru-wrapper">
              <Button title="Paragraf baru" type="secondary" onClick={() => paragrafBaru()} />
              <p className="paragraf-baru-text">(Klik tombol "paragraf baru" untuk memisahkan paragraf)</p>
            </div>

            <div style={{ height: 10 }}></div>

            <div className="btn-thumbnail">
              <Button title="Pilih Thumbnail" type="secondary" />
              <span className="file-name">{formData.thumbnail != null ? formData.thumbnail.name : updateModal ? "(Jika tidak ingin mengubah thumbnail, tetap kosongkan)" : "(anda belum memilih gambar/thumbnail)"}</span>
              <input className="invisible-input" type="file" title="Pilih Thumbnail" onChange={(e) => setFormData({ ...formData, thumbnail: e.target.files[0] })}></input>
            </div>
            {alertThumbnail && <div className="danger-alert">Thumbnail tidak boleh kosong</div>}
          </div>

          <div style={{ height: 30 }}></div>

          <div className="form-option">
            <Button
              title={updateModal ? "Update" : "Tambah"}
              type="primary"
              onClick={() => {
                if (updateModal) {
                  update();
                } else {
                  tambah();
                }
              }}
            />
            <div style={{ width: 10 }}></div>
            <Button
              title="Batal"
              type="danger"
              onClick={() => {
                setModal(false);
                setUpdateModal(false);
                setFormData({
                  judul: "",
                  konten: "",
                  thumbnail: null,
                });
                setAlertJudul(false);
                setAlertKonten(false);
                setAlertThumbnail(false);
              }}
            />
          </div>
        </div>
      </div>

      {/* message pop up */}
      <div className={popUp ? "darker-bg-admin" : "darker-bg-admin hide"}>
        <div className="pop-up-admin">
          <i className={success ? "fas fa-check-circle pop-up-logo" : "fas fa-times-circle pop-up-logo failed-pop-up"}></i>
          {success ? <p>Berhasil</p> : <p className={success ? "" : "failed-pop-up"}>Gagal</p>}
          <div style={{ height: 20 }}></div>
          <Button
            title="Tutup"
            type="primary"
            onClick={() => {
              setAlertJudul(false);
              setAlertKonten(false);
              setAlertThumbnail(false);
              setPopUp(false);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default KelolaBerita;
