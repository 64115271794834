import React, { useEffect, useState } from "react";
import BaseURL from "../../../assets/utils/baseUrl";
import MUIDataTable from "mui-datatables";
import { Button, Footer, HeaderAdmin } from "../../../components";
import { InputLabel, TextField, Select, MenuItem } from "@material-ui/core";
import { Loader } from "../../../assets";

function KelolaUser({ setToken }) {
  const [user, setUser] = useState(null);
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(true);
  const [popUp, setPopUp] = useState(false);
  const [success, setSuccess] = useState(true);
  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [alertNama, setAlertNama] = useState(false);
  const [alertUsername, setAlertUsername] = useState(false);
  const [alertPassword, setAlertPassword] = useState(false);
  const [formData, setFormData] = useState({
    nama: "",
    username: "",
    password: "",
    role: "",
  });
  const [id, setId] = useState("");

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      await fetch(BaseURL + "api/user")
        .then((res) => res.json())
        .then((data) => {
          setUser(data.data);
          setLoading(false);
        });
    };
    getData();
  }, [load]);

  const columns = [
    {
      name: "nama",
      label: "Nama",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <div style={{ width: 200 }}>{value}</div>;
        },
      },
    },
    {
      name: "username",
      label: "Username",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "token",
      label: "Token",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "role",
      label: "Role",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "tanggal_post",
      label: "Tanggal Post",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <div style={{ width: 100 }}>{value}</div>;
        },
      },
    },
    {
      name: "opsi",
      label: "Opsi",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <div style={{ display: "flex", flexDirection: "row" }}>
              {value !== "1" && (
                <div className="tooltip btn-hapus-row" onClick={() => hapus(value)}>
                  <i className="fas fa-trash-alt"></i>
                  <span className="tooltiptext">Hapus</span>
                </div>
              )}
              <div
                className="tooltip btn-edit-row"
                onClick={() => {
                  setUpdateModal(true);
                  openUpdateModal(value);
                }}
              >
                <i className="fas fa-edit"></i>
                <span className="tooltiptext">Update</span>
              </div>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    sort: true,
    filter: false,
    download: false,
    print: false,
    selectableRows: false,
  };

  let tablesData = [];

  if (user) {
    for (let i = 0; i < user.length; i++) {
      tablesData.push({
        nama: user[i].nama,
        username: user[i].username,
        token: user[i].token,
        role: user[i].nama_role,
        tanggal_post: user[i].created_date,
        opsi: user[i].id_user,
      });
    }
  }

  const tambah = () => {
    if (formData.nama == "") {
      setAlertNama(true);
    } else {
      setAlertNama(false);
    }

    if (formData.username == "") {
      setAlertUsername(true);
    } else {
      setAlertUsername(false);
    }

    if (formData.password == "") {
      setAlertPassword(true);
    } else {
      setAlertPassword(false);
    }

    if (formData.nama !== "" && formData.username !== "" && formData.password !== "") {
      const data = new FormData();
      let today = new Date();
      let date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
      data.append("request", "post");
      data.append("nama", formData.nama);
      data.append("username", formData.username);
      data.append("password", formData.password);
      data.append("created_date", date);

      try {
        fetch(BaseURL + "api/user", {
          method: "POST",
          body: data,
        })
          .then((response) => response.json())
          .then((data) => {
            setFormData({
              nama: "",
              username: "",
              password: "",
              role: "",
            });
            setLoad(!load);
            setModal(false);
            setUpdateModal(false);
            if (data.status) {
              setSuccess(true);
            } else {
              setSuccess(false);
            }
            setPopUp(true);
          })
          .catch((error) => console.log("Gagal: ", error));
      } catch (error) {
        alert("Terjadi kesalahan pada sistem");
        console.log(error);
      }
    }
  };

  const hapus = ($id) => {
    try {
      fetch(BaseURL + "api/user/" + $id, {
        method: "DELETE",
      })
        .then((response) => response.json())
        .then((data) => {
          setLoad(!load);
          if (data.status) {
            setSuccess(true);
          } else {
            setSuccess(false);
          }
          setPopUp(true);
        })
        .catch((error) => console.log("Gagal: ", error));
    } catch (error) {
      alert("Terjadi kesalahan pada sistem");
      console.log(error);
    }
  };

  const openUpdateModal = ($id) => {
    const getDataUpdate = async () => {
      await fetch(BaseURL + "api/user?id=" + $id)
        .then((res) => res.json())
        .then((data) => {
          setFormData({
            ...formData,
            nama: data.data[0].nama,
            username: data.data[0].username,
            password: data.data[0].password,
            role: data.data[0].id_role,
          });
          setId($id);
          setModal(true);
        });
    };
    getDataUpdate();
  };

  const update = () => {
    if (formData.nama == "") {
      setAlertNama(true);
    } else {
      setAlertNama(false);
    }

    if (formData.username == "") {
      setAlertUsername(true);
    } else {
      setAlertUsername(false);
    }

    if (formData.password == "") {
      setAlertPassword(true);
    } else {
      setAlertPassword(false);
    }

    if (formData.nama !== "" && formData.username !== "" && formData.password !== "") {
      const data = new FormData();
      let today = new Date();
      let date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
      data.append("request", "put");
      data.append("id_user", id);
      data.append("nama", formData.nama);
      data.append("username", formData.username);
      data.append("password", formData.password);
      data.append("id_role", formData.role);
      data.append("updated_date", date);

      try {
        fetch(BaseURL + "api/user", {
          method: "POST",
          body: data,
        })
          .then((response) => response.json())
          .then((data) => {
            setFormData({
              nama: "",
              username: "",
              password: "",
              role: "",
            });
            setLoad(!load);
            setModal(false);
            setUpdateModal(false);
            if (data.status) {
              setSuccess(true);
            } else {
              setSuccess(false);
            }
            setPopUp(true);
          })
          .catch((error) => console.log("Gagal: ", error));
      } catch (error) {
        alert("Terjadi kesalahan pada sistem");
        console.log(error);
      }
    }
  };

  return (
    <div className="page">
      <HeaderAdmin title="Kelola User" setToken={(e) => setToken(e)} />
      <div className="main-admin">
        <div className="container-admin">
          <div style={{ height: 50 }} />
          <Button
            title="Tambah Post"
            type="primary"
            onClick={() => {
              setUpdateModal(false);
              setModal(true);
            }}
          />
          <div style={{ height: 10 }} />
          {loading ? (
            <div className="loader">
              <img src={Loader}></img>
            </div>
          ) : (
            <MUIDataTable title={"Daftar User"} data={tablesData} columns={columns} options={options} download={false} className="table" />
          )}
        </div>
      </div>
      <Footer />

      {/* modal */}
      <div className={modal ? "darker-bg-admin" : "darker-bg-admin hide"}>
        {/* form */}
        <div className="modal-admin">
          <div className="form-admin">
            <TextField id="nama" label="Nama" variant="outlined" style={{ marginTop: 10, marginBottom: 10 }} onChange={(e) => setFormData({ ...formData, nama: e.target.value })} value={formData.nama} />
            {alertNama && <div className="danger-alert">Judul tidak boleh kosong</div>}

            <TextField id="username" label="Username" variant="outlined" style={{ marginTop: 10, marginBottom: 10 }} onChange={(e) => setFormData({ ...formData, username: e.target.value })} value={formData.username} />
            {alertUsername && <div className="danger-alert">Artikel tidak boleh kosong</div>}

            <TextField id="password" label="Password" variant="outlined" style={{ marginTop: 10, marginBottom: 10 }} onChange={(e) => setFormData({ ...formData, password: e.target.value })} value={formData.password} />
            {alertPassword && <div className="danger-alert">Password tidak boleh kosong</div>}

            {updateModal && (
              <>
                <InputLabel id="jabatan-select-label">Role</InputLabel>
                <Select labelId="jabatan-select-label" id="role" value={formData.role} onChange={(e) => setFormData({ ...formData, role: e.target.value })}>
                  <MenuItem value="1">Superadmin</MenuItem>
                  <MenuItem value="2">admin</MenuItem>
                </Select>
              </>
            )}
          </div>

          <div style={{ height: 30 }}></div>

          <div className="form-option">
            <Button
              title={updateModal ? "Update" : "Tambah"}
              type="primary"
              onClick={() => {
                if (updateModal) {
                  update();
                } else {
                  tambah();
                }
              }}
            />
            <div style={{ width: 10 }}></div>
            <Button
              title="Batal"
              type="danger"
              onClick={() => {
                setModal(false);
                setUpdateModal(false);
                setFormData({
                  nama: "",
                  username: "",
                  password: "",
                  role: "",
                });
                setAlertNama(false);
                setAlertUsername(false);
                setAlertPassword(false);
              }}
            />
          </div>
        </div>
      </div>

      {/* message pop up */}
      <div className={popUp ? "darker-bg-admin" : "darker-bg-admin hide"}>
        <div className="pop-up-admin">
          <i className={success ? "fas fa-check-circle pop-up-logo" : "fas fa-times-circle pop-up-logo failed-pop-up"}></i>
          {success ? <p>Berhasil</p> : <p className={success ? "" : "failed-pop-up"}>Gagal</p>}
          <div style={{ height: 20 }}></div>
          <Button
            title="Tutup"
            type="primary"
            onClick={() => {
              setAlertNama(false);
              setAlertUsername(false);
              setAlertPassword(false);
              setPopUp(false);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default KelolaUser;
