import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BaseURL from "../../../assets/utils/baseUrl";
import { Button } from "../../Atoms";
import "./style.scss";

function HeaderAdmin({ title, setToken }) {
  const [showSidebarAdmin, setShowSidebarAdmin] = useState(false);
  const [modal, setModal] = useState(false);
  const [alertPassword, setAlertPassword] = useState(false);
  const [alertPasswordC, setAlertPasswordC] = useState(false);
  const [newPassword, setNewPassword] = useState({
    id: "",
    password: "",
    passwordc: "",
    nama: "",
    username: "",
    id_role: "",
  });
  const [success, setSuccess] = useState(true);
  const [popUp, setPopUp] = useState(false);
  const [profileName, setProfileName] = useState("");

  useEffect(() => {
    const getData = async () => {
      await fetch(BaseURL + "api/user?id=" + localStorage.getItem("id"))
        .then((res) => res.json())
        .then((data) => {
          setProfileName(data.data[0].nama);
        });
    };
    getData();
  }, []);

  const logoutHandler = () => {
    setToken(false);
    localStorage.removeItem("username");
    localStorage.removeItem("id");
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("nama");
  };

  const openUpdateModal = () => {
    setShowSidebarAdmin(false);
    setModal(true);
    const getData = async () => {
      await fetch(BaseURL + "api/user?id=" + localStorage.getItem("id"))
        .then((res) => res.json())
        .then((data) => {
          console.log(data.data);
          setNewPassword({
            ...newPassword,
            id: data.data[0].id_user,
            nama: data.data[0].nama,
            username: data.data[0].username,
            id_role: data.data[0].id_role,
          });
          setModal(true);
        });
    };
    getData();
  };

  const update = () => {
    if (newPassword.password == "") {
      setAlertPassword(true);
    } else {
      setAlertPassword(false);
    }

    if (newPassword.passwordc == "") {
      setAlertPasswordC(true);
    } else {
      setAlertPasswordC(false);
    }

    if (newPassword.password != "" && newPassword.passwordc != "") {
      if (newPassword.password == newPassword.passwordc) {
        const data = new FormData();
        let today = new Date();
        let date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
        data.append("request", "put");
        data.append("id_user", newPassword.id);
        data.append("nama", newPassword.nama);
        data.append("username", newPassword.username);
        data.append("password", newPassword.password);
        data.append("id_role", newPassword.id_role);
        data.append("updated_date", date);

        try {
          fetch(BaseURL + "api/user", {
            method: "POST",
            body: data,
          })
            .then((response) => response.json())
            .then((data) => {
              console.log(data);
              setNewPassword({
                id: "",
                password: "",
                passwordc: "",
                nama: "",
                username: "",
                id_role: "",
              });
              setModal(false);
              if (data.status) {
                setSuccess(true);
              } else {
                setSuccess(false);
              }
              setPopUp(true);
            })
            .catch((error) => console.log("Gagal: ", error));
        } catch (error) {
          alert("Terjadi kesalahan pada sistem");
          console.log(error);
        }
      } else {
        setAlertPasswordC(true);
      }
    }
  };

  return (
    <div className="header-admin">
      <div className="header-wrapper-admin">
        <i
          className="fas fa-bars btn-menu-admin"
          onClick={() => {
            setShowSidebarAdmin(true);
          }}
        ></i>
        <div className="page-title-wrapper">
          <p className="page-title-admin">{title}</p>
        </div>
      </div>
      <div className={showSidebarAdmin ? "sidebar-admin show-sidebar-admin" : "sidebar-admin"} style={{ overflowY: "scroll" }}>
        <div className="btn-close-wrapper">
          <div>
            <p className="admin-name">{profileName}</p>
            <div className="admin-opsi-wrapper">
              <p className="admin-opsi" onClick={() => openUpdateModal()}>
                Edit Password
              </p>
              <p className="admin-opsi">|</p>
              <p className="admin-opsi" onClick={() => logoutHandler()}>
                Logout
              </p>
            </div>
          </div>
          <i
            className="fas fa-times btn-close-menu-admin"
            onClick={() => {
              setShowSidebarAdmin(false);
            }}
          ></i>
        </div>
        <Link to="/admin" className="menu-item-admin">
          <i className="fas fa-tachometer-alt menu-item-icon-admin"></i>
          <p>Dashboard</p>
        </Link>
        {localStorage.getItem("role") == "superadmin" && (
          <Link to="/admin/kelola-user" className="menu-item-admin">
            <i className="far fa-newspaper menu-item-icon-admin"></i>
            <p>User</p>
          </Link>
        )}
        <Link to="/admin/kelola-banner" className="menu-item-admin">
          <i className="far fa-newspaper menu-item-icon-admin"></i>
          <p>Banner</p>
        </Link>
        <Link to="/admin/kelola-pejabat" className="menu-item-admin">
          <i className="far fa-newspaper menu-item-icon-admin"></i>
          <p>Pejabat</p>
        </Link>
        <Link to="/admin/kelola-berita" className="menu-item-admin">
          <i className="far fa-newspaper menu-item-icon-admin"></i>
          <p>Berita</p>
        </Link>
		 <Link to="/admin/kelola-berita-video" className="menu-item-admin">
          <i className="far fa-newspaper menu-item-icon-admin"></i>
          <p>Berita Video</p>
        </Link>
        <Link to="/admin/kelola-agenda" className="menu-item-admin">
          <i className="far fa-newspaper menu-item-icon-admin"></i>
          <p>Agenda</p>
        </Link>
     
        <Link to="/admin/kelola-galeri" className="menu-item-admin">
          <i className="far fa-newspaper menu-item-icon-admin"></i>
          <p>Galeri</p>
        </Link>
		  <Link to="/admin/kelola-produk-hukum" className="menu-item-admin">
          <i className="far fa-newspaper menu-item-icon-admin"></i>
          <p>Produk Hukum</p>
        </Link>
		  <Link
          to="/admin/kelola-dokumen-perencanaan"
          className="menu-item-admin"
        >
          <i className="far fa-newspaper menu-item-icon-admin"></i>
          <p>Dokumen Perencanaan</p>
        </Link>
        <Link to="/admin/kelola-infografis" className="menu-item-admin">
          <i className="far fa-newspaper menu-item-icon-admin"></i>
          <p>Infografis</p>
        </Link>
	
        <Link to="/admin/kelola-perda" className="menu-item-admin">
          <i className="far fa-newspaper menu-item-icon-admin"></i>
          <p>Perda</p>
        </Link>
        <Link to="/admin/kelola-apbd" className="menu-item-admin">
          <i className="far fa-newspaper menu-item-icon-admin"></i>
          <p>APBD</p>
        </Link>
        <Link to="/admin/kelola-pad" className="menu-item-admin">
          <i className="far fa-newspaper menu-item-icon-admin"></i>
          <p>PAD</p>
        </Link>
        <Link to="/admin/kelola-pajak" className="menu-item-admin">
          <i className="far fa-newspaper menu-item-icon-admin"></i>
          <p>Pajak</p>
        </Link>
        <Link to="/admin/kelola-retribusi" className="menu-item-admin">
          <i className="far fa-newspaper menu-item-icon-admin"></i>
          <p>Retribusi</p>
        </Link>
      </div>

      {/* modal */}
      <div className={modal ? "darker-bg-admin" : "darker-bg-admin hide"}>
        {/* form */}
        <div className="modal-admin">
          <div className="form-admin">
            <TextField id="password" label="Password Baru" variant="outlined" style={{ marginTop: 10, marginBottom: 10 }} onChange={(e) => setNewPassword({ ...newPassword, password: e.target.value })} value={newPassword.password} />
            {alertPassword && <div className="danger-alert">tidak boleh kosong</div>}

            <TextField
              id="passwordc"
              label="Konfirmasi Password Baru"
              variant="outlined"
              style={{ marginTop: 10, marginBottom: 10 }}
              onChange={(e) => setNewPassword({ ...newPassword, passwordc: e.target.value })}
              value={newPassword.passwordc}
            />
            {alertPasswordC && <div className="danger-alert">Password baru tidak sesuai</div>}
          </div>

          <div style={{ height: 30 }}></div>

          <div className="form-option">
            <Button
              title="Update"
              type="primary"
              onClick={() => {
                update();
              }}
            />
            <div style={{ width: 10 }}></div>
            <Button
              title="Batal"
              type="danger"
              onClick={() => {
                setModal(false);
                setNewPassword({
                  id: "",
                  password: "",
                  passwordc: "",
                  nama: "",
                  username: "",
                  id_role: "",
                });
                setAlertPassword(false);
                setAlertPasswordC(false);
              }}
            />
          </div>
        </div>
      </div>

      {/* message pop up */}
      <div className={popUp ? "darker-bg-admin" : "darker-bg-admin hide"}>
        <div className="pop-up-admin">
          <i className={success ? "fas fa-check-circle pop-up-logo" : "fas fa-times-circle pop-up-logo failed-pop-up"}></i>
          {success ? <p>Berhasil</p> : <p className={success ? "" : "failed-pop-up"}>Gagal</p>}
          <div style={{ height: 20 }}></div>
          <Button
            title="Tutup"
            type="primary"
            onClick={() => {
              setAlertPassword(false);
              setAlertPasswordC(false);
              setPopUp(false);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default HeaderAdmin;
