import React, { useEffect, useState } from "react";
import { logo, logo2, logoBekasi } from "../../../assets";
import "./style.scss";
import { Link } from "react-router-dom";

window.addEventListener("scroll", () => {
  let header = document.querySelector("#header");
  if (header) {
    header.classList.toggle("header-sticky", window.scrollY > 100);
  }
});

const Header = ({ active }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <header id="header" className="">
      <div className="top-nav">
        <img src={logoBekasi} className="logo-image"></img>
        <div className="top-nav-title-wrapper">
          <p className="top-nav-title1">
            <span className="bapenda-text">BAPENDA</span> KOTA BEKASI
          </p>
          <p className="top-nav-title2">BADAN PENDAPATAN DAERAH KOTA BEKASI</p>
        </div>
      </div>
      <div className="nav-menu-mobile">
        <div className="button-menu-wrapper">
          <p>MENU</p>
          <i
            className="fas fa-bars btn-menu-mobile"
            onClick={() => {
              console.log(!showMobileMenu);
              setShowMobileMenu(!showMobileMenu);
            }}
          ></i>
        </div>
      </div>
      <nav className={showMobileMenu ? "show-mobile-menu" : ""}>
        <ul className="nav-menu">
          <li>
            <Link to="/" className={active == "beranda" ? "nav-link menu-active" : "nav-link"} id="beranda">
              Beranda
            </Link>
          </li>
          <li>
            <div className={active == "profil" ? "nav-link menu-active" : "nav-link"} id="profil">
              Profil
            </div>
            <ul className="sub-menu">
              <li>
                <Link to="/visimisi" className="nav-link" id="visimisi">
                  Visi dan Misi
                </Link>
              </li>
              <li>
                <Link to="/struktur-organisasi" className="nav-link" id="struktur-organisasi">
                  Struktur Organisasi
                </Link>
              </li>
              <li>
                <Link to="/pejabat" className="nav-link" id="struktur-organisasi">
                  Pejabat
                </Link>
              </li>
			   <li>
                <Link to="/StrukturPPID" className="nav-link" id="struktur-organisasi">
                  StrukturPPID
                </Link>
              </li>
            </ul>
          </li>
		    <li>
            <div className="nav-link" id="profil">
              Jenis Pajak
            </div>
            <ul className="sub-menu">
              <li>
     
			  <Link to="/JPbphtb" className={active == "JPbphtb" ? "nav-link menu-active" : "nav-link"} id="JPbphtb">
                 BPHTB
             </Link> 
              </li>
			  
			  
              <li>
              <Link to="/JPpbb" className="nav-link" id="JPpbb">
                  PBB
              </Link> 
              </li>
			  			 
			  
			   <li>
                <Link to="/JPrestoran" className="nav-link" id="JPrestoran">
                  Pajak Restoran
                </Link>
              </li>
			  <li>
               <Link to="/Photel" className="nav-link" id="Photel">
                  Pajak Hotel
                </Link>
              </li>
			  <li>
                 <Link to="/Phiburan" className="nav-link" id="Phiburan">
				Pajak Hiburan
                </Link>
              </li>
			  <li>
                 <Link to="/Preklame" className="nav-link" id="Preklame">
                  Pajak Reklame
                </Link>
              </li>
			  <li>
                <Link to="/Pairtanah" className="nav-link" id="Pairtanah">
                  Pajak Air Tanah
                </Link>
              </li>
			  <li>
               <Link to="/Pparkir" className="nav-link" id="Pparkir">
                  Pajak Parkir
                </Link>
              </li>
			  <li>
               <Link to="/Pppj" className="nav-link" id="Pppj">
                  Pajak PPJ
                </Link>
              </li>
			
            </ul>
          </li>
          <li>
            <div className="nav-link" id="profil">
              Aplikasi
            </div>
            <ul className="sub-menu">
              <li>
                <a href="http://ebphtb.bekasikota.go.id/" target="blank" className="nav-link" id="visimisi">
                  E-BPHTB
                </a>
              </li>
              <li>
                <a href="http://sipdah.bekasikota.go.id/" target="blank" className="nav-link" id="visimisi">
                  E-SPTPD
                </a>
              </li>
			  <li>
                <a href="http://e-pbb.bekasikota.go.id/cek-tunggakan.php" target="blank" className="nav-link" id="info tunggakan">
                  i-PBB
                </a>
              </li>
            </ul>
          </li>
          <li>
            <div className={active == "publikasi" ? "nav-link menu-active" : "nav-link"} id="publikasi">
              Publikasi
            </div>
            <ul className="sub-menu">
              <li>
                <Link to="/berita" className="nav-link" id="berita">
                  Berita
                </Link>
              </li>
			   <li>
                <Link to="/berita-video" className="nav-link" id="berita">
                  Berita Video
                </Link>
              </li>
              <li>
                <Link to="/agenda" className="nav-link" id="agenda">
                  Agenda
                </Link>
              </li>
            
              <li>
                <Link to="/galeri" className="nav-link" id="berita">
                  Galeri
                </Link>
              </li>
              <li>
                <Link to="/lokasi-pelayanan" className="nav-link" id="berita">
                  Lokasi Pelayanan
                </Link>
              </li>
              <li>
                <Link to="/ProdukHukum" className="nav-link" id="ProdukHukum">
                  Produk hukum
                </Link>
              </li>
			   <li>
                <Link
                  to="/dokumen-perencanaan"
                  className="nav-link"
                  id="dokumen-perencanaan"
                >
                  Dokumen Perencanaan
                </Link>
              </li>
            </ul>
          </li>
           <li>
            <Link to="/Laporanppid" className={active == "Laporanppid" ? "nav-link menu-active" : "nav-link"} id="Laporanppid">
              Laporan PPID
            </Link>
          </li>
       
          <li>
            <Link to="/kontak" className={active == "kontak" ? "nav-link menu-active" : "nav-link"} id="kontak">
              Kontak
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
/* <li>
                <Link to="/pengumuman" className="nav-link" id="pengumuman">
                  Pengumuman
                </Link>
              </li>*/