import React, { useState, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import { Breadcrumb, Footer, Header, Sidebar } from "../../components";
import useDocumentTitle from "../../assets/utils/useDocumentTitle";
import MUIDataTable from "mui-datatables";
import { UnitPelayanan } from "./UnitPelayanan";
import ChangeView from "./ChangeView";

const LokasiPelayanan = () => {
  useDocumentTitle("Lokasi Pelayanan - BAPENDA BEKASI");

  const [mapConfig, setMapConfig] = useState({
    center: [-6.244178048073945, 107.00631259611676],
    zoom: 14,
  });

  let tableData = [];

  for (let i = 0; i < UnitPelayanan.length; i++) {
    tableData.push({
      unit: {
        unit: UnitPelayanan[i].unit,
        koordinat: UnitPelayanan[i].koordinat,
      },
      alamat: UnitPelayanan[i].alamat,
      telp: UnitPelayanan[i].telp,
    });
  }

  const changeMapCenter = (koordinat) => {
    setMapConfig({
      ...mapConfig,
      center: [koordinat.lat, koordinat.lng],
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const columns = [
    {
      name: "unit",
      label: "Unit",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <div
              style={{ flex: 1, cursor: "pointer" }}
              onClick={() => changeMapCenter(value.koordinat)}
            >
              {value.unit}
            </div>
          );
        },
      },
    },
    {
      name: "alamat",
      label: "Alamat Unit",
      options: {
        filter: true,
        sort: true,
      },
      customBodyRender: (value) => {
        return (
          <div
            style={{ flex: 1, cursor: "pointer" }}
            // onClick={() => ChangeMapCenter(value.koordinat)}
          >
            {value}
          </div>
        );
      },
    },
    {
      name: "telp",
      label: "Telp/Fax",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const options = {
    sort: true,
    filter: false,
    download: false,
    print: false,
    selectableRows: false,
  };

  return (
    <div className="page">
      <Header active="publikasi" />
      <main className="main-sb">
        <section className="content">
          <Breadcrumb path="Publikasi / Lokasi Pelayanan" />

          <div className="page-title-wrapper">
            <div className="page-title">Lokasi Pelayanan</div>
          </div>

          <div style={{ height: 20 }} />

          <div style={{ width: "100%", height: 400, zIndex: 1 }}>
            <MapContainer
              center={mapConfig.center}
              zoom={mapConfig.zoom}
              scrollWheelZoom={false}
              style={{ width: "100%", height: 400 }}
            >
              <ChangeView center={mapConfig.center} zoom={mapConfig.zoom} />
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {UnitPelayanan.map((item, index) => (
                <Marker position={[item.koordinat.lat, item.koordinat.lng]}>
                  <Popup>{item.unit}</Popup>
                </Marker>
              ))}
            </MapContainer>
          </div>

          <div style={{ height: 20 }} />

          <MUIDataTable
            title={"Unit Pelayanan"}
            data={tableData}
            columns={columns}
            options={options}
            download={false}
            className="table"
          />
        </section>
        <Sidebar />
      </main>
      <Footer />
    </div>
  );
};

export default LokasiPelayanan;
