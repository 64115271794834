import React, { useEffect, useState } from "react";
import BaseURL from "../../../assets/utils/baseUrl";
import MUIDataTable from "mui-datatables";
import { Button, Footer, HeaderAdmin } from "../../../components";
import { TextField, Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
import { Loader } from "../../../assets";
import { Link } from "react-router-dom";

function KelolaPejabat({ setToken }) {
  const [pejabat, setPejabat] = useState([]);
  const [jabatan, setJabatan] = useState([]);
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(true);
  const [popUp, setPopUp] = useState(false);
  const [success, setSuccess] = useState(true);
  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [alertNamaPejabat, setAlertNamaPejabat] = useState(false);
  const [alertNip, setAlertNip] = useState(false);
  const [alertIdJabatan, setAlertIdJabatan] = useState(false);
  const [alertFoto, setAlertFoto] = useState(false);
  const [formData, setFormData] = useState({
    nama_pejabat: "",
    nip: "",
    id_jabatan: "",
    foto: null,
  });
  const [id, setId] = useState("");
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setLoading(true);
    const getDataPejabat = async () => {
      await fetch(BaseURL + "api/pejabat")
        .then((res) => res.json())
        .then((data) => {
          setPejabat(data.data);
          setLoading(false);
        });
    };
    const getDataJabatan = async () => {
      await fetch(BaseURL + "api/jabatan")
        .then((res) => res.json())
        .then((data) => {
          setJabatan(data.data);
          setLoading(false);
        });
    };
    getDataPejabat();
    getDataJabatan();
  }, [load]);

  useEffect(() => {
    let tablesData = [];

    if (pejabat.length > 0) {
      for (let i = 0; i < pejabat.length; i++) {
        tablesData.push({
          nama_pejabat: pejabat[i].nama_pejabat,
          nip: pejabat[i].nip,
          jabatan: pejabat[i].nama_jabatan,
          bidang: pejabat[i].nama_bidang,
          tanggal_post: pejabat[i].created_date,
          foto: pejabat[i].foto,
          opsi: pejabat[i].id_pejabat,
        });
      }
    }
    setTableData(tablesData);
  }, [pejabat]);

  const columns = [
    {
      name: "nama_pejabat",
      label: "Nama Pejabat",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <div style={{ width: 200 }}>{value}</div>;
        },
      },
    },
    {
      name: "nip",
      label: "NIP",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "jabatan",
      label: "Jabatan",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "bidang",
      label: "Bidang",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "tanggal_post",
      label: "Tanggal Post",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <div style={{ width: 100 }}>{value}</div>;
        },
      },
    },
    {
      name: "foto",
      label: "Foto",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <img src={BaseURL + "assets/images/pejabat/" + value} style={{ width: 150, objectFit: "contain" }}></img>;
        },
      },
    },
    {
      name: "opsi",
      label: "Opsi",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="tooltip btn-hapus-row" onClick={() => hapus(value)}>
                <i className="fas fa-trash-alt"></i>
                <span className="tooltiptext">Hapus</span>
              </div>
              <div
                className="tooltip btn-edit-row"
                onClick={() => {
                  setUpdateModal(true);
                  openUpdateModal(value);
                }}
              >
                <i className="fas fa-edit"></i>
                <span className="tooltiptext">Update</span>
              </div>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    sort: true,
    filter: false,
    download: false,
    print: false,
    selectableRows: false,
  };

  const tambah = () => {
    if (formData.nama_pejabat == "") {
      setAlertNamaPejabat(true);
    } else {
      setAlertNamaPejabat(false);
    }

    if (formData.nip == "") {
      setAlertNip(true);
    } else {
      setAlertNip(false);
    }

    if (formData.id_jabatan == "") {
      setAlertIdJabatan(true);
    } else {
      setAlertIdJabatan(false);
    }

    if (formData.nama_pejabat !== "" && formData.nip !== "" && formData.id_jabatan !== "") {
      const data = new FormData();
      let today = new Date();
      let date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
      data.append("request", "post");
      data.append("nama_pejabat", formData.nama_pejabat);
      data.append("nip", formData.nip);
      data.append("id_jabatan", formData.id_jabatan);
      data.append("id_user", localStorage.getItem("id"));
      data.append("created_date", date);
      data.append("image", formData.foto);

      try {
        fetch(BaseURL + "api/pejabat", {
          method: "POST",
          body: data,
        })
          .then((response) => response.json())
          .then((data) => {
            setFormData({
              nama_pejabat: "",
              nip: "",
              id_jabatan: "",
              foto: null,
            });
            setLoad(!load);
            setModal(false);
            setUpdateModal(false);
            if (data.status) {
              setSuccess(true);
            } else {
              setSuccess(false);
            }
            setPopUp(true);
          })
          .catch((error) => console.log("Gagal: ", error));
      } catch (error) {
        alert("Terjadi kesalahan pada sistem");
        console.log(error);
      }
    }
  };

  const hapus = ($id) => {
    try {
      fetch(BaseURL + "api/pejabat/" + $id, {
        method: "DELETE",
      })
        .then((response) => response.json())
        .then((data) => {
          setLoad(!load);
          if (data.status) {
            setSuccess(true);
          } else {
            setSuccess(false);
          }
          setPopUp(true);
        })
        .catch((error) => console.log("Gagal: ", error));
    } catch (error) {
      alert("Terjadi kesalahan pada sistem");
      console.log(error);
    }
  };

  const openUpdateModal = ($id) => {
    const getDataUpdate = async () => {
      await fetch(BaseURL + "api/pejabat?id=" + $id)
        .then((res) => res.json())
        .then((data) => {
          setFormData({
            ...formData,
            nama_pejabat: data.data[0].nama_pejabat,
            nip: data.data[0].nip,
            id_jabatan: data.data[0].id_jabatan,
          });
          setId($id);
          setModal(true);
        });
    };
    getDataUpdate();
  };

  const update = () => {
    if (formData.nama_pejabat == "") {
      setAlertNamaPejabat(true);
    } else {
      setAlertNamaPejabat(false);
    }

    if (formData.nip == "") {
      setAlertNip(true);
    } else {
      setAlertNip(false);
    }

    if (formData.id_jabatan == "") {
      setAlertIdJabatan(true);
    } else {
      setAlertIdJabatan(false);
    }

    if (formData.nama_pejabat !== "" && formData.nip !== "" && formData.id_jabatan !== "") {
      const data = new FormData();
      let today = new Date();
      let date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
      data.append("request", "put");
      data.append("id_pejabat", id);
      data.append("nama_pejabat", formData.nama_pejabat);
      data.append("nip", formData.nip);
      data.append("id_jabatan", formData.id_jabatan);
      data.append("id_user", localStorage.getItem("id"));
      data.append("created_date", date);
      data.append("updated_date", date);
      data.append("image", formData.foto);

      try {
        fetch(BaseURL + "api/pejabat", {
          method: "POST",
          body: data,
        })
          .then((response) => response.json())
          .then((data) => {
            setFormData({
              nama_pejabat: "",
              nip: "",
              id_jabatan: "",
              foto: null,
            });
            setLoad(!load);
            setModal(false);
            setUpdateModal(false);
            if (data.status) {
              setSuccess(true);
            } else {
              setSuccess(false);
            }
            setPopUp(true);
          })
          .catch((error) => console.log("Gagal: ", error));
      } catch (error) {
        alert("Terjadi kesalahan pada sistem");
        console.log(error);
      }
    }
  };

  return (
    <div className="page">
      <HeaderAdmin title="Kelola Pejabat" setToken={(e) => setToken(e)} />
      <div className="main-admin">
        <div className="container-admin">
          <div style={{ height: 50 }} />
          <div style={{ display: "flex" }}>
            <Button
              title="Tambah Post"
              type="primary"
              onClick={() => {
                setUpdateModal(false);
                setModal(true);
              }}
            />
            <div style={{ width: 10 }}></div>
            <Link to="/admin/kelola-jabatan">
              <Button title="Kelola Jabatan" type="primary" />
            </Link>
          </div>
          <div style={{ height: 10 }} />
          {loading ? (
            <div className="loader">
              <img src={Loader}></img>
            </div>
          ) : (
            <MUIDataTable title={"Daftar Pejabat"} data={tableData} columns={columns} options={options} download={false} className="table" />
          )}
        </div>
      </div>

      <Footer />

      {/* modal */}
      <div className={modal ? "darker-bg-admin" : "darker-bg-admin hide"}>
        {/* form */}
        <div className="modal-admin">
          <div className="form-admin">
            <TextField id="nama_pejabat" label="Nama Pejabat" variant="outlined" style={{ marginTop: 10, marginBottom: 10 }} onChange={(e) => setFormData({ ...formData, nama_pejabat: e.target.value })} value={formData.nama_pejabat} />
            {alertNamaPejabat && <div className="danger-alert">Nama Pejabat tidak boleh kosong</div>}

            <TextField id="nip" label="NIP" variant="outlined" style={{ marginTop: 10, marginBottom: 10 }} onChange={(e) => setFormData({ ...formData, nip: e.target.value })} value={formData.nip} />
            {alertNip && <div className="danger-alert">NIP tidak boleh kosong</div>}

            {/* <FormControl style={{ marginTop: 10, marginBottom: 10 }}> */}
            <InputLabel id="jabatan-select-label">Jabatan</InputLabel>
            <Select labelId="jabatan-select-label" id="jabatan" value={formData.id_jabatan} onChange={(e) => setFormData({ ...formData, id_jabatan: e.target.value })}>
              {jabatan.length > 0 &&
                jabatan.map((item, idx) => {
                  return (
                    <MenuItem value={item.id_jabatan} key={idx}>
                      {item.nama_jabatan} (<b>{item.nama_bidang}</b>)
                    </MenuItem>
                  );
                })}
            </Select>
            {alertIdJabatan && <div className="danger-alert">Jabatan tidak boleh kosong</div>}
            <span className="file-name">(Jika jabatan tidak tersedia, tambahkan di halaman Kelola Jabatan)</span>
            {/* </FormControl> */}

            <div style={{ height: 10 }}></div>

            <div className="btn-thumbnail">
              <Button title="Pilih Foto" type="secondary" />
              <span className="file-name">{formData.foto != null ? formData.foto.name : updateModal ? "(Jika tidak ingin mengubah foto, tetap kosongkan)" : "(anda belum memilih gambar/foto)"}</span>
              <input className="invisible-input" type="file" title="Pilih Foto" onChange={(e) => setFormData({ ...formData, foto: e.target.files[0] })}></input>
            </div>
          </div>

          <div style={{ height: 30 }}></div>

          <div className="form-option">
            <Button
              title={updateModal ? "Update" : "Tambah"}
              type="primary"
              onClick={() => {
                if (updateModal) {
                  update();
                } else {
                  tambah();
                }
              }}
            />
            <div style={{ width: 10 }}></div>
            <Button
              title="Batal"
              type="danger"
              onClick={() => {
                setModal(false);
                setUpdateModal(false);
                setFormData({
                  nama_pejabat: "",
                  nip: "",
                  id_jabatan: "",
                  foto: null,
                });
                setAlertNamaPejabat(false);
                setAlertNip(false);
                setAlertIdJabatan(false);
              }}
            />
          </div>
        </div>
      </div>

      {/* message pop up */}
      <div className={popUp ? "darker-bg-admin" : "darker-bg-admin hide"}>
        <div className="pop-up-admin">
          <i className={success ? "fas fa-check-circle pop-up-logo" : "fas fa-times-circle pop-up-logo failed-pop-up"}></i>
          {success ? <p>Berhasil</p> : <p className={success ? "" : "failed-pop-up"}>Gagal</p>}
          <div style={{ height: 20 }}></div>
          <Button
            title="Tutup"
            type="primary"
            onClick={() => {
              setAlertNamaPejabat(false);
              setAlertNip(false);
              setAlertIdJabatan(false);
              setPopUp(false);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default KelolaPejabat;
