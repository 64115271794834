import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

const Beranda = () => {
  return (
    <Link to="/" className="breadcrumb-link">
      Beranda
    </Link>
  );
};

const Berita = () => {
  return (
    <Link to="/berita" className="breadcrumb-link">
      Berita
    </Link>
  );
};

const Agenda = () => {
  return (
    <Link to="/agenda" className="breadcrumb-link">
      Agenda
    </Link>
  );
};

const Pengumuman = () => {
  return (
    <Link to="/pengumuman" className="breadcrumb-link">
      Pengumuman
    </Link>
  );
};
const ProdukHukum = () => {
  return (
    <Link to="/produk-hukum" className="breadcrumb-link">
      Produk Hukum
    </Link>
  );
};
const Infografis = () => {
  return (
    <Link to="/infografis" className="breadcrumb-link">
      Infografis
    </Link>
  );
};

function Breadcrumb({ path, parent }) {
  if (parent) {
    if (parent == "berita") {
      return (
        <div className="breadcrumb">
          <Beranda /> / Publikasi / <Berita /> / {path}
        </div>
      );
    } else if (parent == "agenda") {
      return (
        <div className="breadcrumb">
          <Beranda /> / Publikasi / <Agenda /> / {path}
        </div>
      );
    } else if (parent == "pengumuman") {
      return (
        <div className="breadcrumb">
          <Beranda /> / Publikasi / <Pengumuman /> / {path}
        </div>
      );
    }else if (parent == "produk-hukum") {
      return (
        <div className="breadcrumb">
          <Beranda /> / Publikasi / <ProdukHukum /> / {path}
        </div>
      );
    } else if (parent == "infografis") {
      return (
        <div className="breadcrumb">
          <Beranda /> / Publikasi / <Pengumuman /> / {path}
        </div>
      );
    } else {
      return (
        <div className="breadcrumb">
          <Beranda /> / {path}
        </div>
      );
    }
  } else {
    return (
      <div className="breadcrumb">
        <Beranda /> / {path}
      </div>
    );
  }
}

export default Breadcrumb;
