import React from "react";
import { StrukturOrganisasi1,Struktur } from "../../assets";
import useDocumentTitle from "../../assets/utils/useDocumentTitle";
import { Breadcrumb, Footer, Header, Sidebar } from "../../components";
import "./style.scss";

function StrukturOrg() {
  useDocumentTitle("Struktur Organisasi - BAPENDA BEKASI");
  return (
    <div className="page">
      <Header active="profil" />

      {/* main */}
      <main className="main-sb">
        <section className="content">
          <Breadcrumb path="Profil / Struktur Organisasi" />
          <div className="page-title-wrapper">
            <div className="page-title">Struktur Organisasi</div>
          </div>
          <div className="so-image-wrapper">
         
			<img src={Struktur} className="so-image"></img>
          </div>
        </section>
        <Sidebar />
      </main>

      <Footer />
    </div>
  );
}

export default StrukturOrg;
