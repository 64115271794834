import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import useDocumentTitle from "../../assets/utils/useDocumentTitle";
import { Breadcrumb, Footer, Header, Sidebar } from "../../components";
import BaseURL from "../../assets/utils/baseUrl";
import { Link } from "react-router-dom";

const ProdukHukumJenis = (props) => {
  useDocumentTitle("Produk Hukum - BAPENDA BEKASI");

  const [produkHukum, setProdukHukum] = useState([]);

  let kategori = props.match.params.kategori.replace(/-/g, " ");

  let idKategori = 0;
  if (kategori === "peraturan walikota") {
    idKategori = 1;
  } else if (kategori === "peraturan daerah") {
    idKategori = 2;
  } else if (kategori === "surat keputusan kepala badan") {
    idKategori = 3;
  } else {
    idKategori = 4;
  }

  useEffect(() => {
    fetch(BaseURL + "api/produk_hukum?kategori=" + idKategori)
      .then((res) => res.json())
      .then((data) => {
        if (data.data.length > 0) {
          let produkHukumTemp = [];
          for (let i = 0; i < data.data.length; i++) {
            produkHukumTemp.push({
              dokumen: data.data[i].nama,
              deskripsi: data.data[i].deskripsi,
              file: data.data[i].id_ph,
            });
          }
          setProdukHukum(produkHukumTemp);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  const columns = [
    {
      name: "dokumen",
      label: "Dokumen",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "deskripsi",
      label: "Deskripsi",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "file",
      label: "File",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Link
              to={"/produk-hukum/" + props.match.params.kategori + "/" + value}
              style={{ width: 100, textDecoration: "underline" }}
            >
              File
            </Link>
          );
        },
      },
    },
  ];

  const options = {
    sort: true,
    filter: false,
    download: false,
    print: false,
    selectableRows: false,
  };

  return (
    <div className="page">
      <Header active="publikasi" />
      <main className="main-sb">
        <section className="content">
          <Breadcrumb path={kategori} parent="produk-hukum" />
          <div className="page-title-wrapper">
            <div className="page-title">{kategori}</div>
          </div>

          <MUIDataTable
            title={"Dokumen"}
            data={produkHukum}
            columns={columns}
            options={options}
            download={false}
			className="table"
          />
        </section>
        <Sidebar />
      </main>
      <Footer />
    </div>
  );
};

export default ProdukHukumJenis;
