import React from "react";
import useDocumentTitle from "../../assets/utils/useDocumentTitle";
import { Breadcrumb, Footer, Header, Sidebar } from "../../components";
import "./style.scss";

function Pparkir() {
  useDocumentTitle("Pajak Parkir- BAPENDA BEKASI");
  return (
    <div className="page">
      <Header active="Pparkir" />

      {/* main */}
      <main className="main-sb">
        <section className="content">
          <Breadcrumb path="Jenis Pelayanan / Pajak Parkir" />
          <div className="page-title-wrapper">
            <div className="page-title">Pajak Parkir</div>
          </div>

          {/* <p className="page-text">
         </p> */}

          <p className="subpage-title"><strong>Pajak Parkir</strong></p>
          <p className="page-text">Parkir adalah keadaan tidak bergerak suatu kendaraan yang tidak bersifat
</p>
<p className="page-text">
Tarif Pajak Parkir ditetapkan sebagai berikut :
a. Pajak Parkir 30 % (tiga puluh persen);
b. Penitipan kendaraan bermotor 30% (tiga puluh persen);
c. Jasa Valet atau jasa sejenisnya 30% (tiga puluh persen).
Paragraf 3
Cara Penghitungan Pajak dan Wilayah Pemungutan
Pasal 49
(1) Besaran pokok Pajak Parkir yang terutang dihitung dengan cara
mengalikan tarif sebagaimana dimaksud dalam Pasal 48 dengan dasar
pengenaan pajak sebagaimana dimaksud dalam Pasal 47.
(2) Pajak Parkir yang terutang dipungut di wilayah Daerah tempat Parkir
berlokasi.


</p>		
        
         
         
          
        </section>
        <Sidebar />
      </main>

      <Footer />
    </div>
  );
}

export default Pparkir;
