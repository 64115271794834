import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DocIcon, FolderIcon } from "../../assets/illustrations/Icon";
import BaseURL from "../../assets/utils/baseUrl";
import useDocumentTitle from "../../assets/utils/useDocumentTitle";
import { Breadcrumb, Footer, Header, Sidebar } from "../../components";
import "./style.scss";

const ProdukHukum = () => {
  useDocumentTitle("Produk Hukum - BAPENDA BEKASI");

  const [pergub, setPergub] = useState([]);
  const [perda, setPerda] = useState([]);
  const [skkd, setSkkd] = useState([]);
  const [formPajakDaerah, setFormPajakDaerah] = useState([]);
  const [jumlahProdukHukum, setJumlahProdukHukum] = useState({
    pergub: 0,
    perda: 0,
    skkd: 0,
    formPajakDaerah: 0,
  });
  const [showTooltip, setShowTooltip] = useState(null);

  useEffect(() => {
    const getPergub = () => {
      fetch(BaseURL + "api/produk_hukum?kategori=1")
        .then((res) => res.json())
        .then((data) => {
          setJumlahProdukHukum((prevState) => ({
            ...prevState,
            pergub: data.data.length,
          }));
          if (data.data.length > 10) {
            let pergubTemp = [];
            for (let i = 0; i < 10; i++) {
              pergubTemp.push(data.data[i]);
            }
            setPergub(pergubTemp);
          } else {
            setPergub(data.data);
          }
        })
        .catch((error) =>
          console.log("sorry, there is a mistake while loading data")
        );
    };
    const getPerda = () => {
      fetch(BaseURL + "api/produk_hukum?kategori=2")
        .then((res) => res.json())
        .then((data) => {
          setJumlahProdukHukum((prevState) => ({
            ...prevState,
            perda: data.data.length,
          }));
          if (data.data.length > 10) {
            let perdaTemp = [];
            for (let i = 0; i < 10; i++) {
              perdaTemp.push(data.data[i]);
            }
            setPerda(perdaTemp);
          } else {
            setPerda(data.data);
          }
        })
        .catch((error) =>
          console.log("sorry, there is a mistake while loading data")
        );
    };
    const getSkkd = () => {
      fetch(BaseURL + "api/produk_hukum?kategori=3")
        .then((res) => res.json())
        .then((data) => {
          setJumlahProdukHukum((prevState) => ({
            ...prevState,
            skkd: data.data.length,
          }));
          if (data.data.length > 10) {
            let skkdTemp = [];
            for (let i = 0; i < 10; i++) {
              skkdTemp.push(data.data[i]);
            }
            setSkkd(skkdTemp);
          } else {
            setSkkd(data.data);
          }
        })
        .catch((error) =>
          console.log("sorry, there is a mistake while loading data")
        );
    };
    const getFormPajakDaerah = () => {
      fetch(BaseURL + "api/produk_hukum?kategori=4")
        .then((res) => res.json())
        .then((data) => {
          setJumlahProdukHukum((prevState) => ({
            ...prevState,
            formPajakDaerah: data.data.length,
          }));
          if (data.data.length > 10) {
            let formPajakDaerahTemp = [];
            for (let i = 0; i < 10; i++) {
              formPajakDaerahTemp.push(data.data[i]);
            }
            setFormPajakDaerah(formPajakDaerahTemp);
          } else {
            setFormPajakDaerah(data.data);
          }
        })
        .catch((error) =>
          console.log("sorry, there is a mistake while loading data")
        );
    };
    getPergub();
    getPerda();
    getSkkd();
    getFormPajakDaerah();
  }, []);

  return (
    <div className="page">
      <Header active="publikasi" />
      <main className="main-sb">
        <section className="content">
          <Breadcrumb path="Publikasi / Produk Hukum" />
          <div className="page-title-wrapper">
            <div className="page-title">Produk Hukum</div>
          </div>

          <div className="ph-container">
            <div className="ph-panel">
              <Link to="/produk-hukum/peraturan-walikota" className="ph-title">
                <FolderIcon style="ph-title-icon" />
                <div className="ph-title-text">
                  Peraturan Walikota ({pergub.length})
                </div>
              </Link>

              <div className="ph-content">
                {pergub.length > 0 &&
                  pergub.map((item, index) => (
                    <Link
                      to={"/produk-hukum/peraturan-walikota/" + showTooltip}
                      className="ph-item"
                      key={index}
                    >
                      <DocIcon style="ph-item-icon" />
                      <div
                        className="ph-item-text"
                        onMouseOver={() => setShowTooltip(item.id_ph)}
                        onMouseOut={() => setShowTooltip(null)}
                      >
                        {item.nama}
                      </div>
                      {showTooltip === item.id_ph && (
                        <div className="ph-item-text-hover">{item.nama}</div>
                      )}
                    </Link>
                  ))}
              </div>
            </div>

            <div className="ph-panel">
              <Link to="/produk-hukum/peraturan-daerah" className="ph-title">
                <FolderIcon style="ph-title-icon" />
                <div className="ph-title-text">
                  Peraturan Daerah ({jumlahProdukHukum.perda})
                </div>
              </Link>

              <div className="ph-content">
                {perda.length > 0 &&
                  perda.map((item, index) => (
                    <Link
                      to={"/produk-hukum/peraturan-daerah/" + item.id_ph}
                      className="ph-item"
                      key={index}
                    >
                      <DocIcon style="ph-item-icon" />
                      <div
                        className="ph-item-text"
                        onMouseOver={() => setShowTooltip(item.id_ph)}
                        onMouseOut={() => setShowTooltip(null)}
                      >
                        {item.nama}
                      </div>
                      {showTooltip === item.id_ph && (
                        <div className="ph-item-text-hover">{item.nama}</div>
                      )}
                    </Link>
                  ))}
              </div>
            </div>

            <div className="ph-panel">
              <Link
                to="/produk-hukum/surat-keputusan-kepala-daerah"
                className="ph-title"
              >
                <FolderIcon style="ph-title-icon" />
                <div className="ph-title-text">
                  Surat Keputusan Kepala Daerah ({skkd.length})
                </div>
              </Link>

              <div className="ph-content">
                {skkd.length > 0 &&
                  skkd.map((item, index) => (
                    <Link
                      to={
                        "/produk-hukum/surat-keputusan-kepala-daerah/" +
                        item.id_ph
                      }
                      className="ph-item"
                      key={index}
                    >
                      <DocIcon style="ph-item-icon" />
                      <div
                        className="ph-item-text"
                        onMouseOver={() => setShowTooltip(item.id_ph)}
                        onMouseOut={() => setShowTooltip(null)}
                      >
                        {item.nama}
                      </div>
                      {showTooltip === item.id_ph && (
                        <div className="ph-item-text-hover">{item.nama}</div>
                      )}
                    </Link>
                  ))}
              </div>
            </div>

            <div className="ph-panel">
              <Link
                to="/produk-hukum/download-formulir-pajak-daerah"
                className="ph-title"
              >
                <FolderIcon style="ph-title-icon" />
                <div className="ph-title-text">
                  Download Formulir Pajak Daerah ({formPajakDaerah.length})
                </div>
              </Link>

              <div className="ph-content">
                {formPajakDaerah.length > 0 &&
                  formPajakDaerah.map((item, index) => (
                    <Link
                      to={
                        "/produk-hukum/download-formulir-pajak-daerah/" +
                        item.id_ph
                      }
                      className="ph-item"
                      key={index}
                    >
                      <DocIcon style="ph-item-icon" />
                      <div
                        className="ph-item-text"
                        onMouseOver={() => setShowTooltip(item.id_ph)}
                        onMouseOut={() => setShowTooltip(null)}
                      >
                        {item.nama}
                      </div>
                      {showTooltip === item.id_ph && (
                        <div className="ph-item-text-hover">{item.nama}</div>
                      )}
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        </section>
        <Sidebar />
      </main>
      <Footer />
    </div>
  );
};

export default ProdukHukum;
