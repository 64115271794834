import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Agenda, AgendaDetail, Beranda, Berita, BeritaDetail, Galeri,LokasiPelayanan, Kontak, Pejabat, StrukturOrganisasi,StrukturPPID, VisiMisi,Laporanppid,JPbphtb,JPpbb,JPrestoran,Photel,Phiburan,Preklame,Pairtanah,Pparkir,Pppj,ProdukHukum,ProdukHukumDetail,ProdukHukumJenis,BeritaVideo,DokumenPerencanaan, } from "./pages";
import {
  Dashboard,
  KelolaAgenda,
  KelolaApbd,
  KelolaBanner,
  KelolaBerita,
  KelolaBidang,
  KelolaGaleri,
  KelolaInfografis,
  KelolaJabatan,
  KelolaPad,
  KelolaPajak,
  KelolaPejabat,
  KelolaRetribusi,
  KelolaUser,
  Login,
  KelolaProdukHukum,
  KelolaLaporanPpid,
  KelolaBeritaVideo,
  KelolaDokumenPerencanaan,

} from "./pages/Admin";
import useToken from "./assets/utils/useToken";

function App() {
  const { token, setToken } = useToken();

  return (
    <Router basename="/bapenda-bekasi">
      <div className="App">
        <Switch>
          <Route path="/" exact component={Beranda} />
          <Route path="/visimisi" component={VisiMisi} />
          <Route path="/struktur-organisasi" component={StrukturOrganisasi} />
		  <Route path="/StrukturPPID" component={StrukturPPID} />
          <Route path="/JPbphtb" component={JPbphtb} />
		  <Route path="/JPpbb" component={JPpbb} />
		  <Route path="/JPrestoran" component={JPrestoran} />
		  <Route path="/Photel" component={Photel} />
		  <Route path="/Phiburan" component={Phiburan} />
		  <Route path="/Preklame" component={Preklame} />
		  <Route path="/Pairtanah" component={Pairtanah} />
		  <Route path="/Pparkir" component={Pparkir} />
		  <Route path="/Pppj" component={Pppj} />
          <Route path="/berita" exact component={Berita} />
          <Route path="/berita-detail/:id" component={BeritaDetail} />
          <Route path="/agenda" component={Agenda} />
          <Route path="/agenda-detail/:id" component={AgendaDetail} />
          <Route path="/galeri" component={Galeri} />
         <Route path="/lokasi-pelayanan" component={LokasiPelayanan} />
          <Route path="/pejabat" component={Pejabat} />
          <Route path="/kontak" component={Kontak} />
          <Route path="/Laporanppid" component={Laporanppid} />
		   
         
		    <Route path="/berita-video" component={BeritaVideo} />
			  <Route path="/dokumen-perencanaan" component={DokumenPerencanaan} />
	
      <Route path="/ProdukHukum" exact component={ProdukHukum} />
	   <Route path="/produk-hukum/:kategori" exact    component={ProdukHukumJenis} />
         
		 <Route path="/produk-hukum/:kategori/:id" component={ProdukHukumDetail} />

          <Route path="/admin" exact>
            {token ? <Dashboard setToken={(e) => setToken(e)} /> : <Redirect to="/admin/login" />}
          </Route>

          <Route path="/admin/kelola-berita" exact>
            {token ? <KelolaBerita setToken={(e) => setToken(e)} /> : <Redirect to="/admin/login" />}
          </Route>

          <Route path="/admin/kelola-agenda" exact>
            {token ? <KelolaAgenda setToken={(e) => setToken(e)} /> : <Redirect to="/admin/login" />}
          </Route>
		
		
		   <Route path="/admin/kelola-produk-hukum" exact>
            {token ? (
              <KelolaProdukHukum setToken={(e) => setToken(e)} />
            ) : (
              <Redirect to="/admin/login" />
            )}
          </Route>
        
          <Route path="/admin/kelola-galeri" exact>
            {token ? <KelolaGaleri setToken={(e) => setToken(e)} /> : <Redirect to="/admin/login" />}
          </Route>

          <Route path="/admin/kelola-infografis" exact>
            {token ? <KelolaInfografis setToken={(e) => setToken(e)} /> : <Redirect to="/admin/login" />}
          </Route>
	  

          <Route path="/admin/kelola-pajak" exact>
            {token ? <KelolaPajak setToken={(e) => setToken(e)} /> : <Redirect to="/admin/login" />}
          </Route>

          <Route path="/admin/kelola-retribusi" exact>
            {token ? <KelolaRetribusi setToken={(e) => setToken(e)} /> : <Redirect to="/admin/login" />}
          </Route>

          <Route path="/admin/kelola-apbd" exact>
            {token ? <KelolaApbd setToken={(e) => setToken(e)} /> : <Redirect to="/admin/login" />}
          </Route>

          <Route path="/admin/kelola-pad" exact>
            {token ? <KelolaPad setToken={(e) => setToken(e)} /> : <Redirect to="/admin/login" />}
          </Route>

          <Route path="/admin/kelola-pejabat" exact>
            {token ? <KelolaPejabat setToken={(e) => setToken(e)} /> : <Redirect to="/admin/login" />}
          </Route>

          <Route path="/admin/kelola-jabatan" exact>
            {token ? <KelolaJabatan setToken={(e) => setToken(e)} /> : <Redirect to="/admin/login" />}
          </Route>
          <Route path="/admin/kelola-bidang" exact>
            {token ? <KelolaBidang setToken={(e) => setToken(e)} /> : <Redirect to="/admin/login" />}
          </Route>

          <Route path="/admin/kelola-banner" exact>
            {token ? <KelolaBanner setToken={(e) => setToken(e)} /> : <Redirect to="/admin/login" />}
          </Route>
        
          <Route path="/admin/kelola-produk-hukum" exact>
            {token && localStorage.getItem("role") == "superadmin" ? (
              <KelolaProdukHukum setToken={(e) => setToken(e)} />
            ) : (
              <Redirect to="/admin/login" />
            )}
          </Route>
		   <Route path="/admin/kelola-laporan-ppid" exact>
            {token && localStorage.getItem("role") == "superadmin" ? (
              <KelolaLaporanPpid setToken={(e) => setToken(e)} />
            ) : (
              <Redirect to="/admin/login" />
            )}
          </Route>
		  
		   <Route path="/admin/kelola-berita-video" exact>
            {token && localStorage.getItem("role") == "superadmin" ? (
              <KelolaBeritaVideo setToken={(e) => setToken(e)} />
            ) : (
              <Redirect to="/admin/login" />
            )}
          </Route>
		  
		   <Route path="/admin/kelola-dokumen-perencanaan" exact>
            {token && localStorage.getItem("role") == "superadmin" ? (
              <KelolaDokumenPerencanaan setToken={(e) => setToken(e)} />
            ) : (
              <Redirect to="/admin/login" />
            )}
          </Route>

          <Route path="/admin/kelola-user" exact>
            {token && localStorage.getItem("role") == "superadmin" ? <KelolaUser setToken={(e) => setToken(e)} /> : <Redirect to="/admin/login" />}
          </Route>

          <Route path="/admin/login" exact>
            {token ? <Redirect to="/admin" /> : <Login setToken={(e) => setToken(e)} />}
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
