import React, { useEffect, useState } from "react";
import BaseURL from "../../../assets/utils/baseUrl";
import MUIDataTable from "mui-datatables";
import { Button, Footer, HeaderAdmin } from "../../../components";
import { TextField } from "@material-ui/core";
import { Loader } from "../../../assets";
import { InputLabel } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";

function KelolaProdukHukum({ setToken }) {
  const [produkHukum, setProdukHukum] = useState([]);
  const [jenisProdukHukum, setJenisProdukHukum] = useState([]);
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(true);
  const [popUp, setPopUp] = useState(false);
  const [success, setSuccess] = useState(true);
  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [alertDokumen, setAlertDokumen] = useState(false);
  const [alertDeskripsi, setAlertDeskripsi] = useState(false);
  const [alertKategori, setAlertKategori] = useState(false);
  const [alertFile, setAlertFile] = useState(false);
  const [formData, setFormData] = useState({
    dokumen: "",
    deskripsi: "",
    kategori: "",
    created_at: "",
    file: null,
  });
  const [id, setId] = useState("");

  useEffect(() => {
    setLoading(true);
    const getProdukHukum = async () => {
      await fetch(BaseURL + "api/produk_hukum")
        .then((res) => res.json())
        .then((data) => {
          setProdukHukum(data.data);
          setLoading(false);
        });
    };
    const getJenisProdukHukum = async () => {
      await fetch(BaseURL + "api/jenis_produk_hukum")
        .then((res) => res.json())
        .then((data) => {
          setJenisProdukHukum(data.data);
          setLoading(false);
        });
    };
    getProdukHukum();
    getJenisProdukHukum();
  }, [load]);

  const columns = [
    {
      name: "dokumen",
      label: "Dokumen",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "deskripsi",
      label: "Deskripsi",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "kategori",
      label: "Kategori",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "tanggal_post",
      label: "Tanggal Post",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <div style={{ width: 100 }}>{value}</div>;
        },
      },
    },
    {
      name: "file",
      label: "File",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <div style={{ width: 100, textDecoration: "underline" }}>
              <a
                href={BaseURL + "assets/documents/produk_hukum/" + value}
                target="_blank"
              >
                File
              </a>
            </div>
          );
        },
      },
    },
    {
      name: "user",
      label: "Admin",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "opsi",
      label: "Opsi",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="tooltip btn-hapus-row"
                onClick={() => hapus(value)}
              >
                <i className="fas fa-trash-alt"></i>
                <span className="tooltiptext">Hapus</span>
              </div>
              <div
                className="tooltip btn-edit-row"
                onClick={() => {
                  setUpdateModal(true);
                  openUpdateModal(value);
                }}
              >
                <i className="fas fa-edit"></i>
                <span className="tooltiptext">Update</span>
              </div>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    sort: true,
    filter: false,
    download: false,
    print: false,
    selectableRows: false,
  };

  let tablesData = [];

  if (produkHukum) {
    for (let i = 0; i < produkHukum.length; i++) {
      tablesData.push({
        dokumen: produkHukum[i].nama,
        deskripsi: produkHukum[i].deskripsi,
        kategori: produkHukum[i].nama_kategori,
        tanggal_post: produkHukum[i].created_at,
        file: produkHukum[i].file,
        user: produkHukum[i].nama_user,
        opsi: produkHukum[i].id_ph,
      });
    }
  }

  const tambah = () => {
    if (formData.dokumen == "") {
      setAlertDokumen(true);
    } else {
      setAlertDokumen(false);
    }

    if (formData.deskripsi == "") {
      setAlertDeskripsi(true);
    } else {
      setAlertDeskripsi(false);
    }

    if (formData.kategori == "") {
      setAlertKategori(true);
    } else {
      setAlertKategori(false);
    }

    if (formData.file == null) {
      setAlertFile(true);
    } else {
      setAlertFile(false);
    }

    if (
      formData.dokumen !== "" &&
      formData.deskripsi !== "" &&
      formData.kategori !== "" &&
      formData.file !== null
    ) {
      const data = new FormData();
      let today = new Date();
      let date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      data.append("request", "post");
      data.append("nama", formData.dokumen);
      data.append("deskripsi", formData.deskripsi);
      data.append("kategori", formData.kategori);
      data.append("id_user", localStorage.getItem("id"));
      data.append("created_at", date);
      data.append("file", formData.file);

      try {
        fetch(BaseURL + "api/produk_hukum", {
          method: "POST",
          body: data,
        })
          .then((response) => response.json())
          .then((data) => {
            setFormData({
              dokumen: "",
              deskripsi: "",
              kategori: "",
              file: null,
            });
            setLoad(!load);
            setModal(false);
            setUpdateModal(false);
            if (data.status) {
              setSuccess(true);
            } else {
              setSuccess(false);
            }
            setPopUp(true);
          })
          .catch((error) => console.log("Gagal: ", error));
      } catch (error) {
        alert("Terjadi kesalahan pada sistem");
        console.log(error);
      }
    }
  };

  const hapus = (id) => {
    var encodedKey = encodeURIComponent("id_ph");
    var encodedValue = encodeURIComponent(id);
    const formBody = [encodedKey + "=" + encodedValue];
    try {
      fetch(BaseURL + "api/produk_hukum", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((data) => {
          setLoad(!load);
          if (data.status) {
            setSuccess(true);
          } else {
            setSuccess(false);
          }
          setPopUp(true);
        })
        .catch((error) => console.log("Gagal: ", error));
    } catch (error) {
      alert("Terjadi kesalahan pada sistem");
      console.log(error);
    }
  };

  const openUpdateModal = (id) => {
    const getDataUpdate = async () => {
      await fetch(BaseURL + "api/produk_hukum?id=" + id)
        .then((res) => res.json())
        .then((data) => {
          setFormData({
            ...formData,
            dokumen: data.data[0].nama,
            deskripsi: data.data[0].deskripsi,
            kategori: data.data[0].kategori,
          });
          setId(id);
          setModal(true);
        });
    };
    getDataUpdate();
  };

  const update = () => {
    if (formData.dokumen == "") {
      setAlertDokumen(true);
    } else {
      setAlertDokumen(false);
    }

    if (formData.deskripsi == "") {
      setAlertDeskripsi(true);
    } else {
      setAlertDeskripsi(false);
    }

    if (formData.kategori == "") {
      setAlertKategori(true);
    } else {
      setAlertKategori(false);
    }

    if (
      formData.dokumen !== "" &&
      formData.deskripsi !== "" &&
      formData.kategori !== ""
    ) {
      const data = new FormData();
      let today = new Date();
      let date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      data.append("request", "put");
      data.append("id_ph", id);
      data.append("nama", formData.dokumen);
      data.append("deskripsi", formData.deskripsi);
      data.append("kategori", formData.kategori);
      data.append("id_user", localStorage.getItem("id"));
      data.append("updated_at", date);
      data.append("file", formData.file);

      try {
        fetch(BaseURL + "api/produk_hukum", {
          method: "POST",
          body: data,
        })
          .then((response) => response.json())
          .then((data) => {
            setFormData({
              dokumen: "",
              deskripsi: "",
              kategori: "",
              file: null,
            });
            setLoad(!load);
            setModal(false);
            setUpdateModal(false);
            if (data.status) {
              setSuccess(true);
            } else {
              setSuccess(false);
            }
            setPopUp(true);
          })
          .catch((error) => console.log("Gagal: ", error));
      } catch (error) {
        alert("Terjadi kesalahan pada sistem");
        console.log(error);
      }
    }
  };

  return (
    <div className="page">
      <HeaderAdmin title="Kelola Produk Hukum" setToken={(e) => setToken(e)} />
      <div className="main-admin">
        <div className="container-admin">
          <div style={{ height: 50 }} />
          <Button
            title="Tambah Post"
            type="primary"
            onClick={() => {
              setUpdateModal(false);
              setModal(true);
            }}
          />
          <div style={{ height: 10 }} />
          {loading ? (
            <div className="loader">
              <img src={Loader}></img>
            </div>
          ) : (
            <MUIDataTable
              title={"Daftar Produk Hukum"}
              data={tablesData}
              columns={columns}
              options={options}
              download={false}
              className="table"
            />
          )}
        </div>
      </div>

      <Footer />

      {/* modal */}
      <div className={modal ? "darker-bg-admin" : "darker-bg-admin hide"}>
        {/* form */}
        <div className="modal-admin">
          <div className="form-admin">
            <TextField
              id="dokumen"
              label="Dokumen"
              variant="outlined"
              style={{ marginTop: 10, marginBottom: 10 }}
              onChange={(e) =>
                setFormData({ ...formData, dokumen: e.target.value })
              }
              value={formData.dokumen}
            />
            {alertDokumen && (
              <div className="danger-alert">
                Nama dokumen tidak boleh kosong
              </div>
            )}

            <TextField
              id="deskripsi"
              label="Deskripsi"
              variant="outlined"
              style={{ marginTop: 10, marginBottom: 10 }}
              multiline
              rows={10}
              onChange={(e) =>
                setFormData({ ...formData, deskripsi: e.target.value })
              }
              value={formData.deskripsi}
            />
            {alertDeskripsi && (
              <div className="danger-alert">Deskripsi tidak boleh kosong</div>
            )}

            <InputLabel id="jabatan-select-label">Kategori</InputLabel>
            <Select
              labelId="jabatan-select-label"
              id="kategori"
              value={formData.kategori}
              onChange={(e) =>
                setFormData({ ...formData, kategori: e.target.value })
              }
            >
              {jenisProdukHukum.length > 0 &&
                jenisProdukHukum.map((item, idx) => {
                  return (
                    <MenuItem value={item.id_jenis_ph} key={idx}>
                      {item.nama}
                    </MenuItem>
                  );
                })}
            </Select>
            {alertKategori && (
              <div className="danger-alert">Kategori tidak boleh kosong</div>
            )}

            <div style={{ height: 10 }}></div>

            <div className="btn-thumbnail">
              <Button title="Pilih File" type="secondary" />
              <span className="file-name">
                {formData.file != null
                  ? formData.file.name
                  : updateModal
                  ? "(Jika tidak ingin mengubah file, tetap kosongkan)"
                  : "(anda belum memilih file)"}
              </span>
              <input
                className="invisible-input"
                type="file"
                title="Pilih File"
                onChange={(e) =>
                  setFormData({ ...formData, file: e.target.files[0] })
                }
              ></input>
            </div>
            {alertFile && (
              <div className="danger-alert">File tidak boleh kosong</div>
            )}
          </div>

          <div style={{ height: 30 }}></div>

          <div className="form-option">
            <Button
              title={updateModal ? "Update" : "Tambah"}
              type="primary"
              onClick={() => {
                if (updateModal) {
                  update();
                } else {
                  tambah();
                }
              }}
            />
            <div style={{ width: 10 }}></div>
            <Button
              title="Batal"
              type="danger"
              onClick={() => {
                setModal(false);
                setUpdateModal(false);
                setFormData({
                  dokumen: "",
                  deskripsi: "",
                  kategori: "",
                  file: null,
                });
                setAlertDokumen(false);
                setAlertDeskripsi(false);
                setAlertKategori(false);
                setAlertFile(false);
              }}
            />
          </div>
        </div>
      </div>

      {/* message pop up */}
      <div className={popUp ? "darker-bg-admin" : "darker-bg-admin hide"}>
        <div className="pop-up-admin">
          <i
            className={
              success
                ? "fas fa-check-circle pop-up-logo"
                : "fas fa-times-circle pop-up-logo failed-pop-up"
            }
          ></i>
          {success ? (
            <p>Berhasil</p>
          ) : (
            <p className={success ? "" : "failed-pop-up"}>Gagal</p>
          )}
          <div style={{ height: 20 }}></div>
          <Button
            title="Tutup"
            type="primary"
            onClick={() => {
              setAlertDokumen(false);
              setAlertDeskripsi(false);
              setAlertKategori(false);
              setAlertFile(false);
              setPopUp(false);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default KelolaProdukHukum;
