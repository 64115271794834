import React, { useState } from "react";
import useDocumentTitle from "../../assets/utils/useDocumentTitle";
import {
  Breadcrumb,
  Footer,
  Header,
  Numbering,
  PanelAccordion,
  Sidebar,
} from "../../components";
import "./style.scss";
import { items } from "./ListContentAccordion";

function JPbphtb() {
  useDocumentTitle("Pelayanan Bphtb- BAPENDA BEKASI");
  const [isActive, setIsActive] = useState(null);

  return (
    <div className="page">
      <Header active="JPbphtb" />

      {/* main */}
      <main className="main-sb">
        <section className="content">
          <Breadcrumb path="Jenis Pelayanan / BPHTB" />
          <div className="page-title-wrapper">
            <div className="page-title">Pajak BPHTB</div>
          </div>
          <div className="jenis-pajak-title">
            <strong>Bea Perolehan Hak atas Tanah dan Bangunan</strong>
          </div>
          <div className="jenis-pajak-content">
            <p className="page-text">
              Bea Perolehan Hak atas Tanah dan Bangunan atau yang disingkat
              dengan BPHTB, diatur dalam ketentuan peraturan perundang-undangan
              di Indonesia, yaitu dengan UU No. 21 Tahun 1997 tentang Bea
              Perolehan Hak Atas Tanah dan Bangunan sebagaimana telah diubah
              dengan UU No. 20 Tahun 2000 tentang Perubahan Atas UU No. 21 Tahun
              1997 tentang Bea Perolehan Hak Atas Tanah dan Bangun. Dalam UU No.
              21 Tahun 1997 sebagaimana telah diubah dengan UU No. 20 Tahun 2000
              (disebut dengan UU BPHTB), memberikan pengertian mengenai BPHTB,
              yaitu Bea Perolehan Hak atas Tanah dan Bangunan adalah pajak yang
              dikenakan atas perolehan hak atas tanah dan atau bangunan, yang
              selanjutnya disebut pajak. Jadi BPHTB adalah sama dengan Pajak
              Perolehan Hak atas Tanah dan Bangunan.
            </p>
            <p className="page-text">
              Yang dimaksud dengan Perolehan Hak atas Tanah dan Bangunan, UU
              BPHTB menyebutkan bahwa Perolehan Hak atas Tanah dan atau bangunan
              adalah perbuatan atau peristiwa hukum yang mengakibatkan
              diperolehnya hak atas tanah dan atau bangunan oleh orang pribadi
              atau badan. Adapun Hak atas Tanah dan atau Bangunan adalah hak
              atas tanah, termasuk hak pengelolaan, beserta bangunan di atasnya,
              sebagaimana dimaksud dalam UU No. 5 Tahun 1960 tentang Peraturan
              Dasar Pokok-Pokok Agraria, UU No. 16 Tahun 1985 tentang Rumah
              Susun, dan ketentuan peraturan perundang-undangan lainnya.
            </p>
            <p className="page-text">
              Hak Atas Tanah Pasal 16 ayat (1) UU No. 5 Tahun 1960 menyebutkan
              bahwa hak-hak atas tanah yang dimaksud ialah :
            </p>
            <Numbering number="1" content="hak milik;" />
            <Numbering number="2" content="hak guna usaha;" />
            <Numbering number="3" content="hak guna bangunan;" />
            <Numbering number="4" content="hak pakai;" />
            <Numbering number="5" content="hak sewa;" />
            <Numbering number="6" content="hak membuka tanah;" />
            <Numbering number="7" content="hak memungut hasil hutan; dan" />
            <Numbering
              number="8"
              content="hak-hak lain yang tidak termasuk dalam hak-hak tersebut diatas yang akan ditetapkan dengan undang-undang serta hak-hak yang sifatnya sementara. Hak-hak yang sifatnya sementara tersebut, ialah hak gadai, hak usaha bagi hasil, hak menumpang dan hak sewa tanah pertanian diatur untuk membatasi sifatsifatnya yang bertentangan dengan Undang-undang ini dan hak-hak tersebut diusahakan hapusnya di dalam waktu yang singkat."
            />
          </div>
          <div className="jenis-pajak-content">
            {items.map((item, index) => {
              return (
                <PanelAccordion
                  key={index}
                  label={item.label}
                  content={item.content}
                  index={index}
                  setIsActive={setIsActive}
                  isActive={isActive}
                />
              );
            })}
          </div>
        </section>
        <Sidebar />
      </main>

      <Footer />
    </div>
  );
}

export default JPbphtb;
