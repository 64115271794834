import React from "react";
import useDocumentTitle from "../../assets/utils/useDocumentTitle";
import { Breadcrumb, Footer, Header, Sidebar } from "../../components";
import "./style.scss";

function Photel() {
  useDocumentTitle("Pajak Hotel- BAPENDA BEKASI");
  return (
    <div className="page">
      <Header active="Photel" />

      {/* main */}
      <main className="main-sb">
        <section className="content">
          <Breadcrumb path="Jenis Pelayanan / Pajak Hotel" />
          <div className="page-title-wrapper">
            <div className="page-title">Pajak Hotel</div>
          </div>

          {/* <p className="page-text">
            Dalam rangka pelaksanaan Peraturan Pemerintah Nomor 18 Tahun 2016 tentang Perangkat Daerah dan Peraturan Menteri Dalam Negeri Nomor 97 Tahun 2016 tentang Perangkat Daerah Provinsi DKI Jakarta, maka berdasarkan Peraturan Gubernur
            Nomor 154 Tahun 2020 tentang Organisasi dan Tata Kerja Perangkat Daerah dibentuklah Badan Pendapatan Daerah (Bapenda) Provinsi DKI Jakarta.
          </p> */}

          <p className="subpage-title"><strong>Pajak Hotel</strong></p>
          <p className="page-text">Hotel adalah fasilitas penyedia jasa penginapan/peristirahatan termasuk
jasa terkait lainnya dengan dipungut bayaran, yang mencakup juga motel,
losmen, gubuk pariwisata, wisma pariwisata, pesanggrahan, rumah
penginapan dan sejenisnya, serta rumah kos dengan jumlah kamar lebih
dari 10 (sepuluh).</p>
<p>
Dasar Pengenaan dan Tarif Pajak
Pasal 5
Dasar pengenaan Pajak Hotel adalah jumlah pembayaran atau yang
seharusnya dibayar kepada Hotel.
13
Pasal 6
Tarif Pajak Hotel ditetapkan sebesar 10% (sepuluh persen).


</p>
         
        
         
         
          
        </section>
        <Sidebar />
      </main>

      <Footer />
    </div>
  );
}

export default Photel;
