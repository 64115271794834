import React from "react";
import useDocumentTitle from "../../assets/utils/useDocumentTitle";
import { Breadcrumb, Footer, Header, Sidebar } from "../../components";
import "./style.scss";

function Preklame() {
  useDocumentTitle("Pajak Reklame- BAPENDA BEKASI");
  return (
    <div className="page">
      <Header active="Preklame" />

      {/* main */}
      <main className="main-sb">
        <section className="content">
          <Breadcrumb path="Jenis Pelayanan / Pajak Reklame" />
          <div className="page-title-wrapper">
            <div className="page-title">Pajak Reklame</div>
          </div>

          {/* <p className="page-text">
            Dalam rangka pelaksanaan Peraturan Pemerintah Nomor 18 Tahun 2016 tentang Perangkat Daerah dan Peraturan Menteri Dalam Negeri Nomor 97 Tahun 2016 tentang Perangkat Daerah Provinsi DKI Jakarta, maka berdasarkan Peraturan Gubernur
            Nomor 154 Tahun 2020 tentang Organisasi dan Tata Kerja Perangkat Daerah dibentuklah Badan Pendapatan Daerah (Bapenda) Provinsi DKI Jakarta.
          </p> */}

          <p className="subpage-title"><strong>Pajak Reklame</strong></p>
          <p className="page-text">Reklame adalah benda, alat, perbuatan, atau media yang bentuk dan corak
ragamnya dirancang untuk tujuan komersial memperkenalkan,
menganjurkan, mempromosikan, atau untuk menarik perhatian umum
terhadap barang, jasa, orang, atau badan, yang dapat dilihat, dibaca,
didengar, dirasakan, dan/atau dinikmati oleh umum.
</p>
<p className="page-text">
Tarif Pajak Reklame ditetapkan sebesar 25% (dua puluh lima persen).
21
Pasal 32
(1) Rumus NSR adalah besaran nilai kelas jalan x ukuran media reklame (m2)
x jumlah reklame x jangka waktu penyelenggaraan x 25% (dua puluh lima
meter persen) dari jumlah pokok pajak.
(2) Hasil perhitungan NSR sebagaimana dimaksud pada ayat (1) ditetapkan
dalam table Hasil Perhitungan Nilai Sewa Reklame yang ditetapkan dengan
Peraturan Wali Kota, dan dapat dievaluasi atau disesuaikan setiap 2 (dua)
tahun sekali.

</p>

         
        
         
         
          
        </section>
        <Sidebar />
      </main>

      <Footer />
    </div>
  );
}

export default Preklame;
