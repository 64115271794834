import React from "react";
import "./style.scss";
import { logoBekasi, logoWebBekasi } from "../../../assets";
import { Link } from "react-router-dom";

function SubFooter() {
  return (
    <div className="sub-footer-wrapper bg-dark">
      {/* <div className="visitor-tracking">
        <div className="vt-item">
          <p className="vt-number">25+</p>
          <p className="vt-title">Pengunjung Hari ini</p>
        </div>
        <div className="vt-item">
          <p className="vt-number">100+</p>
          <p className="vt-title">Total Pengunjung</p>
        </div>
        <div className="vt-item">
          <p className="vt-number">5+</p>
          <p className="vt-title">Pengunjung Online</p>
        </div>
      </div> */}
      <div className="sub-footer">
        <div className="identitas">
          <img className="logo-bekasi" src={logoBekasi}></img>
          <p>Badan Pendapatan Daerah</p>
          <p>Kota Bekasi</p>
          <div className="identitas-item-wrapper">
            <div className="identitas-item">
              <div className="identitas-item-logo">
                <i className="fas fa-map-marker-alt" />
              </div>
              <div className="identitas-item-text">
                <p>Jl. Ir. H. Juanda No.100, RT.001/RW.005, Margahayu, Kec. Bekasi Timur</p>
                <p>Kota Bekasi, Jawa Barat 17113</p>
              </div>
            </div>
            <div className="identitas-item">
              <div className="identitas-item-logo">
                <i className="fas fa-envelope" />
              </div>
              <div className="identitas-item-text">
                <p>opd.bapenda@bekasikota.go.id</p>
              </div>
            </div>
            <div className="identitas-item">
              <div className="identitas-item-logo">
                <i className="fas fa-phone" />
              </div>
              <div className="identitas-item-text">
                <p>(021) 82654756</p>
              </div>
            </div>

            <div className="identitas-item">
              <div className="identitas-item-logo">
                <i className="fas fa-phone" />
              </div>
              <div className="identitas-item-text">
                <p>WA : 0813-1170-4059</p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-info">
          <div className="footer-anchor">
            <div className="footer-info-title">Layanan</div>
            <a href="http://ebphtb.bekasikota.go.id/" className="footer-info-item">
              E-BPHTB
            </a>
            <a href="http://sipdah.bekasikota.go.id/" className="footer-info-item">
              E-SPTPD
            </a>
          </div>

          <div className="footer-anchor">
            <div className="footer-info-title">Publikasi</div>
            <Link to="/berita" className="footer-info-item">
              Berita
            </Link>
            <Link to="/agenda" className="footer-info-item">
              Agenda
            </Link>
            <Link to="/galeri" className="footer-info-item">
              Galeri
            </Link>
           
          </div>

          <div className="media-sosial">
            <div className="footer-info-title">Media Sosial</div>
            <div className="medsos-wrapper">
              <a href="https://www.facebook.com/bapendabekasikota/" className="medsos-logo facebook">
                <i className="fab fa-facebook-f" />
              </a>
              <a href="https://www.youtube.com/channel/UCgOrO1dZjaDuyIFTm_V0keQ" className="medsos-logo youtube">
                <i className="fab fa-youtube" />
              </a>
              <a href="https://twitter.com/bapendabekasikt" target="blank" className="medsos-logo twitter">
                <i className="fab fa-twitter" />
              </a>
              {/* <div className="medsos-logo instagram">
                <i className="fab fa-instagram"></i>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubFooter;
