import React, { useEffect, useState } from "react";
import { DocIcon } from "../../assets/illustrations/Icon";
import BaseURL from "../../assets/utils/baseUrl";
import useDocumentTitle from "../../assets/utils/useDocumentTitle";
import { Breadcrumb, Footer, Header, Sidebar } from "../../components";
import "./style.scss";

const ProdukHukumDetail = (props) => {
  useDocumentTitle("Produk Hukum - BAPENDA BEKASI");

  const [produkHukum, setProdukHukum] = useState([]);

  let kategori = props.match.params.kategori.replace("-", " ");
  let id = props.match.params.id;

  let idKategori = 0;
  if (kategori === "peraturan walikota") {
    idKategori = 1;
  } else if (kategori === "peraturan daerah") {
    idKategori = 2;
  } else if (kategori === "surat keputusan kepala daerah") {
    idKategori = 3;
  } else {
    idKategori = 4;
  }

  useEffect(() => {
    fetch(BaseURL + "api/produk_hukum?kategori=" + idKategori + "&id=" + id)
      .then((res) => res.json())
      .then((data) => setProdukHukum(data.data[0]))
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="page">
      <Header active="publikasi" />
      <main className="main-sb">
        <section className="content">
          <Breadcrumb path={kategori} parent="produk-hukum" />
          <div className="page-title-wrapper">
            <div className="page-title">{kategori}</div>
          </div>

          <div>
            <div className="document-name">{produkHukum.nama}</div>
            <div className="document-desc">
              <span style={{ width: "10%" }}>Deskripsi</span>
              <span style={{ width: "2%" }}>:</span>
              <span style={{ width: "78%" }}>{produkHukum.deskripsi}</span>
            </div>
            <div className="document-desc">
              <span style={{ width: "10%" }}>Diposting</span>
              <span style={{ width: "2%" }}>:</span>
              <span style={{ width: "78%" }}>{produkHukum.created_at}</span>
            </div>
            <div className="document-desc">
              <span style={{ width: "10%" }}>Kategori</span>
              <span style={{ width: "2%" }}>:</span>
              <span style={{ width: "78%", textTransform: "capitalize" }}>
                {produkHukum.nama_kategori}
              </span>
            </div>
            <div className="document-desc">
              <span style={{ width: "10%" }}>Unduh</span>
              <span style={{ width: "2%" }}>:</span>
              <a
                href={
                  BaseURL + "assets/documents/produk_hukum/" + produkHukum.file
                }
                target="_blank"
                rel="noopener noreferrer"
                className="document-download"
              >
                <DocIcon style="document-icon" />
                File
              </a>
            </div>
          </div>
        </section>
        <Sidebar />
      </main>
      <Footer />
    </div>
  );
};

export default ProdukHukumDetail;
