import { useState } from "react";

function UseToken() {
  function getToken() {
    const tokenString = localStorage.getItem("token");
    const userToken = tokenString;
    return userToken;
  }

  const [token, setToken] = useState(getToken());

  function saveToken(token) {
    if (token == false) {
      setToken(token);
    } else {
      localStorage.setItem("token", token.token);
      localStorage.setItem("username", token.username);
      localStorage.setItem("nama", token.nama);
      localStorage.setItem("id", token.id_user);
      localStorage.setItem("role", token.nama_role);
      setToken(token);
    }
  }

  return {
    setToken: saveToken,
    token,
  };
}

export default UseToken;
