import React from "react";
import useDocumentTitle from "../../assets/utils/useDocumentTitle";
import { Breadcrumb, Footer, Header, Sidebar } from "../../components";
import "./style.scss";

function Phiburan() {
  useDocumentTitle("Pajak Hiburan- BAPENDA BEKASI");
  return (
    <div className="page">
      <Header active="Phiburan" />

      {/* main */}
      <main className="main-sb">
        <section className="content">
          <Breadcrumb path="Jenis Pelayanan / Pajak Hiburan" />
          <div className="page-title-wrapper">
            <div className="page-title">Pajak Hiburan</div>
          </div>

          {/* <p className="page-text">
            Dalam rangka pelaksanaan Peraturan Pemerintah Nomor 18 Tahun 2016 tentang Perangkat Daerah dan Peraturan Menteri Dalam Negeri Nomor 97 Tahun 2016 tentang Perangkat Daerah Provinsi DKI Jakarta, maka berdasarkan Peraturan Gubernur
            Nomor 154 Tahun 2020 tentang Organisasi dan Tata Kerja Perangkat Daerah dibentuklah Badan Pendapatan Daerah (Bapenda) Provinsi DKI Jakarta.
          </p> */}

          <p className="subpage-title"><strong>Pajak hiburan</strong></p>
          <p className="page-text">Hiburan adalah semua jenis tontonan, pertunjukan, permainan, dan/atau
keramaian yang dinikmati dengan dipungut bayaran.
</p>
<p className="page-text">
Pajak Hiburan
Paragraf 1
Nama, Objek, Subjek dan Wajib Pajak
Pasal 19
(1) Dengan nama Pajak Hiburan dipungut pajak atas penyelenggaraan hiburan
dengan dipungut bayaran.
(2) Objek Pajak Hiburan adalah jasa penyelenggaraan hiburan dengan
dipungut bayaran.
(3) Hiburan sebagaimana dimaksud pada ayat (2) adalah :
a. tontonan film;
b. pagelaran kesenian, musik, tari, dan/atau busana;
c. kontes kecantikan, binaraga, dan sejenisnya;
d. pameran;
e. diskotik, karaoke, klab malam dan sejenisnya;
f. sirkus, akrobat dan sulap;
g. pacuan kuda, kendaraan bermotor dan permainan ketangkasan;
h. panti pijat/refleksi, mandi uap/spa dan pusat kebugaran (fitness
center); dan
i. pertandingan olahraga.
</p>
<p className="page-text">

Tarif Pajak Hiburan ditetapkan sebagai berikut :
</p>
<p className="page-text">
a. tontonan film :
</p>
<p className="page-text">
1. film impor ditetapkan sebesar 10% (sepuluh persen);
2. film nasional ditetapkan sebesar 10% (sepuluh persen).
</p>
<p className="page-text">
b. penyelenggaraan hiburan kesenian meliputi :
</p>
<p className="page-text">
1. pagelaran kesenian, tari, dan/atau pagelaran busana (fashion show)
yang berkelas lokal/tradisional sebesar 0% (nol persen);
2. pagelaran kesenian, tari, dan/atau pagelaran busana (fashion show)
yang berkelas nasional 5 % (lima persen);
3. pagelaran kesenian, tari, dan/atau pagelaran busana (fashion show)
yang berkelas internasional sebesar 10% (sepuluh persen).
</p>
<p className="page-text">
c. penyelenggaraan pagelaran musik meliputi :
</p>
<p className="page-text">
1. pagelaran musik yang berkelas lokal/tradisional sebesar 0% (nol persen);
2. pagelaran musik yang berkelas nasional sebesar 10% (sepuluh persen);
3. pagelaran musik yang berkelas internasional sebesar 25% (dua puluh
lima persen).
</p>
<p className="page-text">
d. penyelenggaraan kontes kecantikan meliputi :
</p>
<p className="page-text">
1. kontes kecantikan dan sejenisnya, yang berkelas lokal/tradisional
sebesar 0% (nol persen);
17
2. kontes kecantikan dan sejenisnya, yang berkelas nasional sebesar 5%
(lima persen);
3. kontes kecantikan dan sejenisnya, yang berkelas internasional sebesar
25% (dua puluh lima persen).
</p>
<p className="page-text">
e. pameran, sirkus, akrobat dan sulap meliputi :
1. pameran yang bersifat non komersial sebesar 0% (nol persen);
2. pameran yang bersifat komersial sebesar 10% (sepuluh persen);
3. sirkus, akrobat dan sulap yang berkelas lokal/tradisional sebesar 10%
(sepuluh persen);
4. sirkus, akrobat dan sulap yang berkelas nasional dan internasional
sebesar 25% (dua puluh lima persen).
</p>
<p className="page-text">
f. diskotik, klab malam, pub dan sejenisnya sebesar 40% (empat puluh persen);
</p>
<p className="page-text">
g. arena bernyanyi antara lain, karaoke, rumah bernyanyi sebesar 30% (tiga puluh
persen) dan arena bernyanyi berupa studio musik sebesar 5% (lima persen);
</p>
<p className="page-text">
h. pacuan kuda dan kendaraan bermotor yang meliputi ATV dan Road Race
ditetapkan sebesar 10% (sepuluh persen);
</p>
<p className="page-text">
i. permainan ketangkasan meliputi :
</p>
<p className="page-text">
1. gelanggang permainan anak, outbond dan sejenisnya ditetapkan 25%
(dua puluh lima persen);
2. permainan seluncur es (ice skating) dan sejenisnya ditetapkan sebesar
10% (sepuluh persen).
j. panti pijat modern ditetapkan sebesar 25% (dua puluh lima persen);
</p>
<p className="page-text">
k. panti pijat tradisional dan refleksi ditetapkan sebesar 10% (sepuluh persen);
</p>
<p className="page-text">
l. hiburan untuk umum yang diselenggarakan dengan memanfaatkan
fasillitas hotel ditetapkan sebesar 15% (lima belas persen);
</p>
<p className="page-text">
m. mandi uap/spa dan pusat kebugaran (fitness center) dan sejenisnya
ditetapkan sebesar 25% (dua puluh lima persen);
</p>
<p className="page-text">
n. mandi uap/spa dan jenis kebugaran yang bersifat tradisional
(di luar fitness center/hotel dan sejenisnya) ditetapkan sebesar 25% (dua
puluh lima persen);
</p>
<p className="page-text">
o. pertandingan olahraga ditetapkan sebesar 10% (sepuluh persen).
</p>

         
        
         
         
          
        </section>
        <Sidebar />
      </main>

      <Footer />
    </div>
  );
}

export default Phiburan;
