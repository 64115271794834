import React from "react";
import useDocumentTitle from "../../assets/utils/useDocumentTitle";
import { Breadcrumb, Footer, Header, Sidebar } from "../../components";
import "./style.scss";

function Pairtanah() {
  useDocumentTitle("Pajak air Tanah- BAPENDA BEKASI");
  return (
    <div className="page">
      <Header active="Pairtanah" />

      {/* main */}
      <main className="main-sb">
        <section className="content">
          <Breadcrumb path="Jenis Pelayanan / Pajak Air Tanah" />
          <div className="page-title-wrapper">
            <div className="page-title">Pajak Air Tanah</div>
          </div>

          {/* <p className="page-text">
            Dalam rangka pelaksanaan Peraturan Pemerintah Nomor 18 Tahun 2016 tentang Perangkat Daerah dan Peraturan Menteri Dalam Negeri Nomor 97 Tahun 2016 tentang Perangkat Daerah Provinsi DKI Jakarta, maka berdasarkan Peraturan Gubernur
            Nomor 154 Tahun 2020 tentang Organisasi dan Tata Kerja Perangkat Daerah dibentuklah Badan Pendapatan Daerah (Bapenda) Provinsi DKI Jakarta.
          </p> */}

          <p className="subpage-title"><strong>Pajak Air Tanah</strong></p>
          <p className="page-text">Air Tanah adalah air yang terdapat dalam lapisan tanah atau batuan di
bawah permukaan tanah; </p>
<p className="page-text">
Tarif Pajak Air Tanah ditetapkan sebesar 20% (dua puluh persen).

</p>

         
        
         
         
          
        </section>
        <Sidebar />
      </main>

      <Footer />
    </div>
  );
}

export default Pairtanah;
