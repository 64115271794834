import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import BaseURL from "../../../assets/utils/baseUrl";
import { Button, Footer, HeaderAdmin } from "../../../components";

function Dashboard({ setToken }) {
  const [berita, setBerita] = useState([]);
  const [beritaVideo, setBeritaVideo] = useState([]);
  const [agenda, setAgenda] = useState([]);
 const [pengumuman, setPengumuman] = useState([]);
  const [galeri, setGaleri] = useState([]);
  const [infografis, setInfografis] = useState([]);
  const [ProdukHukum, setProdukHukum] = useState([]);
    const [dokumenPerencanaan, setDokumenPerencanaan] = useState([]);
  const [Laporanppid, setLaporanppid] = useState([]);
  const [pajak, setPajak] = useState([]);
  const [retribusi, setRetribusi] = useState([]);
  const [apbd, setApbd] = useState([]);
  const [pad, setPad] = useState([]);
  const [banner, setBanner] = useState([]);

  useEffect(() => {
    const getDataBerita = async () => {
      await fetch(BaseURL + "api/berita")
        .then((res) => res.json())
        .then((data) => setBerita(data.data));
    };
 const getDataBeritaVideo = async () => {
      await fetch(BaseURL + "api/berita_video")
        .then((res) => res.json())
        .then((data) => setBeritaVideo(data.data));
    };
    const getDataAgenda = async () => {
      await fetch(BaseURL + "api/agenda")
        .then((res) => res.json())
        .then((data) => setAgenda(data.data));
    };

   // const getDataPengumuman = async () => {
    //  await fetch(BaseURL + "api/pengumuman")
   //     .then((res) => res.json())
   //     .then((data) => setPengumuman(data.data));
  //  };

    const getDataGaleri = async () => {
      await fetch(BaseURL + "api/galeri")
        .then((res) => res.json())
        .then((data) => setGaleri(data.data));
    };

    const getDataInfografis = async () => {
      await fetch(BaseURL + "api/infografis")
        .then((res) => res.json())
        .then((data) => setInfografis(data.data));
    };

    const getDataPajak = async () => {
      await fetch(BaseURL + "api/pajak")
        .then((res) => res.json())
        .then((data) => setPajak(data.data));
    };

    const getDataRetribusi = async () => {
      await fetch(BaseURL + "api/retribusi")
        .then((res) => res.json())
        .then((data) => setRetribusi(data.data));
    };

   const getDataApbd = async () => {
      await fetch(BaseURL + "api/Apbd")
        .then((res) => res.json())
        .then((data) => setApbd(data.data));
    };

    const getDataPad = async () => {
      await fetch(BaseURL + "api/pad")
        .then((res) => res.json())
        .then((data) => setPad(data.data));
    };

    const getDataBanner = async () => {
      await fetch(BaseURL + "api/banner")
        .then((res) => res.json())
        .then((data) => setBanner(data.data));
    };
	const getProdukHukum = async () => {
      await fetch(BaseURL + "api/produk_hukum")
        .then((res) => res.json())
        .then((data) => setProdukHukum(data.data));
    };
	    const getDataDokumenPerencanaan = async () => {
      await fetch(BaseURL + "api/dokumen_perencanaan")
        .then((res) => res.json())
        .then((data) => setDokumenPerencanaan(data.data));
    };

	const getLaporanppid = async () => {
      await fetch(BaseURL + "api/laporan_ppid")
        .then((res) => res.json())
        .then((data) => setLaporanppid(data.data));
    };

    getDataBerita();
	getDataBeritaVideo();
    getDataAgenda();
   // getDataPengumuman();
    getDataGaleri();
    getDataInfografis();
	getProdukHukum();
	 getDataDokumenPerencanaan();
	getLaporanppid();
    getDataPajak();
    getDataRetribusi();
    getDataApbd();
    getDataPad();
    getDataBanner();
  }, []);

  return (
    <div className="page">
      <HeaderAdmin setToken={(e) => setToken(e)} title="Dashboard Website Bapenda Bekasi" />

      <div className="main-admin">
        <div className="container-admin">
          <div style={{ height: 50 }} />
          <div className="menu-panel-admin">
            <div className="menu-card-admin">
              <div>
                <p className="menu-title-admin">Berita</p>
                <p className="menu-desc-admin">Total Post: {berita.length}</p>
              </div>
              <Link to="/admin/kelola-berita">
                <Button title="Detail" type="primary" />
              </Link>
            </div>
			
			
			
            <div className="menu-card-admin">
              <div>
                <p className="menu-title-admin">Agenda</p>
                <p className="menu-desc-admin">Total Post: {agenda.length}</p>
              </div>
              <Link to="/admin/kelola-agenda">
                <Button title="Detail" type="primary" />
              </Link>
            </div>
            <div className="menu-card-admin">
              <div>
                <p className="menu-title-admin">Pengumuman</p>
                <p className="menu-desc-admin">Total Post: {pengumuman.length}</p>
              </div>
              <Link to="/admin/kelola-pengumuman">
                <Button title="Detail" type="primary" />
              </Link>
            </div>
            <div className="menu-card-admin">
              <div>
                <p className="menu-title-admin">Galeri</p>
                <p className="menu-desc-admin">Total Post: {galeri.length}</p>
              </div>
              <Link to="/admin/kelola-galeri">
                <Button title="Detail" type="primary" />
              </Link>
            </div>
          </div>
          <div className="menu-panel-admin">
            <div className="menu-card-admin">
              <div>
                <p className="menu-title-admin">APBD</p>
                <p className="menu-desc-admin">Total Post: {apbd.length}</p>
              </div>
              <Link to="/admin/kelola-apbd">
                <Button title="Detail" type="primary" />
              </Link>
            </div>
            <div className="menu-card-admin">
              <div>
                <p className="menu-title-admin">PAD</p>
                <p className="menu-desc-admin">Total Post: {pad.length}</p>
              </div>
              <Link to="/admin/kelola-pad">
                <Button title="Detail" type="primary" />
              </Link>
            </div>

            <div className="menu-card-admin">
              <div>
                <p className="menu-title-admin">Pajak</p>
                <p className="menu-desc-admin">Total Post: {pajak.length}</p>
              </div>
              <Link to="/admin/kelola-pajak">
                <Button title="Detail" type="primary" />
              </Link>
            </div>

            <div className="menu-card-admin">
              <div>
                <p className="menu-title-admin">Retribusi</p>
                <p className="menu-desc-admin">Total Post: {retribusi.length}</p>
              </div>
              <Link to="/admin/kelola-retribusi">
                <Button title="Detail" type="primary" />
              </Link>
            </div>
          </div>

          <div className="menu-panel-admin">
            <div className="menu-card-admin">
              <div>
                <p className="menu-title-admin">Banner</p>
                <p className="menu-desc-admin">Total Post: {banner.length}</p>
              </div>
              <Link to="/admin/kelola-banner">
                <Button title="Detail" type="primary" />
              </Link>
            </div>

            <div className="menu-card-admin">
              <div>
                <p className="menu-title-admin">Infografis</p>
                <p className="menu-desc-admin">Total Post: {infografis.length}</p>
              </div>
              <Link to="/admin/kelola-infografis">
                <Button title="Detail" type="primary" />
              </Link>
            </div>
			
			  <div className="menu-card-admin">
              <div>
                <p className="menu-title-admin">Produk Hukum</p>
                <p className="menu-desc-admin">Total Post: {ProdukHukum.length}</p>
              </div>
              <Link to="/admin/kelola-produk-hukum">
                <Button title="Detail" type="primary" />
              </Link>
            </div>
			
			  
			
			   <div className="menu-card-admin">
              <div>
                <p className="menu-title-admin">Laporan PPID</p>
                <p className="menu-desc-admin">Total Post: {Laporanppid.length}</p>
              </div>
              <Link to="/admin/kelola-laporan-ppid">
                <Button title="Detail" type="primary" />
              </Link>
            </div>
			  
			  
			   <div className="menu-card-admin">
              <div>
                <p className="menu-title-admin">Dokumen Perencanaan</p>
                <p className="menu-desc-admin">
                  Total Post: {dokumenPerencanaan.length}
                </p>
              </div>
              <Link to="/admin/kelola-dokumen-perencanaan">
                <Button title="Detail" type="primary" />
              </Link>
            </div>
			
			
			<div className="menu-card-admin">
              <div>
                <p className="menu-title-admin">Berita Video</p>
                <p className="menu-desc-admin">
                  Total Post: {beritaVideo.length}
                </p>
              </div>
              <Link to="/admin/kelola-berita-video">
                <Button title="Detail" type="primary" />
              </Link>
            </div>
			  
			
          </div>
        </div>
      </div>
	  
	

      <Footer />
    </div>
  );
}

export default Dashboard;
