import React from "react";
import { logoBekasi } from "../../assets";
import useDocumentTitle from "../../assets/utils/useDocumentTitle";
import { Breadcrumb, Footer, Header, Sidebar } from "../../components";
import "./style.scss";

function Kontak() {
  useDocumentTitle("Kontak - BAPENDA BEKASI");
  return (
    <div className="page">
      <Header active="kontak" />

      {/* main */}
      <main className="main-sb">
        <section className="content">
          <Breadcrumb path="Kontak" />
          <div className="page-title-wrapper">
            <div className="page-title">Kontak</div>
          </div>
          <div className="identitas" style={{ color: "#333333" }}>
            <img className="logo-bekasi" src={logoBekasi}></img>
            <p style={{ fontWeight: "700" }}>Badan Pendapatan Daerah</p>
            <p style={{ fontWeight: "700" }}>Kota Bekasi</p>
            <div className="identitas-item-wrapper">
              <div className="identitas-item">
                <div className="identitas-item-logo">
                  <i className="fas fa-map-marker-alt" />
                </div>
                <div className="identitas-item-text">
                  <p>
                    Jl. Ir. H. Juanda No.100, RT.001/RW.005, Margahayu, Kec.
                    Bekasi Timur
                  </p>
                  <p>Kota Bekasi, Jawa Barat 17113</p>
                </div>
              </div>
              <div className="identitas-item">
                <div className="identitas-item-logo">
                  <i className="fas fa-envelope" />
                </div>
                <div className="identitas-item-text">
                  <p>opd.bapenda@bekasikota.go.id</p>
                </div>
              </div>

             
              <div className="identitas-item">
                <div className="identitas-item-logo">
                  <i className="fas fa-phone" />
                </div>
                <div className="identitas-item-text">
                  <p>WA: 0811-1904-0740</p>
                </div>
              </div>

            </div>
            

            <div style={{ height: 10 }}></div>

            <div className="pengaduan">
              <p className="pengaduan-text">
                Layanan Aspirasi dan Pengaduan Online
              </p>
              <a
                href="https://bekasikota.lapor.go.id"
                rel="noopener noreferrer"
                className="pengaduan-button"
                target="_blank"
              >
                Pengaduan Klik Disini
              </a>
            </div>
          </div>
        </section>
        <Sidebar />
      </main>

      <Footer />
    </div>
  );
}

export default Kontak;
