import React from "react";
import "./style.scss";

var d = new Date();
var currentYear = d.getFullYear();

const Footer = () => {
  return (
    <div className="footer">
      <span className="copyright">
        <i className="fas fa-copyright"></i> {currentYear} Bapenda Kota Bekasi
      </span>
    </div>
  );
};

export default Footer;
